import isPropValid from '@emotion/is-prop-valid';
import { Button, Tooltip } from '@material-ui/core';
import { truncate } from 'lodash';
import { useMemo } from 'react';
import {
  Loader as IconCreateLabelRule,
  Tag,
  Zap as IconSmartLabel
} from 'react-feather';
import { Link } from 'react-router-dom';
import { WithShape } from '../../../../components/Charts/Util';
import { Dash } from '../../../../components/Table/CountCell';
import { ClickhouseTransaction } from '../../../../domainTypes/analytics_v2';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useRoutes } from '../../../../routes';
import { useChannels } from '../../../../services/channels';
import { getStableRandomColor, useColors } from '../../../../services/color';
import {
  getCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { getPathName } from '../../../../services/pages';
import { isSameDomainWithoutWww, onlyHostname } from '../../../../services/url';
import {
  EMPTY_ATTRIBUTION_RULE,
  EMPTY_RULE_APPLICATION,
  RuleCreateDialog
} from '../RuleCreateDialog';
import { ITrackedConvertedSaleWithProduct } from './types';

const CouponBadge = styled('span')`
  color: ${(p) => p.theme.palette.grey[500]};
  background-color: ${(p) => p.theme.palette.grey[100]};
  border-radius: 30px;
  display: inline-block;
  padding: ${(p) => `${p.theme.spacing(0.5)}px ${p.theme.spacing(2)}px`};

  svg {
    position: relative;
    top: 2px;
  }
`;

const BadgeLink = styled<typeof Link, { color: string; bgColor: string }>(
  Link,
  {
    shouldForwardProp: (props) => isPropValid(props)
  }
)`
  color: ${(p) => p.color};
  background-color: ${(p) => p.bgColor};
  border-radius: 30px;
  display: inline-block;
  padding: ${(p) => `${p.theme.spacing(0.5)}px ${p.theme.spacing(2)}px`};
  margin-right: 4px;
`;

export const TrackingLabel = ({
  d
}: {
  d: ITrackedConvertedSaleWithProduct;
}) => {
  const currentUser = getCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [channels = [], loadingChannels] = useChannels(currentUser.space.id);
  const [canCreateRules] = useHasCurrentUserRequiredScopes(['rules.create']);
  const { ROUTES } = useRoutes();
  const COLORS = useColors();
  const label = d.sale.trackingLabel || '';
  const hasCoupon = d.sale.coupon;

  let domain: string | null;

  if (d.origin) {
    try {
      const { origin } = new URL(`https://${d.origin}`);
      domain = origin;
    } catch (err) {
      domain = null;
    }
  }

  const ownsDomain = currentUser.space.domains.find((e) => {
    if (!domain || !e.active || !e.verified) {
      return false;
    }
    const isSame = isSameDomainWithoutWww(e.url, domain);
    return isSame;
  });

  const justYouTubeReferrer =
    d.origin && d.origin.indexOf('www.youtube.com') !== -1;

  const channel = useMemo(
    () => channels.find((c) => c.data.channelId === d.channelId),
    [channels, d.channelId]
  );

  if (d.channelId && !loadingChannels && channel) {
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [d.channelId]
              }
            ]
          })}
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue1}
        >
          <WithShape large color={getStableRandomColor(channel.data.channelId)}>
            {channel.data.name} {d.click && <IconSmartLabel size={11} />}
          </WithShape>
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={d.sale.coupon || ''} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if ((!label || d.labelRuleId) && d.origin && justYouTubeReferrer) {
    const pageUrl = d.pageUrl || d.origin;

    if (!isSameDomainWithoutWww(pageUrl, d.origin)) {
      return null;
    }
    const origin = d.origin!;
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [origin]
              }
            ]
          })}
          color={COLORS.purple.purple6}
          bgColor={COLORS.purple.purple1}
        >
          {d.origin}
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={d.sale.coupon || ''} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (d.pageUrl) {
    if (!ownsDomain) {
      return <div title={d.pageUrl}>{truncate(d.pageUrl, { length: 50 })}</div>;
    }

    const pathOnly = getPathName(d.pageUrl).substr(0, d.pageUrl.length - 1);
    const startIndex = pathOnly.length - 30;
    const truncatedPath = pathOnly.substr(
      startIndex < 0 ? 0 : startIndex,
      pathOnly.length
    );
    const showEllipses = truncatedPath.length !== pathOnly.length;

    const linkedViaLabelRule =
      !!d.labelRuleId && d.sale.trackingLabel?.indexOf('wct') === -1;

    return (
      <span>
        <Tooltip
          title={
            linkedViaLabelRule
              ? `${d.pageUrl} - linked via a Page Label Rule ${
                  label ? `for ${label}` : ``
                }`
              : d.pageUrl
          }
          placement="top"
        >
          <BadgeLink
            to={ROUTES.content.details.trends.url(d.pageUrl)}
            color={COLORS.blue.blue6}
            bgColor={COLORS.blue.blue1}
          >
            {showEllipses && '...'}
            {truncatedPath} {d.click && <IconSmartLabel size={11} />}
          </BadgeLink>
        </Tooltip>
        {hasCoupon && (
          <Tooltip title={d.sale.coupon || ''} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (d.labelRuleId && d.origin && !d.sale.trackingId) {
    const hostname = onlyHostname(d.origin);
    return (
      <span>
        <Tooltip
          title={`${hostname} - linked via a Site Label Rule
        ${label ? `for ${label}` : ``}`}
          placement="top"
        >
          <BadgeLink
            to={ROUTES.performanceNew.transactions.url({
              filters: [
                {
                  k: 'channel',
                  v: [hostname]
                }
              ]
            })}
            color={COLORS.blue.blue6}
            bgColor={COLORS.blue.blue1}
          >
            {hostname}
          </BadgeLink>
        </Tooltip>
        {hasCoupon && (
          <Tooltip title={d.sale.coupon || ''} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (!label && d.origin && ownsDomain) {
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [d.origin]
              }
            ]
          })}
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue1}
        >
          {d.origin}
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={d.sale.coupon || ''} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (!label && hasCoupon) {
    return (
      <Tooltip title={d.sale.coupon || ''} placement="top">
        <CouponBadge>
          <Tag size={14} /> {d.sale.coupon}
        </CouponBadge>
      </Tooltip>
    );
  }

  if (!label) {
    return <Dash />;
  }

  const text = <div title={label}>{truncate(label, { length: 30 })}</div>;

  if (!canCreateRules) {
    return text;
  }

  return (
    <>
      <Tooltip title="Click to create a rule for this label" placement="top">
        <Button
          endIcon={<IconCreateLabelRule size={16} />}
          onClick={(e) => {
            e.stopPropagation();
            openDialog();
          }}
        >
          {text}
        </Button>
      </Tooltip>
      {dialogOpen && (
        <RuleCreateDialog
          spaceId={d.spaceId}
          open={dialogOpen}
          onClose={closeDialog}
          value={{
            ...EMPTY_ATTRIBUTION_RULE(currentUser.space.id),
            apply: [EMPTY_RULE_APPLICATION],
            match: {
              type: 'all',
              els: [
                {
                  op: '==',
                  k: 'trackingLabel',
                  v: label
                }
              ]
            }
          }}
        />
      )}
    </>
  );
};

export const TrackingLabelV2 = ({ d }: { d: ClickhouseTransaction }) => {
  const currentUser = getCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [channels = [], loadingChannels] = useChannels(d.space_id);
  const [canCreateRules] = useHasCurrentUserRequiredScopes(['rules.create']);
  const { ROUTES } = useRoutes();
  const COLORS = useColors();
  const label = d.tracking_label || '';
  const hasCoupon = !!(d.coupon || []).length;

  let domain: string | null;

  if (d.page_url_origin) {
    try {
      const { origin } = new URL(`https://${d.page_url_origin}`);
      domain = origin;
    } catch (err) {
      domain = null;
    }
  }

  const ownsDomain = currentUser.space.domains.find((e) => {
    if (!domain || !e.active || !e.verified) {
      return false;
    }
    const isSame = isSameDomainWithoutWww(e.url, domain);
    return isSame;
  });

  const justYouTubeReferrer =
    d.page_url_origin && d.page_url_origin.indexOf('www.youtube.com') !== -1;

  const channel = useMemo(
    () => channels.find((c) => c.data.channelId === d.channel_id),
    [channels, d.channel_id]
  );

  if (d.channel_id && !loadingChannels && channel) {
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [d.channel_id]
              }
            ]
          })}
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue1}
        >
          <WithShape large color={getStableRandomColor(channel.data.channelId)}>
            {channel.data.name} {d.link_id && <IconSmartLabel size={11} />}
          </WithShape>
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={(d.coupon || []).join(', ')} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if ((!label || d.label_rule_id) && d.page_url_origin && justYouTubeReferrer) {
    const pageUrl = d.page_url || d.page_url_origin;

    if (!isSameDomainWithoutWww(pageUrl, d.page_url_origin)) {
      return null;
    }
    const origin = d.page_url_origin;
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [origin]
              }
            ]
          })}
          color={COLORS.purple.purple6}
          bgColor={COLORS.purple.purple1}
        >
          {d.page_url_origin}
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={(d.coupon || []).join(', ')} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (d.page_url) {
    if (!ownsDomain) {
      return (
        <div title={d.page_url}>{truncate(d.page_url, { length: 50 })}</div>
      );
    }

    const pathOnly = getPathName(d.page_url).substr(0, d.page_url.length - 1);
    const startIndex = pathOnly.length - 30;
    const truncatedPath = pathOnly.substr(
      startIndex < 0 ? 0 : startIndex,
      pathOnly.length
    );
    const showEllipses = truncatedPath.length !== pathOnly.length;

    const linkedViaLabelRule =
      !!d.label_rule_id && d.tracking_label?.indexOf('wct') === -1;

    return (
      <span>
        <Tooltip
          title={
            linkedViaLabelRule
              ? `${d.page_url} - linked via a Page Label Rule ${
                  label ? `for ${label}` : ``
                }`
              : d.page_url
          }
          placement="top"
        >
          <BadgeLink
            to={ROUTES.content.details.trends.url(d.page_url)}
            color={COLORS.blue.blue6}
            bgColor={COLORS.blue.blue1}
          >
            {showEllipses && '...'}
            {truncatedPath} {d.link_id && <IconSmartLabel size={11} />}
          </BadgeLink>
        </Tooltip>
        {hasCoupon && (
          <Tooltip title={(d.coupon || []).join(',')} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (d.label_rule_id && d.page_url_origin && !d.tracking_id) {
    const hostname = onlyHostname(d.page_url_origin);
    return (
      <span>
        <Tooltip
          title={`${hostname} - linked via a Site Label Rule
        ${label ? `for ${label}` : ``}`}
          placement="top"
        >
          <BadgeLink
            to={ROUTES.performanceNew.transactions.url({
              filters: [
                {
                  k: 'channel',
                  v: [hostname]
                }
              ]
            })}
            color={COLORS.blue.blue6}
            bgColor={COLORS.blue.blue1}
          >
            {hostname}
          </BadgeLink>
        </Tooltip>
        {hasCoupon && (
          <Tooltip title={(d.coupon || []).join(', ')} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (!label && d.page_url_origin && ownsDomain) {
    return (
      <span>
        <BadgeLink
          to={ROUTES.performanceNew.transactions.url({
            filters: [
              {
                k: 'channel',
                v: [d.page_url_origin]
              }
            ]
          })}
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue1}
        >
          {d.page_url_origin}
        </BadgeLink>
        {hasCoupon && (
          <Tooltip title={(d.coupon || []).join(', ')} placement="top">
            <CouponBadge>
              <Tag size={14} />
            </CouponBadge>
          </Tooltip>
        )}
      </span>
    );
  }

  if (!label && hasCoupon) {
    return (
      <Tooltip title={(d.coupon || []).join(', ')} placement="top">
        <CouponBadge>
          <Tag size={14} /> {(d.coupon || []).join(', ')}
        </CouponBadge>
      </Tooltip>
    );
  }

  if (!label) {
    return <Dash />;
  }

  const text = <div title={label}>{truncate(label, { length: 30 })}</div>;

  if (!canCreateRules) {
    return text;
  }

  return (
    <>
      <Tooltip title="Click to create a rule for this label" placement="top">
        <Button
          endIcon={<IconCreateLabelRule size={16} />}
          onClick={(e) => {
            e.stopPropagation();
            openDialog();
          }}
        >
          {text}
        </Button>
      </Tooltip>
      {dialogOpen && (
        <RuleCreateDialog
          spaceId={d.space_id}
          open={dialogOpen}
          onClose={closeDialog}
          value={{
            ...EMPTY_ATTRIBUTION_RULE(currentUser.space.id),
            apply: [EMPTY_RULE_APPLICATION],
            match: {
              type: 'all',
              els: [
                {
                  op: '==',
                  k: 'trackingLabel',
                  v: label
                }
              ]
            }
          }}
        />
      )}
    </>
  );
};
