import { Card } from '@material-ui/core';
import React from 'react';
import { CopyId } from '../../../../../../components/CopyButton';
import { ProfileLabel } from '../../../../../../components/ProfileLabel';
import { MarketplaceAdvertiser } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { FlexContainerVertical } from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { DataGrid } from '../../../../../components/DataGrid';

export const General = ({ d }: { d: Doc<MarketplaceAdvertiser> }) => {
  return (
    <Section>
      <CanvasBar>
        <div>General</div>
      </CanvasBar>
      <Card>
        <FlexContainerVertical spacing={3} fullWidth>
          <DataGrid
            items={[
              ['ID', <CopyId id={d.id} />],
              ['Name', <ProfileLabel profile={d.data.profile} />]
            ]}
          />
        </FlexContainerVertical>
      </Card>
    </Section>
  );
};
