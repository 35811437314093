import { PayoutStatus, SaleType, TransactionStatus } from './performance';
import { ReferrerGroup } from './referrers';
import { Device } from './tracking';
import { ProductCatalogAvailability } from './productCatalog';

export type FilterMode = 'in' | 'not_in';
export const coerceFilterMode = (mode: FilterMode | undefined): FilterMode =>
  mode ?? 'in';

export type FiltersDefinition = Array<FilterDefinition>;

interface UtmCampaignFilterDef {
  k: 'utm_campaign';
  v: Array<string>;
}

interface UtmMediumFilterDef {
  k: 'utm_medium';
  v: Array<string>;
}

interface UtmSourceFilterDef {
  k: 'utm_source';
  v: Array<string>;
}

interface UtmTermFilterDef {
  k: 'utm_term';
  v: Array<string>;
}

interface UtmContentFilterDef {
  k: 'utm_content';
  v: Array<string>;
}

export type ReferrerFilterValue =
  | {
      mode: 'group';
      v: ReferrerGroup | null;
    }
  | {
      mode: 'domains';
      v: Array<string>;
    };

export interface ReferrerFilterDef {
  k: 'referrer';
  v: ReferrerFilterValue;
}

export interface TagFilterDef {
  k: 'tag';
  v: Array<string>;
  mode?: FilterMode;
}

export interface SiteFilterDef {
  k: 'site';
  v: Array<string>;
}

export interface ChannelFilterDef {
  k: 'channel';
  v: Array<string>;
}

export interface PlatformFilterDef {
  k: 'platform';
  v: Array<string>;
  mode?: FilterMode;
}

export interface DeviceFilterDef {
  k: 'device';
  v: Array<Device>;
}

export interface CountryFilterDef {
  k: 'country';
  v: Array<string>;
}

export interface ClickData01FilterDef {
  k: 'click_data_01';
  v: Array<string>;
}

export interface ClickData02FilterDef {
  k: 'click_data_02';
  v: Array<string>;
}

export interface ClickData03FilterDef {
  k: 'click_data_03';
  v: Array<string>;
}

export interface ClickData04FilterDef {
  k: 'click_data_04';
  v: Array<string>;
}

export interface ClickData05FilterDef {
  k: 'click_data_05';
  v: Array<string>;
}

export interface ClickData06FilterDef {
  k: 'click_data_06';
  v: Array<string>;
}

export interface ClickData07FilterDef {
  k: 'click_data_07';
  v: Array<string>;
}

export interface ClickData08FilterDef {
  k: 'click_data_08';
  v: Array<string>;
}

export interface ClickData09FilterDef {
  k: 'click_data_09';
  v: Array<string>;
}

export interface ClickData10FilterDef {
  k: 'click_data_10';
  v: Array<string>;
}

export type UtmFilterDefinition =
  | UtmCampaignFilterDef
  | UtmMediumFilterDef
  | UtmSourceFilterDef
  | UtmTermFilterDef
  | UtmContentFilterDef;

export type ClickDataFilterDefinition =
  | ClickData01FilterDef
  | ClickData02FilterDef
  | ClickData03FilterDef
  | ClickData04FilterDef
  | ClickData05FilterDef
  | ClickData06FilterDef
  | ClickData07FilterDef
  | ClickData08FilterDef
  | ClickData09FilterDef
  | ClickData10FilterDef;

export type ClickDataSlot = ClickDataFilterDefinition['k'];

export interface PayoutStatusFilterDefinition {
  k: 'payout_status';
  v: Array<PayoutStatus>;
}

export interface PayoutIdFilterDefinition {
  k: 'payout_id';
  v: Array<string>;
}

export interface TransactionStatusFilterDefinition {
  k: 'transaction_status';
  v: Array<TransactionStatus>;
}

export interface TransactionTypeFilterDefinition {
  k: 'transaction_type';
  v: Array<SaleType>;
  mode?: FilterMode;
}

export interface AdvertiserFilterDefinition {
  k: 'advertiser';
  v: Array<string>;
  mode?: FilterMode;
}

export interface LinkAdvertiserFilterDefinition {
  k: 'l_advertiser_domain';
  v: Array<string>;
  mode?: FilterMode;
}

export interface IntegrationIdFilterDefinition {
  k: 'integration_id';
  v: Array<string>;
}

export interface CampaignTeamFilterDefinition {
  k: 'campaign_team';
  v: Array<string>;
}

export interface CampaignManagerFilterDefinition {
  k: 'campaign_manager';
  v: Array<string>;
}

export interface CampaignAdvertiserFilterDefinition {
  k: 'campaign_advertiser';
  v: Array<string>;
}

export interface CampaignStatusFilterDefinition {
  k: 'campaign_status';
  v: Array<string>;
}

export interface ProductAvailabilityFilterDef {
  k: 'product_availability';
  v: Array<ProductCatalogAvailability>;
  mode?: FilterMode;
}

export interface ProductCatalogBrandFilterDef {
  k: 'product_catalog_brand';
  v: Array<string>;
}

export interface ProductCatalogCatalogFilterDef {
  k: 'product_catalog';
  v: Array<string>;
}

export interface ProductCatalogManufacturerFilterDef {
  k: 'product_catalog_manufacturer';
  v: Array<string>;
}

export interface ProductCatalogRetailerFilterDef {
  k: 'product_catalog_retailer';
  v: Array<string>;
}

export interface ProductCatalogSellerFilterDef {
  k: 'product_catalog_seller';
  v: Array<string>;
}

export interface ProductCatalogAvailabilityFilterDef {
  k: 'product_catalog_availability';
  v: Array<ProductCatalogAvailability>;
}

export type FilterDefinition =
  | ProductCatalogBrandFilterDef
  | ProductCatalogCatalogFilterDef
  | ProductCatalogManufacturerFilterDef
  | ProductCatalogRetailerFilterDef
  | ProductCatalogSellerFilterDef
  | ProductCatalogAvailabilityFilterDef
  | CampaignStatusFilterDefinition
  | CampaignAdvertiserFilterDefinition
  | CampaignTeamFilterDefinition
  | CampaignManagerFilterDefinition
  | IntegrationIdFilterDefinition
  | AdvertiserFilterDefinition
  | LinkAdvertiserFilterDefinition
  | PayoutIdFilterDefinition
  | PayoutStatusFilterDefinition
  | TransactionStatusFilterDefinition
  | TransactionTypeFilterDefinition
  | UtmCampaignFilterDef
  | UtmMediumFilterDef
  | UtmSourceFilterDef
  | UtmTermFilterDef
  | UtmContentFilterDef
  | ClickDataFilterDefinition
  | ReferrerFilterDef
  | TagFilterDef
  | SiteFilterDef
  | ChannelFilterDef
  | DeviceFilterDef
  | CountryFilterDef
  | PlatformFilterDef
  | ProductAvailabilityFilterDef;

export const readFilters = (str: string): FiltersDefinition =>
  JSON.parse(decodeURIComponent(str)) as FiltersDefinition;

export const printFilters = (filters: FiltersDefinition) =>
  encodeURIComponent(JSON.stringify(filters));
