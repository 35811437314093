import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Check as IconCheck, Edit as IconEdit } from 'react-feather';
import {
  matchAdvertiserAppSpace,
  useAdvertiserAppSpaces
} from '../../../../../advertiser-app/services/spaces';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip,
  MultiSelectorValueOption
} from '../../../../../components/MultiSelector';
import { SearchInput } from '../../../../../components/SearchInput';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { AdvertiserAppSpace } from '../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../domainTypes/document';
import { css } from '../../../../../emotion';
import { Section } from '../../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam,
  useStringQueryParam
} from '../../../../../routes';
import { formatDate, toMoment } from '../../../../../services/time';
import { IStatsBarItem, StatsBar } from '../../../../components/StatsBar';
import { updateSpace } from '../../../../services/space';

type Statuses = 'ACTIVE' | 'INACTIVE' | 'VERIFIED' | 'UNVERIFIED';

type ColumnName =
  | 'id'
  | 'name'
  | 'active'
  | 'verified'
  | 'createdAt'
  | 'tz'
  | 'currency';

const ClickInterceptor: React.FC = ({ children }) => {
  return <div onClick={(ev) => ev.stopPropagation()}>{children}</div>;
};

const TimezoneUpdateDialog: React.FC<{ d: Doc<AdvertiserAppSpace> }> = ({
  d
}) => {
  const tz = d.data.config.tz || '';
  const [nextTz, setNextTz] = useState(tz);
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const update = () =>
    updateSpace(d.id, {
      config: {
        ...d.data.config,
        tz: nextTz
      }
    }).then(close);
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        <IconEdit size={16} />
      </IconButton>

      <Dialog open={open} onClose={close}>
        <DialogTitle>Edit Timezone</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth={true}
            value={nextTz}
            onChange={(ev) => setNextTz(ev.target.value)}
          />
          <ButtonWithPromise
            onClick={update}
            pending="Updating..."
            variant="contained"
          >
            Update
          </ButtonWithPromise>
        </DialogContent>
      </Dialog>
    </>
  );
};

type D = Doc<AdvertiserAppSpace>;
type Column = IColumn<D, ColumnName>;

const COLUMNS: Column[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <div>{truncate(d.id, { length: 12 })}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => d.data.config.name,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'active',
    head: () => 'Active',
    cell: (d) => d.data.active && <IconCheck />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'verified',
    head: () => 'Verified',
    cell: (d) => d.data.verified && <IconCheck />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'tz',
    head: () => 'TZ',
    cell: (d) => (
      <ClickInterceptor>
        {d.data.config.tz || ''}
        <TimezoneUpdateDialog d={d} />
      </ClickInterceptor>
    ),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'currency',
    head: () => 'Currency',
    cell: (d) => <ClickInterceptor>{d.data.config.currency}</ClickInterceptor>,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'createdAt',
    head: () => 'Created at',
    cell: (d) => formatDate(toMoment(d.data.createdAt), 'YYYY/MM/DD'),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 90,
    flexGrow: 1
  }
];

const SORTERS: ItemSorters<D> = {
  id: {
    key: 'id',
    items: { sort: (d) => d.id, dir: 'asc' }
  },
  domain: {
    key: 'name',
    items: { sort: (d) => d.data.config.name, dir: 'asc' }
  },
  active: {
    key: 'active',
    items: { sort: (d) => d.data.active, dir: 'asc' }
  },
  verified: {
    key: 'verified',
    items: { sort: (d) => d.data.active, dir: 'asc' }
  },
  tz: {
    key: 'tz',
    items: { sort: (d) => d.data.config.tz || 'ZZZZZ', dir: 'asc' }
  },
  currency: {
    key: 'currency',
    items: { sort: (d) => d.data.config.currency || 'ZZZZZ', dir: 'asc' }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.data.createdAt.toMillis(), dir: 'desc' }
  }
};

const filterBySearchTerm = (spaces: D[], term: string) => {
  if (!term) {
    return spaces;
  }
  return spaces.filter((s) => matchAdvertiserAppSpace(s.data, term));
};

const filterByStatus = (spaces: D[], statuses: Statuses[]) => {
  if (!statuses.length) {
    return spaces;
  }
  return spaces.filter(
    (s) =>
      (statuses.includes('ACTIVE') && s.data.active) ||
      (statuses.includes('INACTIVE') && !s.data.active) ||
      (statuses.includes('VERIFIED') && s.data.verified) ||
      (statuses.includes('UNVERIFIED') && !s.data.verified)
  );
};

const filterSpaces = (docs: D[], term: string, statuses: Statuses[]) => {
  let ss = docs;
  ss = filterBySearchTerm(ss, term);
  ss = filterByStatus(ss, statuses);
  return ss;
};

const getRowColor = (space: Doc<AdvertiserAppSpace> | null, now: number) => {
  if (space) {
    const { verified } = space.data;
    // '#fff6ce' // a faint yellow
    // '#ffd9d9'; // a faint red
    if (verified) {
      return '#eeffe2'; // a faint green
    }
    return '#c3ebff'; // a faint blue
  }
  return 'none';
};

const getRowClassName = (d: D) => {
  const background = getRowColor(d, Date.now());
  return css(() => ({
    background: d.data.active
      ? background
      : `repeating-linear-gradient(90deg, ${background}, ${background} 5px, white 6px, white 8px)`
  }));
};

const STATUS_FILTERS: MultiSelectorValueOption<Statuses>[] = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE'
  },
  {
    label: 'Verified',
    value: 'VERIFIED'
  },
  {
    label: 'Unverified',
    value: 'UNVERIFIED'
  }
];
const STATUSES = STATUS_FILTERS.map((d) => d.value);

const toStatsBarData = (
  docs: Doc<AdvertiserAppSpace>[] | void,
  setFilters: (filters: Statuses[]) => void
): IStatsBarItem[] | void => {
  if (!docs) {
    return;
  }

  return [
    {
      label: 'Active',
      value: docs.filter((d) => d.data.active).length,
      onClick: () => setFilters(['ACTIVE'])
    },
    {
      label: 'Inactive',
      value: docs.filter((d) => !d.data.active).length,
      onClick: () => setFilters(['INACTIVE'])
    },
    {
      label: 'Verified',
      value: docs.filter((d) => d.data.verified).length,
      onClick: () => setFilters(['VERIFIED'])
    },
    {
      label: 'Unverified',
      value: docs.filter((d) => !d.data.verified).length,
      onClick: () => setFilters(['UNVERIFIED'])
    }
  ];
};

const Stats = ({
  docs,
  loading,
  error,
  setStatuses
}: {
  docs: void | Doc<AdvertiserAppSpace>[];
  loading: boolean;
  error: any;
  setStatuses: (next: Set<Statuses>) => void;
}) => {
  return (
    <StatsBar
      data={toStatsBarData(docs, (filters) => setStatuses(new Set(filters)))}
      loading={loading}
      error={error}
    />
  );
};
const DEFAULT_COLUMNS: ColumnName[] = [
  'id',
  'name',
  'active',
  'verified',
  'tz',
  'currency',
  'createdAt'
];

const ROW_TO_KEY = (d: D) => d.id;
const ROW_TO_HREF = (d: D) => `/spaces/advertisers/${d.id}`;

const StatusSelector = ({
  value,
  onChange
}: {
  value: Set<Statuses>;
  onChange: (nextvalue: Set<Statuses>) => void;
}) => {
  const isApplied = STATUSES.length !== value.size;
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Filter by status"
      options={STATUS_FILTERS}
      allOption={<strong>All</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(new Set(STATUSES))}
        label="Filter by status"
        appliedLabel={getAppliedLabel(
          'status',
          isApplied
            ? STATUS_FILTERS.filter((s) => value.has(s.value)).map(
                (s) => s.label as string
              )
            : []
        )}
      />
    </MultiSelector>
  );
};

export const PageAdvertiserAppSpacesList = () => {
  const [docs, loading, error] = useAdvertiserAppSpaces();

  const [search, setSearch] = useStringQueryParam('q');

  const [statuses, setStatuses] = useQueryParam(
    'status',
    (p) => new Set(p ? queryParamToList<Statuses>(p) : STATUSES),
    (ss) => (ss.size === STATUSES.length ? undefined : setToQueryParam(ss))
  );

  const [columnNames, setColumnNames] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const rows = useMemo(
    () => (docs ? filterSpaces(docs, search, Array.from(statuses)) : docs),
    [docs, search, statuses]
  );

  console.log(rows);

  return (
    <>
      <Section>
        <Stats
          docs={docs}
          loading={loading}
          error={error}
          setStatuses={setStatuses}
        />
      </Section>
      <Section>
        <TableToolbar padding="dense" style={{ padding: '12px 0' }}>
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="Search by Space ID or Name"
            autoFocus={true}
            width={300}
          />
          <TableToolbarSection>
            <StatusSelector value={statuses} onChange={setStatuses} />
            <ColumnSelector
              value={columnNames}
              onChange={setColumnNames}
              columns={COLUMNS}
            />
          </TableToolbarSection>
        </TableToolbar>
        {loading && <Loader />}
        {rows && (
          <RowsRenderer
            variant="contained"
            columns={columns}
            rows={rows}
            rowToKey={ROW_TO_KEY}
            sorter={sorter || SORTERS.domain}
            sortDirection={dir}
            renderHead={true}
            headProps={{ sticky: true, offset: 48 }}
            onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.url, d])}
            chunkSize={30}
            rootMargin="300px"
            rowHeight={ROW_HEIGHTS.dense}
            otherProps={undefined}
            rowToHref={ROW_TO_HREF}
            rowToClassName={getRowClassName}
          />
        )}
      </Section>
    </>
  );
};
