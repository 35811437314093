import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { publishTopic } from '../../services/pubsub';

export const PagePubSub = () => {
  const [topic, setTopic] = useState('');
  const [payload, setPayload] = useState('{\n  \n}');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Section>
        <FlexContainerVertical fullWidth spacing={2}>
          <TextField
            label="Topic"
            fullWidth
            spellCheck="false"
            value={topic}
            onChange={(ev) => setTopic(ev.target.value)}
            variant="outlined"
          />
          <TextField
            label="Payload (JSON)"
            key="query"
            fullWidth
            spellCheck={false}
            inputProps={{
              style: {
                fontFamily: 'monospace'
              }
            }}
            value={payload}
            onChange={(ev) => {
              setPayload(ev.target.value);
            }}
            multiline
            rows={25}
            variant="outlined"
          />
          <FlexContainer justifyContent="flex-end" fullWidth>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Publishing..."
              onClick={async () => {
                try {
                  await publishTopic({
                    topic: topic.trim(),
                    payload: payload ? JSON.parse(payload) : undefined
                  });
                  enqueueSnackbar('Published successfully');
                } catch (err) {
                  console.log('ERR', err);
                  enqueueSnackbar(`Something went wrong - ${err?.message}`, {
                    variant: 'error'
                  });
                }
              }}
            >
              Publish
            </ButtonWithPromise>
          </FlexContainer>
        </FlexContainerVertical>
      </Section>
    </Page>
  );
};
