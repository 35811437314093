import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import { styled } from '../../../../../emotion';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import {
  AnchoredSections,
  AnchorLinks,
  ISection
} from '../../../../layout/AnchoredSection';
import { TopNav } from '../../../../layout/TopNav';
import { useSegmentCampaign } from '../../service';
import { Data } from './components/Data';
import { General } from './components/General';
import { Pg } from './components/Pg';
import { Redis } from './components/Redis';

const Header = styled('h2')`
  display: flex;
  align-items: center;
  color: white;

  a {
    color: white;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

export const PageCampaignDetails = ({ id }: { id: string }) => {
  const [campaign, loading] = useSegmentCampaign(id);

  if (loading) {
    return <Loader height={500} />;
  }
  if (!campaign) {
    return <Centered height={500}>Campaign not found</Centered>;
  }

  const SECTIONS: ISection[] = [
    {
      anchor: 'general',
      label: 'General',
      el: <General campaign={campaign} />
    },
    {
      anchor: 'redis',
      label: 'Redis',
      el: <Redis campaign={campaign} />
    },
    {
      anchor: 'pg',
      label: 'PG',
      el: <Pg campaign={campaign} />
    },
    {
      anchor: 'data',
      label: 'Data',
      el: <Data d={campaign} />
    }
  ];
  return (
    <>
      <TopNav>
        <FlexContainer justifyContent="space-between">
          <Header>
            <Link to="/campaigns">Campaigns</Link>
            <ChevronRight />
            <div>{campaign?.data.name}</div>
          </Header>
        </FlexContainer>
        <AnchorLinks sections={SECTIONS} offset={100} />
      </TopNav>
      <Page width="FULL" style={{ marginTop: 108 }}>
        <AnchoredSections sections={SECTIONS} />
      </Page>
    </>
  );
};
