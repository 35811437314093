import React from 'react';
import { DocReference } from '../../../domainTypes/document';
import { toFirestoreConsole } from '../../services/firebase';
import { LinkExternal } from '../LinkExternal';

export const FirestoreLink: React.FC<{ doc: DocReference }> = ({
  doc,
  children
}) => {
  return (
    <LinkExternal href={toFirestoreConsole(doc.collection, doc.id)}>
      {children || 'Open in Firestore console'}
    </LinkExternal>
  );
};
