import { styled } from '../emotion';

export const FlexContainer = styled('div')<{
  justifyContent?: React.CSSProperties['justifyContent'];
  spacing?: number;
  wrap?: any;
  fullWidth?: boolean;
  alignItems?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  marginBottom?: number; // a multiplier of spacing,
  marginTop?: number; // a multiplier of spacing,
  paddingTop?: number;
  paddingBottom?: number;
  // overflowX?: React.CSSProperties['overflowX'];
  maxWidth?: number;
  width?: number;
  backgroundColor?: string;
}>((p) => ({
  display: 'flex',
  justifyContent: p.justifyContent || 'flex-start',
  flexWrap: p.wrap || undefined,
  alignItems: p.alignItems || 'center',
  flexDirection: p.direction || 'row',
  gap: p.theme.spacing(p.spacing === undefined ? 1 : p.spacing),
  marginBottom: p.theme.spacing(p.marginBottom || 0),
  marginTop: p.theme.spacing(p.marginTop || 0),
  paddingBottom: p.theme.spacing(p.paddingBottom || 0),
  paddingTop: p.theme.spacing(p.paddingTop || 0),
  width:
    p.width === undefined
      ? p.fullWidth
        ? '100%'
        : undefined
      : p.theme.spacing(p.width),
  // overflowX: p.overflowX || 'visible',
  maxWidth: p.maxWidth === undefined ? undefined : p.theme.spacing(p.maxWidth),
  backgroundColor: p.backgroundColor || undefined
}));

export const FlexItem = styled('div')<{ flex?: number }>((p) => ({
  flex: p.flex ?? 1
}));

export const FlexContainerVertical = styled(FlexContainer)<{
  justifyContent?: React.CSSProperties['justifyContent'];
  spacing?: number;
  wrap?: any;
  fullWidth?: boolean;
  alignItems?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  marginBottom?: number; // a multiplier of spacing,
  marginTop?: number; // a multiplier of spacing,
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  padding?: number;
  backgroundColor?: string;
  // overflowX?: React.CSSProperties['overflowX'];
}>((p) => ({
  flexDirection: 'column',
  width: p.fullWidth === false ? undefined : '100%', // defaults to full width, but can opt out
  flexWrap: p.wrap || undefined,
  alignItems: p.alignItems || 'flex-start',
  gap: p.theme.spacing(p.spacing === undefined ? 1 : p.spacing),
  marginBottom: p.theme.spacing(p.marginBottom || 0),
  marginTop: p.theme.spacing(p.marginTop || 0),
  padding: p.theme.spacing(p.padding || 0),
  paddingBottom: p.theme.spacing(p.paddingBottom || 0),
  paddingTop: p.theme.spacing(p.paddingTop || 0),
  paddingLeft: p.theme.spacing(p.paddingLeft || 0),
  paddingRight: p.theme.spacing(p.paddingRight || 0),
  backgroundColor: p.backgroundColor || undefined
  // overflowX: p.overflowX || 'visible'
}));
