import { compact } from 'lodash';
import shortid from 'shortid';
import { Doc } from '../../../../domainTypes/document';
import {
  AttributionRule,
  AttributionRulePg
} from '../../../../domainTypes/performanceLabelRules';
import { EMPTY_RULE_GROUP } from '../../../../domainTypes/rules';
import { useDeepEqual } from '../../../../hooks/useDeepEqual';
import { usePromise } from '../../../../hooks/usePromise';
import { refreshTimestamp } from '../../../../services/db';
import { downloadFileFromBlob, toCsv } from '../../../../services/file';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { now } from '../../../../services/time';
import { removeTrailingSlash } from '../../../../services/url';
import { FS } from '../../../../versions';

export const fsAttributionRuleToPg = (
  d: Doc<AttributionRule>
): AttributionRulePg => {
  return {
    space_id: d.data.spaceId,
    id: d.id,
    created_at: d.data.createdAt,
    match: d.data.match || EMPTY_RULE_GROUP,
    apply: d.data.apply
  };
};

export const pgAttributionRuleToFs = (
  d: AttributionRulePg
): Doc<AttributionRule> => {
  return {
    id: d.id,
    collection: FS.attributionRules,
    data: {
      spaceId: d.space_id,
      createdAt: d.created_at,
      match: d.match,
      apply: d.apply
    }
  };
};

const _getRules = async (
  spaceId: string,
  args: { limit?: number; offset?: number; search?: string }
) => {
  const rules = await callFirebaseFunction<AttributionRulePg[]>(
    'labelRules-getRules',
    {
      spaceId,
      args
    }
  );
  rules.forEach((r) => {
    r.created_at = refreshTimestamp(r.created_at);
  });
  return rules.map(pgAttributionRuleToFs);
};

const RULES_CACHE_BY_SPACE: {
  [spaceId: string]: {
    [key: string]: Promise<Doc<AttributionRule>[]>;
  };
} = {};

export const flushRulesCacheForSpace = (spaceId: string) => {
  RULES_CACHE_BY_SPACE[spaceId] = {};
};

export const getRules = async (
  spaceId: string,
  args: { limit?: number; offset?: number; search?: string },
  opts: { noCache?: boolean } = {}
) => {
  if (opts.noCache) {
    return _getRules(spaceId, args);
  }
  const key = [
    spaceId,
    `limit:${args.limit ?? '---'}`,
    `offset:${args.offset ?? '---'}`,
    `search:${args.search ?? '---'}`
  ].join('|||');
  const spaceCache = (RULES_CACHE_BY_SPACE[spaceId] =
    RULES_CACHE_BY_SPACE[spaceId] || {});
  if (!spaceCache[key]) {
    spaceCache[key] = _getRules(spaceId, args);
  }
  return spaceCache[key];
};

export const useRules = (
  spaceId: string,
  args: { limit?: number; offset?: number; search?: string }
) => {
  return usePromise(() => getRules(spaceId, args), [
    spaceId,
    useDeepEqual(args)
  ]);
};

const normalizeTrailingSlashesInAttributionRule = (d: Doc<AttributionRule>) => {
  d.data.apply.forEach((a) => {
    if (a.type === 'PAGE') {
      a.value = removeTrailingSlash(a.value);
    }
    if (a.type === 'SITE') {
      a.value = removeTrailingSlash(a.value);
    }
  });
  return d;
};

export const createRules = async (
  spaceId: string,
  rules: AttributionRule[]
) => {
  const createdAt = now();
  const fsRules = rules.map((r) =>
    normalizeTrailingSlashesInAttributionRule({
      id: shortid(),
      collection: FS.attributionRules,
      data: {
        ...r,
        createdAt
      }
    })
  );
  const pgRules = fsRules.map((r) => fsAttributionRuleToPg(r));
  await callFirebaseFunction<AttributionRulePg[]>('labelRules-createRules', {
    spaceId,
    rules: pgRules
  });
  flushRulesCacheForSpace(spaceId);
  return {
    fs: fsRules,
    pg: pgRules
  };
};

export const createRule = async (spaceId: string, rule: AttributionRule) => {
  const res = await createRules(spaceId, [rule]);
  return {
    fs: res.fs[0],
    pg: res.pg[0]
  };
};

export const removeRule = async (spaceId: string, ruleId: string) => {
  await callFirebaseFunction('labelRules-removeRules', {
    spaceId,
    ruleIds: [ruleId]
  });
  flushRulesCacheForSpace(spaceId);
};

export const exportRules = async (spaceId: string) => {
  const limit = 10000;
  const docs = await getRules(spaceId, { limit }, { noCache: true });

  // Create a CSV file in the browser including the rules
  const csvObj = compact(
    docs.map((d) => {
      // I tried and failed to use the typeguards
      const rule = d.data.match?.els[0] as any;
      const apply = d.data.apply[0];

      if (!rule) {
        return null;
      }

      const matchAttribute = rule.k;
      const matchCondition = rule.op;
      const matchValue = rule.v;
      const applyValue = apply.value;

      return {
        Match: matchAttribute,
        Condition: matchCondition,
        Value: matchValue,
        Apply: applyValue
      };
    })
  );

  const csv = await toCsv(csvObj);
  const filename = `rules.csv`;
  const fileType = 'csv';

  downloadFileFromBlob(filename, fileType, csv);
};
