import React from 'react';
import { EMPTY_OBJ } from '../../domainTypes/emptyConstants';
import { styled } from '../../emotion';
import { withStoppedPropagation } from '../../helpers';
import { useTheme } from '../../themes';

export const LinkExternal: React.FC<{
  href: string;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  color?: 'primary' | 'secondary' | 'text';
}> = ({ href, title, children, className, style = EMPTY_OBJ, color }) => {
  const theme = useTheme();
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={title || href}
      style={
        color === 'primary'
          ? { ...style, color: theme.custom.colors.primary.main }
          : color === 'secondary'
          ? { ...style, color: theme.custom.colors.secondary.main }
          : color === 'text'
          ? { ...style, color: theme.palette.text.primary }
          : style
      }
      onClick={withStoppedPropagation(() => {}, false)}
    >
      {children || href}
    </a>
  );
};

export const LinkExternalWithEllipsis = styled(LinkExternal)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
