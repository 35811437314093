import { Tooltip } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy as IconCopy } from 'react-feather';
import { styled } from '../../emotion';
import { withStoppedPropagation } from '../../helpers';
import { useSnackbar } from '../../hooks/useSnackbar';
import * as tracking from '../../tracking';

type Props = {
  text?: string;
  notificationMessage?: string;
  label?: string | JSX.Element;
};

export const CopyButton = ({
  text = '',
  label = <IconCopy size={18} />,
  notificationMessage = 'Link copied',
  ...props
}: Props & ButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CopyToClipboard text={text}>
      <Button
        size="small"
        color="inherit"
        onClick={withStoppedPropagation(() => {
          tracking.sendEvent({
            category: tracking.toAppCategory(),
            action: 'Copy',
            label: text
          });
          return enqueueSnackbar(notificationMessage, { variant: 'info' });
        })}
        title="Copy"
        {...props}
      >
        {label}
      </Button>
    </CopyToClipboard>
  );
};

const Id = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 124px;
  text-overflow: ellipsis;
  text-align: center;
  font-family: Menlo, Consolas, Courier;
  cursor: pointer;
`;

export const CopyId = ({ id }: { id: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Tooltip title="Copy to clipboard">
      <CopyToClipboard
        text={id}
        onCopy={() => {
          enqueueSnackbar('ID copied', { variant: 'info' });
        }}
      >
        <Id
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          {id}
        </Id>
      </CopyToClipboard>
    </Tooltip>
  );
};
