export interface IAffilimateConfig {
  services: {
    schedules: boolean;
    mail: boolean;
    denormalization: boolean;
    modificationCheck: boolean;
    linkCheck: boolean;
    linkCheckV2: boolean;
    realTimeEtl: boolean;
    realTimeSalesEtl: boolean;
    clickhouseInserts: boolean;
    mailDailyEarningsReport: boolean;
  };
}

export type AffilimateService = keyof IAffilimateConfig['services'];

export const EMPTY_AFFILIMATE_CONFIG = (): IAffilimateConfig => ({
  services: {
    schedules: true,
    mail: true,
    denormalization: true,
    modificationCheck: true,
    linkCheck: true,
    linkCheckV2: true,
    realTimeEtl: false,
    realTimeSalesEtl: false,
    clickhouseInserts: true,
    mailDailyEarningsReport: false
  }
});

export const AFFILIMATE_CONFIG_ID = 'config';
