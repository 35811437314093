import { groupBy, Omit } from 'lodash';
import { Moment } from 'moment-timezone';
import { ReactElement } from 'react';
import {
  CheckCircle,
  Circle,
  Clock,
  HelpCircle,
  PieChart
} from 'react-feather';
import { Calendar } from 'react-feather/dist';
import {
  ISegmentCampaign,
  SegmentCampaignStatus
} from '../../../domainTypes/campaigns';
import { COLORS } from '../../../domainTypes/colors';
import { Doc } from '../../../domainTypes/document';
import { ISpace } from '../../../domainTypes/space';
import { toMoment } from '../../../services/time';

type BaseSegmentCampaign = Omit<ISegmentCampaign, 'status' | 'timeframe'>;

export interface DraftSegmentCampaign extends BaseSegmentCampaign {
  status: 'draft';
}

export interface ProposedSegmentCampaign extends BaseSegmentCampaign {
  status: 'proposed';
}

export interface ApprovedSegmentCampaign extends BaseSegmentCampaign {
  status: 'approved';
}

export interface ScheduledSegmentCampaign extends BaseSegmentCampaign {
  status: 'scheduled';
  timeframe: {
    start: Moment;
    end: Moment;
  };
}

export interface RunningSegmentCampaign extends BaseSegmentCampaign {
  status: 'running';
  timeframe: {
    start: Moment;
    end: Moment;
  };
}

export interface CompletedSegmentCampaign extends BaseSegmentCampaign {
  status: 'completed';
  timeframe: {
    start: Moment;
    end: Moment;
  };
}

export type SegmentCampaign =
  | DraftSegmentCampaign
  | ProposedSegmentCampaign
  | ApprovedSegmentCampaign
  | ScheduledSegmentCampaign
  | RunningSegmentCampaign
  | CompletedSegmentCampaign;

export type ReadySegmentCampaign =
  | RunningSegmentCampaign
  | CompletedSegmentCampaign;

const DEFAULT_SEGMENT_CAMPAIGN: Omit<
  DraftSegmentCampaign,
  'spaceId' | 'id' | 'spaceProfile' | 'log'
> = {
  name: '',
  type: 'segment',
  managers: [],
  team: '',
  incentives: [],
  goals: [],
  partner: null,
  paused: false,
  status: 'draft',
  segments: []
};

export const createEmptySegmentCampaign = (
  space: ISpace,
  id: string
): DraftSegmentCampaign => {
  return {
    spaceId: space.id,
    spaceProfile: space.profile,
    id,
    log: [],
    ...DEFAULT_SEGMENT_CAMPAIGN
  };
};

const readTimeframe = (
  timeframe: ISegmentCampaign['timeframe'] | undefined
): { start: Moment; end: Moment } => {
  if (!timeframe) {
    throw new Error('Timeframe is required');
  }

  return {
    start: toMoment(timeframe.start),
    end: toMoment(timeframe.end)
  };
};

export const readSegmentCampaign = ({
  status,
  ...rest
}: Pick<
  ISegmentCampaign,
  'spaceId' | 'id' | 'spaceProfile' | 'log' | 'status'
> &
  Partial<
    Omit<ISegmentCampaign, 'spaceId' | 'id' | 'spaceProfile' | 'log' | 'status'>
  >): SegmentCampaign => {
  const base: BaseSegmentCampaign = {
    ...DEFAULT_SEGMENT_CAMPAIGN,
    ...rest,
    log: rest.log ?? []
  };

  switch (status) {
    case 'draft':
      return {
        ...base,
        status: 'draft'
      };
    case 'proposed':
      return {
        ...base,
        status: 'proposed'
      };
    case 'approved':
      return {
        ...base,
        status: 'approved'
      };
    case 'scheduled':
      return {
        ...base,
        status: 'scheduled',
        timeframe: readTimeframe(rest.timeframe)
      };
    case 'running':
      return {
        ...base,
        status: 'running',
        timeframe: readTimeframe(rest.timeframe)
      };
    case 'completed':
      return {
        ...base,
        status: 'completed',
        timeframe: readTimeframe(rest.timeframe)
      };
  }
};

export const groupCampaignsByStatus = (
  campaigns: Doc<SegmentCampaign>[]
): Record<SegmentCampaignStatus, Array<Doc<SegmentCampaign>>> => {
  const byStatus = groupBy(campaigns, (c) => c.data.status);
  return {
    draft: byStatus['draft'] ?? [],
    proposed: byStatus['proposed'] ?? [],
    approved: byStatus['approved'] ?? [],
    scheduled: byStatus['scheduled'] ?? [],
    running: byStatus['running'] ?? [],
    completed: byStatus['completed'] ?? []
  };
};

export const campaignStatusTitle = (status: SegmentCampaignStatus): string => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'proposed':
      return 'Proposed';
    case 'approved':
      return 'Approved';
    case 'scheduled':
      return 'Scheduled';
    case 'running':
      return 'Running';
    case 'completed':
      return 'Completed';
  }
};

export const campaignStatusColor = (status: SegmentCampaignStatus): string => {
  switch (status) {
    case 'draft':
      return '#828282';
    case 'proposed':
      return COLORS.purple.purple5;
    case 'approved':
      return '#FAAD14';
    case 'scheduled':
      return '#FAAD14';
    case 'running':
      return '#39B185';
    case 'completed':
      return '#000000';
  }
};

export const CampaignStatusIcon = ({
  status,
  size = 18,
  color = campaignStatusColor(status)
}: {
  status: SegmentCampaignStatus;
  size?: number;
  color?: string;
}): ReactElement => {
  switch (status) {
    case 'draft':
      return <Circle size={size} color={color} />;
    case 'proposed':
      return <HelpCircle size={size} color={color} />;
    case 'approved':
      return <Clock size={size} color={color} />;
    case 'scheduled':
      return <Calendar size={size} color={color} />;
    case 'running':
      return <PieChart size={size} color={color} />;
    case 'completed':
      return <CheckCircle size={size} color={color} />;
  }
};
