import firebase from 'firebase/app';
import { compact, keyBy, mapValues, startCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { UNKNOWN } from '../../components/GroupableList';
import { Timeframe } from '../../domainTypes/analytics';
import { CurrencyCode } from '../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import {
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  toEarningFromMinimal
} from '../../domainTypes/performance';
import {
  CreateProductArgs,
  IPostgresProduct,
  IProduct,
  IProductAliasMerged,
  IProductDestination,
  PostgresProductQuery
} from '../../domainTypes/product';
import { ISpace } from '../../domainTypes/space';
import { fireAndForget } from '../../helpers';
import {
  batchDelete,
  batchSet,
  refreshTimestamp,
  store,
  updateDoc,
  useMappedLoadingValue
} from '../../services/db';
import { CF, FS } from '../../versions';
import { toComparableTimeframe } from '../analytics';
import {
  getCurrentSpace,
  getCurrentUser,
  useCurrentUser
} from '../currentUser';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../firecache/documentListener';
import { getPartnerForUrl } from '../partner';
import { useEarnings } from '../sales/earnings';
import { usePostgres } from '../sales/service';
import { now, timeframeToMs } from '../time';
import { removeTrailingSlash } from '../url';
import {
  flushPgProductsCacheForSpace,
  getPgProductsCacheForSpace
} from './cache';

const productsCollection = () => store().collection(FS.products);

export const toProductDoc = generateToDocFn<IProduct>((p) => {
  p.aliases = p.aliases || []; // introduced later - making sure it's always defined
  p.destinations = p.destinations || []; // introduced later - making sure it's always defined
  p.issues = p.issues || [];
  return p;
});

const getProductsQuery = (spaceId: string) => {
  return productsCollection().where('spaceId', '==', spaceId);
};

const getCollectionListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener(getProductsQuery(spaceId), toProductDoc)
);

const getDocListener = createDocumentListenerGetter(
  (productId) => productsCollection().doc(productId),
  toProductDoc
);

export const getProductsBySpaceId = (spaceId: string) => {
  return getCollectionListener(spaceId).get();
};

export const getProductDocumentListener = createDocumentListenerGetter(
  (id) => productsCollection().doc(id),
  toProductDoc
);

export const useProduct = (productId: string) => {
  return useDocumentListener(getDocListener(productId));
};

/**
 * @deprecated
 */
export const useProducts = () => {
  const { space } = useCurrentUser();
  const pg = usePostgres();
  useEffect(() => {
    pg && console.log('Using deprecated hook useProducts');
  }, [pg]);
  return useProductsBySpaceId(space.id);
};

export const useProductsBySpaceId = (spaceId: string) => {
  const pg = usePostgres();
  useEffect(() => {
    pg && console.log('Using deprecated hook useProducts');
  }, [pg]);
  return useCollectionListener(getCollectionListener(spaceId));
};

/**
 * @deprecated
 */
export const useHasAnyProducts = () => {
  return useMappedLoadingValue(
    useCollection(getProductsQuery(getCurrentSpace().id).limit(1)),
    (v) => v && v.docs && !!v.docs.length
  );
};

/**
 * @deprecated Unused
 */
export const useEarningsByProductInTimeframe = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const queries = useMemo<EarningsArgsGroupedInTimeframe[]>(() => {
    const tf: Timeframe = { start, end, tz };
    return compact([
      {
        type: 'groupedInTimeframe',
        d: { groupBy: ['product_id'], dates: timeframeToMs(tf), currency }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          groupBy: ['product_id'],
          dates: timeframeToMs(toComparableTimeframe(tf)),
          currency
        }
      }
    ]);
  }, [start, end, tz, compare, currency]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      console.log('useEarningsByProductInTimeframe', r);
      const curr = r.res[0];
      const prev: EarningsRespGroupedInTimeframe | undefined = r.res[1];
      const prevByProductId = keyBy(
        prev?.d || [],
        (k) => (k.group['product_id'] as string) || UNKNOWN
      );
      const currByProductId = keyBy(
        curr.d,
        (k) => (k.group['product_id'] as string) || UNKNOWN
      );
      return mapValues(currByProductId, (x, k) => {
        const p = prevByProductId[k]?.d;
        return {
          prev: p ? toEarningFromMinimal(p) : EMPTY_EARNING(currency),
          curr: toEarningFromMinimal(x.d)
        };
      });
    }
  );
};

export const deleteProducts = async (spaceId: string, productIds: string[]) => {
  const products = await Promise.all(
    productIds.map((productId) =>
      store()
        .collection(FS.products)
        .doc(productId)
        .get()
        .then((s) => (s.exists ? toProductDoc(s) : null))
    )
  ).then(compact);

  const finalProductIds = products.map((p) => p.id);

  return Promise.all([
    batchDelete(FS.products, finalProductIds),
    callFirebaseFunction(CF.products.deleteProductsFromPg, {
      spaceId,
      // Technically should use finalProductIds here - but in case something went wrong with
      // a prior PG deletion, a product id that's NOT in FS anymore might appear here.
      // Try to remove it again, so that the system cleans up after itself.
      productIds
    })
  ]).then(async () => {
    // don't wait - fire and forget
    callFirebaseFunction(
      CF.trackingConfig_v2.removeProductsFromTrackingConfig,
      {
        spaceId,
        products
      }
    );
    flushPgProductsCacheForSpace(spaceId);
    return products;
  });
};

export const deleteProductsOld = (
  spaceId: string,
  products: Doc<IProduct>[]
) => {
  return batchDelete(
    FS.products,
    products.map((p) => p.id)
  ).then(() => {
    // don't wait - fire and forget
    callFirebaseFunction(
      CF.trackingConfig_v2.removeProductsFromTrackingConfig,
      {
        spaceId,
        products
      }
    );
    return products;
  });
};

// TODO When the url changes, we probably should also check
// if the destination changed!
export const updateProduct = (
  id: string,
  { name, url }: Pick<IProduct, 'name' | 'url'>
) => {
  return productsCollection().doc(id).update({
    name,
    url
  });
};

export const updateProductName = async (
  product: Doc<IProduct>,
  name: string
) => {
  product.data.name = name;
  await updateDoc<IProduct>(product, (d) => ({ name: d.name }));

  const spaceId = product.data.spaceId;
  await callFirebaseFunction(CF.products.pushProductsToPg, {
    spaceId,
    products: [product]
  });

  flushPgProductsCacheForSpace(spaceId);
};

const toAliasMerged = (doc: Doc<IProduct>): IProductAliasMerged => {
  const currentUser = getCurrentUser();
  return {
    type: 'merged',
    createdBy: currentUser ? currentUser.id : 'admin',
    createdAt: now(),

    url: doc.data.url,
    formerProductId: doc.id
  };
};

export const mergeProducts = (target: Doc<IProduct>, src: Doc<IProduct>) => {
  return productsCollection()
    .doc(target.id)
    .update({
      aliases: [...target.data.aliases, toAliasMerged(src), ...src.data.aliases]
    });
};

const createProductDoc = (
  spaceId: string,
  createdBy: string,
  args: CreateProductArgs,
  createdAt: firebase.firestore.Timestamp
): Doc<IProduct> => {
  const destinations: IProductDestination[] = [
    {
      url: args.destinationUrl,
      foundAt: createdAt
    }
  ];
  return {
    id: shortid(),
    collection: FS.products,
    data: {
      name: args.name,
      url: args.url,
      spaceId,
      aliases: [],
      destinations,
      createdAt,
      createdBy,
      issues: [],
      partnerKey: null
    }
  };
};

const notifyOtherPartiesAboutCreatedProducts = async (
  spaceId: string,
  docs: Doc<IProduct>[],

  opts: { trackImportEvent?: boolean } = {}
) => {
  await Promise.all([
    callFirebaseFunction(CF.trackingConfig_v2.addProductsToTrackingConfig, {
      spaceId,
      products: docs,
      trackImportEvent: !!opts.trackImportEvent
    }),
    callFirebaseFunction(CF.products.pushProductsToPg, {
      spaceId,
      products: docs
    })
  ]);
  flushPgProductsCacheForSpace(spaceId);
};

export const createProducts = async (
  createdBy: string,
  space: ISpace,
  products: CreateProductArgs[],
  createdAt: firebase.firestore.Timestamp,
  opts: { trackImportEvent?: boolean } = {}
) => {
  const docs = products.map((p) =>
    createProductDoc(space.id, createdBy, p, createdAt)
  );

  await batchSet(FS.products, docs);

  await Promise.all([
    notifyOtherPartiesAboutCreatedProducts(space.id, docs, opts),
    fireAndForget(() =>
      callFirebaseFunction(
        CF.pages.tryRecreateExistingScreenshots,
        {
          spaceId: space.id,
          urls: [
            ...products.reduce<Set<string>>((m, p) => {
              p.pageUrls.forEach((url) => m.add(removeTrailingSlash(url)));
              return m;
            }, new Set())
          ]
        },
        600
      )
    ),
    async () => {
      if (!space.onboarding.importedProducts) {
        const update: Partial<ISpace> = {
          onboarding: {
            ...space.onboarding,
            importedProducts: {
              finishedAt: createdAt,
              finishedBy: createdBy
            }
          }
        };
        await store().collection(FS.spaces).doc(space.id).update(update);
      }
    }
  ]);

  return docs;
};

export const createProduct = async (name: string, url: string) => {
  const space = getCurrentSpace();
  const spaceId = space.id;
  const createdAt = now();
  const createdBy = getCurrentUser().id;
  const { destinationUrl } = await callFirebaseFunction(
    CF.products.getDestinationUrl,
    { spaceId, url }
  );
  const doc = createProductDoc(
    spaceId,
    createdBy,
    {
      name,
      url,
      destinationUrl,
      pageUrls: [],
      partnerKey: getPartnerForUrl(destinationUrl).key
    },
    createdAt
  );

  await productsCollection().doc(doc.id).set(doc.data);
  await notifyOtherPartiesAboutCreatedProducts(spaceId, [doc]);

  return doc;
};

export const findProductsInSpaceByName = (spaceId: string, name: string) => {
  const lowerCased = name.toLowerCase();
  return getProductsBySpaceId(spaceId).then((docs) =>
    docs.filter((d) => d.data.name.toLowerCase().indexOf(lowerCased) !== -1)
  );
};

export const findProductsByName = (name: string) =>
  findProductsInSpaceByName(getCurrentSpace().id, name);

export const getDestinationUrlForProduct = (product: IProduct) => {
  const destination = product.destinations[product.destinations.length - 1];
  return destination ? destination.url : product.url;
};

export const tryUpdateDestinationUrlForProduct = async (p: Doc<IProduct>) => {
  const { spaceId, url } = p.data;
  const destUrl = getDestinationUrlForProduct(p.data);

  const { destinationUrl: nextDestUrl } = await callFirebaseFunction(
    CF.products.getDestinationUrl,
    { spaceId, url }
  );

  if (!nextDestUrl) {
    return false;
  }

  if (removeTrailingSlash(nextDestUrl) === removeTrailingSlash(destUrl)) {
    return false;
  }

  const destinations: IProductDestination[] = [
    ...p.data.destinations,
    {
      url: nextDestUrl,
      foundAt: now()
    }
  ];

  p.data.destinations = destinations;
  await updateDoc<IProduct>(p, (d) => ({ destinations: d.destinations }));
  await notifyOtherPartiesAboutCreatedProducts(spaceId, [p]);
  flushPgProductsCacheForSpace(spaceId);
  return true;
};

export const tryUpdateDestinationUrlForProductById = async (
  productId: string
) => {
  const p = await store()
    .collection(FS.products)
    .doc(productId)
    .get()
    .then((s) => (s.exists ? toProductDoc(s) : null));

  if (!p) {
    return false;
  }
  return tryUpdateDestinationUrlForProduct(p);
};

const refreshPostgresProduct = (d: IPostgresProduct) => {
  d.created_at = refreshTimestamp(d.created_at);
  d.issues.forEach((i) => {
    i.createdAt = refreshTimestamp(i.createdAt);
    i.updatedAt = refreshTimestamp(i.updatedAt);
    i.mutedAt = refreshTimestamp(i.mutedAt);
  });
  return d;
};

// UNCACHED
// TODO - this should pump items in the cache too!
const getProductsPg = async (
  spaceId: string,
  q?: Omit<PostgresProductQuery, 'returnIdsOnly'>
) => {
  return callFirebaseFunction<{ time: number; ds: IPostgresProduct[] }>(
    'products-getProductsPg',
    {
      spaceId,
      q
    }
  ).then((r) => {
    r.ds.forEach(refreshPostgresProduct);
    return r.ds;
  });
};

export const getProductsByIdPg = (spaceId: string, productIds: string[]) => {
  const spaceCache = getPgProductsCacheForSpace(spaceId).products;
  const uncached = productIds.filter((id) => !spaceCache[id]);
  if (uncached.length) {
    const req = getProductsPg(spaceId, { id: uncached }).then((ds) =>
      keyBy(ds, (d) => d.id)
    );
    uncached.forEach((id) => {
      spaceCache[id] = req.then((byId) => byId[id] || null);
    });
  }
  return Promise.all(productIds.map((id) => spaceCache[id])).then(compact);
};

export const normalizeLinkUrl = (url: string, partnerKey: string) => {
  if (!url) {
    return { normalizedUrl: url, usingModifiedUrl: false };
  }
  try {
    const urlObj = new URL(url);
    if (partnerKey === 'howl') {
      if (!urlObj.searchParams.has('article_name')) {
        urlObj.searchParams.set('article_name', 'placeholder');
        return { normalizedUrl: urlObj.toString(), usingModifiedUrl: true };
      }
    }
    return { normalizedUrl: url, usingModifiedUrl: false };
  } catch (e) {
    return { normalizedUrl: url, usingModifiedUrl: false };
  }
};

export const getProductByIdPg = (
  spaceId: string,
  productId: string
): Promise<IPostgresProduct | null> => {
  return getProductsByIdPg(spaceId, [productId]).then((ps) => ps[0] || null);
};

const PARTNER_KEYS_WTH_EXTRACTABLE_DEEPLINKS = [
  'cj',
  'skimlinks',
  'shareasale',
  'rakuten',
  'pepperjam',
  'awin',
  'partnerize',
  'impact',
  'howl',
  'avantlink',
  'sovrn'
];

export const extractDeepLink = ({
  name,
  url,
  partnerKey
}: {
  name: string;
  url: string;
  partnerKey: string;
}) => {
  try {
    const urlObj = new URL(url);

    if (
      ['cj', 'skimlinks', 'pepperjam', 'howl', 'avantlink'].includes(partnerKey)
    ) {
      return urlObj.searchParams.get('url');
    }

    if (['shareasale'].includes(partnerKey)) {
      return urlObj.searchParams.get('urllink');
    }

    if (['rakuten'].includes(partnerKey)) {
      return urlObj.searchParams.get('murl');
    }

    if (['awin'].includes(partnerKey)) {
      return urlObj.searchParams.get('ued');
    }

    if (['impact'].includes(partnerKey)) {
      return urlObj.searchParams.get('u');
    }
    if (['sovrn'].includes(partnerKey)) {
      return urlObj.searchParams.get('u') || urlObj.searchParams.get('out');
    }

    if (partnerKey === 'partnerize') {
      const [, matches] = new RegExp(/\/destination:(.+)/).exec(url) || [];
      if (matches) {
        return decodeURIComponent(matches);
      }
    }
  } catch (err) {
    console.log('error extracting deeplink', err);
    return null;
  }
};

export const renderLinkName = ({
  name,
  url,
  partnerKey
}: {
  name: string;
  url: string;
  partnerKey: string;
}) => {
  const nameOrUrl = name || url;

  if (name !== url) {
    return name || url;
  }

  try {
    const urlObj = new URL(url);
    if (urlObj.hostname === 'affiliate.insider.com') {
      const deeplink = urlObj.searchParams.get('u');
      if (PARTNER_KEYS_WTH_EXTRACTABLE_DEEPLINKS.includes(partnerKey)) {
        const deeperlink = extractDeepLink({
          name,
          url: deeplink || nameOrUrl,
          partnerKey
        });
        if (deeperlink) {
          return deeperlink;
        }
      }
      return deeplink;
    }
  } catch (err) {
    // continue
  }

  if (PARTNER_KEYS_WTH_EXTRACTABLE_DEEPLINKS.includes(partnerKey)) {
    return extractDeepLink({ name, url, partnerKey }) || nameOrUrl;
  }

  try {
    const urlObj = new URL(url);

    if (partnerKey === 'amazon' && urlObj.searchParams.has('tag')) {
      const [matches] = new RegExp(/\/(.+)\/dp\//).exec(urlObj.pathname) || [];
      if (matches) {
        return startCase(matches);
      }
    }
    return nameOrUrl;
  } catch (e) {
    return nameOrUrl;
  }
};
