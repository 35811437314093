import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { css, styled } from '../../emotion';
import { pluralize } from '../../services/pluralize';
import { SearchInput } from '../SearchInput';
import { SelectorChip } from '../SelectorChip';

export type MultiSelectorValueOption<Key> = {
  label: React.ReactNode;
  value: Key;
};

interface MultiSelectorGroupOption<T> {
  label: string;
  options: Array<MultiSelectorValueOption<T>>;
}

type MultiSelectorOption<T> =
  | MultiSelectorValueOption<T>
  | MultiSelectorGroupOption<T>;

function isValueOption<T>(
  o: MultiSelectorOption<T>
): o is MultiSelectorValueOption<T> {
  return 'value' in o;
}

export type MultiSelectorProps<Key> = {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;

  options: MultiSelectorOption<Key>[];
  legend?: React.ReactNode;
  minMenuWidth?: string | number;
  allOption?: React.ReactNode;
  allowFocusing?: boolean;
  allowSearch?: boolean;
  onSearch?: (x: { term: string; filteredOptions: Set<Key> }) => void;
  onOpen?: (isOpen: boolean) => void;
};

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FocusButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing()}px !important;
`;

const SearchWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(1)}px;
  width: calc(100% - ${(p) => p.theme.spacing(2)}px);

  & > div {
    width: 100%;
  }
`;

export const getAppliedLabel = (
  name: string,
  labels: string[],
  prefix = 'Filtered by'
) => {
  const ls =
    labels.length === 1 ? labels[0] : pluralize(name, labels.length, true);
  return `${prefix} ${ls}`;
};

export const MultiSelectorChip = SelectorChip;

const filterOptions = <Key extends string>(
  options: MultiSelectorOption<Key>[],
  term: string
) => {
  if (!term) return options;
  const matches = (o: MultiSelectorValueOption<Key>) =>
    o.value.toString().toLowerCase().includes(term.toLowerCase());
  return options.flatMap<MultiSelectorOption<Key>>((o) => {
    if (isValueOption(o)) return matches(o) ? [o] : [];
    const matchingOptions = o.options.filter(matches);
    if (matchingOptions.length === 0) return [];
    return [{ ...o, options: matchingOptions }];
  });
};

export const MultiSelectorPopoverBody = <Key extends string>({
  value,
  onChange,
  options = [],
  legend,
  minMenuWidth = 150,
  allOption,
  allowFocusing,
  allowSearch,
  onSearch
}: MultiSelectorProps<Key>) => {
  const [search, setSearch] = useState('');
  const toggle = (key: Key) => {
    const nextValue = new Set(value);
    if (nextValue.has(key)) {
      nextValue.delete(key);
    } else {
      nextValue.add(key);
    }
    onChange(nextValue);
  };
  const allSelected = value.size === options.length;

  const renderOption = (o: MultiSelectorValueOption<Key>) => (
    <FormControlLabel
      key={o.value}
      control={
        <Checkbox
          checked={value.has(o.value)}
          color="primary"
          value={o.value}
          onChange={() => toggle(o.value)}
        />
      }
      classes={{
        label: css(() => ({ width: '100%' })),
        root: css(() => ({
          marginLeft: 0,
          marginRight: 0
        }))
      }}
      label={
        <Label>
          <div>{o.label}</div>
          {allowFocusing && (
            <FocusButton
              size="small"
              variant="text"
              color="primary"
              onClick={() => onChange(new Set([o.value]))}
            >
              Only
            </FocusButton>
          )}
        </Label>
      }
    />
  );

  const filteredOptions = useMemo(() => filterOptions(options, search), [
    options,
    search
  ]);

  return (
    <FormControl
      component="fieldset"
      className={css((t) => ({
        '&&': {
          margin: t.spacing(2),
          minWidth: minMenuWidth
        }
      }))}
    >
      {legend && (
        <FormLabel
          classes={{
            root: css((t) => ({
              padding: t.spacing(1),
              fontWeight: 'bold'
            }))
          }}
          component="legend"
        >
          {legend}
        </FormLabel>
      )}
      {allowSearch && (
        <SearchWrapper>
          <SearchInput
            placeholder=""
            fullWidth
            value={search}
            onChange={(nextSearch) => {
              setSearch(nextSearch);
              onSearch?.({
                term: nextSearch,
                filteredOptions: new Set(
                  filterOptions(options, nextSearch).flatMap((c) =>
                    isValueOption(c) ? c.value : c.options.map((o) => o.value)
                  )
                )
              });
            }}
            autoFocus
          />
        </SearchWrapper>
      )}
      <FormGroup>
        {allOption && (
          <FormControlLabel
            classes={{
              root: css(() => ({
                marginLeft: 0,
                marginRight: 0
              }))
            }}
            control={
              <Checkbox
                checked={allSelected}
                color="primary"
                indeterminate={value.size > 0 && !allSelected}
                value={'____ALL____'}
                onChange={() =>
                  allSelected
                    ? onChange(new Set())
                    : onChange(
                        new Set(
                          options.filter(isValueOption).map((o) => o.value)
                        )
                      )
                }
              />
            }
            label={allOption}
          />
        )}
        {filteredOptions.map((o) => {
          if ('value' in o) {
            return renderOption(o);
          }
          return (
            <React.Fragment key={o.label}>
              <FormLabel
                classes={{
                  root: css((t) => ({
                    padding: t.spacing(1)
                  }))
                }}
                component="legend"
              >
                {o.label}
              </FormLabel>
              {o.options.map(renderOption)}
            </React.Fragment>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export const MultiSelector = <Key extends string>({
  children,
  onOpen,
  ...props
}: MultiSelectorProps<Key> & { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        size="small"
        onClick={() => {
          onOpen?.(!open);
          setOpen((x) => !x);
        }}
        ref={ref}
      >
        {children}
      </Button>
      <Popover
        open={open}
        onClose={() => {
          onOpen?.(false);
          setOpen(false);
        }}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MultiSelectorPopoverBody {...props} />
      </Popover>
    </>
  );
};
