import { MarketplaceAdvertiserProfilePublic } from '../../domainTypes/advertiserApp';
import { useFirebaseFunction } from '../firebaseFunctions';
import { ONE_DAY } from '../time';

export const useMarketplaceAdvertiserProfilesPublic = (spaceId: string) => {
  return useFirebaseFunction<MarketplaceAdvertiserProfilePublic[]>(
    'marketplaceAdvertisers-getMarketplaceAdvertiserProfilesFromPApp',
    { spaceId },
    [spaceId],
    ONE_DAY
  );
};
