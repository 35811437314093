import { MarketplaceAdvertiser } from '../../domainTypes/advertiserApp';
import { generateToDocFn } from '../../domainTypes/document';
import { store } from '../../services/db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../services/firecache/documentListener';

export const marketplaceAdvertisersCollection = () =>
  store().collection('marketplaceAdvertisersV1');

export const toMarketplaceAdvertiserDoc = generateToDocFn<
  MarketplaceAdvertiser
>((d) => {
  return d;
});

export const marketplaceAdvertiserStore = createDocumentListenerGetter(
  (id) => marketplaceAdvertisersCollection().doc(id),
  toMarketplaceAdvertiserDoc
);

export const useMarketplaceAdvertiser = (id: string) => {
  return useDocumentListener(marketplaceAdvertiserStore(id));
};
