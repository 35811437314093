import { AdvertiserAppSpaceAccessLists } from '../../../../domainTypes/advertiserApp';
import { generateToDocFn } from '../../../../domainTypes/document';
import { store } from '../../../../services/db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../../services/firecache/documentListener';

export const toAdvertiserAppSpaceAccessListDoc = generateToDocFn<
  AdvertiserAppSpaceAccessLists
>();

const getAdvertiserAppSpaceAccessListRef = (spaceId: string) => {
  return store().collection('advertiserAppSpaceAccessListsV1').doc(spaceId);
};

const accessListStore = createDocumentListenerGetter(
  getAdvertiserAppSpaceAccessListRef,
  toAdvertiserAppSpaceAccessListDoc,
  (id) => ({ id, catalogIds: [], segmentIds: [] })
);

export const useAdvertiserAppSpaceAccessList = (spaceId: string) => {
  return useDocumentListener(accessListStore(spaceId));
};
