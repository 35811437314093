import { uniq } from 'lodash';
import { useMemo } from 'react';
import { formatNumber } from '../../components/Number';
import {
  AnalyticsField,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison,
  AnalyticsSearch,
  SelectableFieldComplete
} from '../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../domainTypes/emptyConstants';
import { usePromise } from '../../hooks/usePromise';
import { toChecksum } from '../checksum';
import { LoadingValueExtended } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { isLocalhost } from '../localhost';
import { getCache, isExpired, useAnalyticsV2CacheRevision } from './cache';
import { createLogFn, getLogModeDefault } from './logs';

const CF_NAME = 'analytics_v2-query';

const logQuery = createLogFn(CF_NAME, 'Analytics V2 Query', 'AV2Q');

export type AnalyticsOpts = Partial<{
  noCache: boolean;
  cacheExpirationDuration: number; // in ms
  logMode: 'full' | 'compact' | 'off';
  logLabel: string;
}>;

const QUERY_COUNTER = {
  cached: 0,
  fresh: 0,
  total: 0
};

(window as any).queryCounter = QUERY_COUNTER;

export const queryAnalyticsV2 = <T = AnalyticsResponse>(
  spaceId: string,
  q: AnalyticsQuery,
  opts: AnalyticsOpts = {}
) => {
  const logMode = opts.logMode || (isLocalhost() ? 'compact' : 'off');
  const n = Date.now();
  const request = () =>
    callFirebaseFunction<AnalyticsResponse>(CF_NAME, {
      spaceId,
      q
    });

  const getRequestCached = () => {
    if (opts.noCache) {
      return {
        cached: false,
        promise: (request() as unknown) as Promise<T>
      };
    }
    const cache = getCache(spaceId, 'analytics');
    const checksum = toChecksum({ spaceId, q });
    let cached = false;
    if (!cache[checksum] || isExpired(n, cache[checksum].expiresAt)) {
      cache[checksum] = {
        expiresAt: opts.cacheExpirationDuration
          ? n + opts.cacheExpirationDuration
          : null,
        promise: request()
      };
      QUERY_COUNTER.fresh++;
    } else {
      QUERY_COUNTER.cached++;
      cached = true;
    }
    QUERY_COUNTER.total++;
    return {
      promise: (cache[checksum].promise as unknown) as Promise<T>,
      cached
    };
  };

  const req = getRequestCached();
  if (logMode !== 'off') {
    req.promise.then((res) => {
      logQuery(res as any, n, req.cached, logMode, opts.logLabel);
      return res;
    });
  }
  return req.promise;
};

export const useAnalyticsQueryV2 = (
  spaceId: string,
  q: AnalyticsQuery,
  opts?: AnalyticsOpts
): LoadingValueExtended<AnalyticsResponse> => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const cacheExpirationDuration = opts?.cacheExpirationDuration ?? 0;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: AnalyticsOpts = useMemo(
    () => ({
      noCache,
      cacheExpirationDuration,
      logMode,
      logLabel
    }),

    [noCache, cacheExpirationDuration, logMode, logLabel]
  );
  const revision = useAnalyticsV2CacheRevision(spaceId);
  return usePromise(() => queryAnalyticsV2(spaceId, q, optsWithDefaults), [
    spaceId,
    q,
    optsWithDefaults,
    revision
  ]);
};

export const useAnalyticsQueryV2WithAdditionalData = <T extends any>(
  spaceId: string,
  q: AnalyticsQuery,
  additionalDataRequest: (res: AnalyticsResponse) => Promise<T>,
  opts?: AnalyticsOpts
): LoadingValueExtended<T> => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const cacheExpirationDuration = opts?.cacheExpirationDuration ?? 0;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: AnalyticsOpts = useMemo(
    () => ({
      noCache,
      cacheExpirationDuration,
      logMode,
      logLabel
    }),

    [noCache, cacheExpirationDuration, logMode, logLabel]
  );
  const revision = useAnalyticsV2CacheRevision(spaceId);
  return usePromise(
    () =>
      queryAnalyticsV2(spaceId, q, optsWithDefaults).then(async (res) => {
        const start = Date.now();
        const finalData = await additionalDataRequest(res);
        const additionalTime = Date.now() - start;
        if (logMode !== 'off') {
          console.log(
            `Additional time: ${formatNumber({
              n: additionalTime / 1000,
              digits: 3
            })} - Total time ${formatNumber({
              n: (res.time + additionalTime) / 1000,
              digits: 3
            })}`
          );
        }
        return finalData;
      }),
    [spaceId, q, optsWithDefaults, revision, additionalDataRequest, logMode]
  );
};

export const mergeAnalyticsRowsData = (
  rows: AnalyticsResponseRowWithComparison['data'][]
) => {
  const next: AnalyticsResponseRowWithComparison['data'] = {};
  rows.forEach((row) => {
    Object.entries(row).forEach(([key, v]) => {
      if (!v) {
        return;
      }
      const k = key as SelectableFieldComplete;
      const n: any = next;
      if (k.startsWith('agg_')) {
        const arrContainer = (n[k] = n[k] || { curr: [] });
        arrContainer.curr.push(...(v as any).curr);
        if (v.prev !== undefined) {
          const prevContainer = (arrContainer.prev = arrContainer.prev || []);
          prevContainer.push(...(v as any).prev);
        }
      } else {
        const nContainer = (n[k] = n[k] || { curr: 0 });
        nContainer.curr += (v as any).curr;
        if (v.prev !== undefined) {
          nContainer.prev = (nContainer.prev || 0) + (v as any).prev;
        }
      }
    });
  });

  Object.entries(next).forEach(([k, v]) => {
    if (k.startsWith('agg_') && v) {
      v.curr = uniq((v as any).curr);
      if (v.prev !== undefined) {
        v.prev = uniq((v as any).prev);
      }
    }
  });

  return next;
};

export const toAnalyticsV2Search = (
  searchTerm: string,
  fields: AnalyticsField[]
): AnalyticsSearch[] => {
  if (!searchTerm) {
    return EMPTY_ARR;
  }
  return [{ pattern: `%${searchTerm}%`, in: fields }];
};

export const toStableAnalyticsV2Link = <
  X extends { group: { [groupKey: string]: string } }
>(
  d: X
) => {
  return Object.keys(d.group)
    .sort()
    .map((k) => `${k}-${d.group[k] || ''}`)
    .join('|');
};
