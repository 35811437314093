import { pick } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Query } from './domainTypes/routes/common';
import { useRouter } from './hooks/useRouter';

let collectedQueryParams: Query = {};

const withoutEmpty = (query: Query) =>
  Object.keys(query).reduce((m, k) => {
    const v = query[k];
    if (v && v.length) {
      m[k] = v;
    }
    return m;
  }, {} as Query);

const withQuery = (url: string, params: Query) => {
  const queryParams = withoutEmpty(params);
  return `${url}${
    Object.keys(queryParams).length
      ? `?${queryString.stringify(queryParams)}`
      : ''
  }`;
};

export const useRoutesBuilder = <X extends object>(opts: {
  createRoutesFn: (
    toUrlFn: (
      path: string,
      query?: Query,
      persistedQueryParams?: string[]
    ) => { path: string; query?: Query },
    stringify: (o: { [key: string]: any }) => string
  ) => X;
  persistedQueryParams?: string[];
}) => {
  const { history, match, location } = useRouter();

  const create = () => {
    const getQuery = () => queryString.parse(location.search);
    collectedQueryParams = { ...collectedQueryParams, ...getQuery() };

    const getPath = () => location.pathname;
    const getParams = <T = any>() => match.params as T;
    const goTo = (url: string, replace = false) =>
      replace ? history.replace(url) : history.push(url);

    const changeQuery = (queryParams: Query) => {
      // explicit changes need to update the collectedQueryParams, so that
      // params can be blanked. Otherwise they would stick around!
      collectedQueryParams = { ...collectedQueryParams, ...queryParams };
      return goTo(withQuery(getPath(), { ...getQuery(), ...queryParams }));
    };

    const toUrl = (
      path: string,
      query: Query = {},
      persistedQueryParams: string[] = []
    ) => ({
      path,
      query: {
        // If page A wants to pick param X, but moving to a page which
        // ignores param X, we want to set it again when we come back to
        // X. Hence we collect all query params over time, so that we
        // can always re-establish them for pages which are interested in them.
        ...pick(collectedQueryParams, [
          ...persistedQueryParams,
          ...(opts.persistedQueryParams || [])
        ]),
        ...query
      }
    });

    const ROUTES = opts.createRoutesFn(toUrl, queryString.stringify);
    return { getPath, getParams, getQuery, goTo, changeQuery, ROUTES };
  };

  const [v, setV] = useState(create());
  useEffect(() => {
    setV(create());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match, location]);
  return v;
};
