import { Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProfileLabel } from '../../../../../../components/ProfileLabel';
import { AdvertiserAppSpace } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { FlexContainerVertical } from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { _useMarketplaceAdvertisersForSpace } from '../../../../MarketplaceAdvertisers/service';

export const MarketplaceAdvertisers = ({
  space
}: {
  space: Doc<AdvertiserAppSpace>;
}) => {
  const [ds] = _useMarketplaceAdvertisersForSpace(space.id);

  return (
    <Section>
      <CanvasBar>
        <div>Marketplace Advertisers</div>
      </CanvasBar>

      <Card>
        <CardContent>
          <FlexContainerVertical fullWidth></FlexContainerVertical>
          {!ds && (
            <>
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
            </>
          )}
          {ds &&
            ds.length &&
            ds.map((d) => (
              <Link key={d.id} to={`/marketplaceAdvertisers/${d.id}`}>
                <ProfileLabel profile={d.data.profile} variant="strong" />
              </Link>
            ))}
          {ds && !ds.length && (
            <div>No associated marketplace advertisers found</div>
          )}
        </CardContent>
      </Card>
    </Section>
  );
};
