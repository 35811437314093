import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { DAY_FORMAT, isDayString } from './domainTypes/analytics';
import { EMPTY_ARR } from './domainTypes/emptyConstants';
import { createRoutes } from './domainTypes/routes';
import { Query } from './domainTypes/routes/common';
import {
  DEFAULT_SEPARATOR,
  ListSeparator
} from './domainTypes/routes/query-params';
import { useRoutesBuilder } from './routesBuilder';
import { localJsonStorage } from './services/localJsonStorage';
import { MomentRange } from './services/time';

export const useRoutes = () => {
  return useRoutesBuilder({
    createRoutesFn: createRoutes,
    persistedQueryParams: [
      'spaceId',
      'dataProvider',
      'experimental',
      'ignore_maintenance_mode'
    ]
  });
};

export const useQueryParam = <T>(
  param: string,
  fromParam: (p: string | null | undefined) => T,
  toParam: (v: T) => string | undefined, // undefined removes the param
  dependencies: any[] = EMPTY_ARR
): [T, (nextValue: T) => void, (nextValue: T) => Query] => {
  const { getQuery, changeQuery } = useRoutes();
  let p = getQuery()[param];

  if (Array.isArray(p)) {
    console.log(`Param ${param} is array, expected a single value`);
    p = p[0];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => fromParam(p as string | null | undefined), [
    p,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies
  ]);

  const setValue = useCallback(
    (v: T) =>
      changeQuery({
        [param]: toParam(v)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [p, changeQuery]
  );

  return [value, setValue, (v) => ({ [param]: toParam(v) })];
};

export const useBooleanQueryParam = (param: string, defaultValue: boolean) => {
  return useQueryParam<boolean>(
    param,
    (t) => (t ? t === 'true' : defaultValue),
    (t) => `${t}`
  );
};

export const useStringQueryParam = (param: string, defaultValue = '') =>
  useQueryParam<string>(
    param,
    (t) => t || defaultValue,
    (t) => t
  );

export const useStringQueryParamWithLocalStorage = <T extends string>(
  param: string,
  defaultValue: T,
  localStorageKey: string
) => {
  return useQueryParam<T>(
    param,
    (t) =>
      (t as T) || localJsonStorage.getItem(localStorageKey) || defaultValue,
    (t) => {
      localJsonStorage.setItem(localStorageKey, t);
      return t;
    }
  );
};

export const useTypedStringQueryParam = <T extends string>(
  param: string,
  defaultValue: T,
  blankDefault?: boolean
): [T, (nextValue: T) => void] => {
  const [value, setValue] = useQueryParam<T>(
    param,
    (t) => (t as T) || defaultValue,
    (t) => (blankDefault && t === defaultValue ? undefined : t)
  );

  return [value, setValue];
};

export const useNumberQueryParam = (param: string, defaultValue = 0) =>
  useQueryParam<number>(
    param,
    (t) => (t ? parseInt(t, 10) : defaultValue),
    (t) => `${t}`
  );

export const useComboQueryParam = <T>(
  param: string,
  fromParam: (p: [string | undefined, string | undefined]) => T,
  toParam: (v: T) => [string | undefined, string | undefined],
  delimiter = '---',
  dependencies: any[] = EMPTY_ARR
) =>
  useQueryParam(
    param,
    (p) => {
      const [a, b] = (p || '').split(delimiter);
      return fromParam([a, b]);
    },
    (v) => toParam(v).join(delimiter),
    dependencies
  );

export const setToQueryParam = (
  xs: Set<string>,
  separator: ListSeparator = DEFAULT_SEPARATOR
): string => [...xs].sort().join(separator);

export const queryParamToList = <T extends string>(
  x: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
): T[] => x.split(separator) as T[];

export const queryParamToSet = <T extends string>(
  x: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
): Set<T> => new Set(queryParamToList(x, separator));

export const useNullableStringSetQueryParam = (
  param: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
) => {
  return useQueryParam(
    param,
    (p) => (p ? queryParamToSet(p, separator) : null),
    (v) => (v ? setToQueryParam(v, separator) : undefined)
  );
};

export const useDayRangeQueryParam = (
  param: string

  // fromParam: (p: [string | undefined, string | undefined]) => MomentRange,
  // toParam: (v: MomentRange | null) => [string | undefined, string | undefined],
) => {
  const delimiter = '---';
  const f = DAY_FORMAT;

  return useQueryParam<MomentRange | null>(
    param,
    (p) => {
      if (!p) {
        return null;
      }
      const [start = '', end = ''] = p.split(delimiter);
      if (isDayString(start) && isDayString(end)) {
        return {
          start: moment(start, f).startOf('d'),
          end: moment(end, f).startOf('d')
        };
      }
      return null;
    },
    (r) => {
      if (!r) {
        return undefined;
      }
      return `${r.start.format(DAY_FORMAT)}${delimiter}${r.end.format(
        DAY_FORMAT
      )}`;
    }
  );
};
