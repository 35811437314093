import React from 'react';
import { PlatformWithColor } from '../../../../../../components/PlatformWithColor';
import { ProductCatalogConnectionType } from '../../../../../../domainTypes/advertiserApp';
import { getKnownPartnerForKeyUnsafe } from '../../../../../../services/partner';

export const ProductCatalogConnectionTypeBadge = ({
  type
}: {
  type: ProductCatalogConnectionType;
}) => {
  switch (type) {
    case ProductCatalogConnectionType.CUSTOM:
      return (
        <PlatformWithColor
          partner={{
            name: 'Custom',
            color: '#ddd'
          }}
        />
      );
    case ProductCatalogConnectionType.AWIN:
      return (
        <PlatformWithColor partner={getKnownPartnerForKeyUnsafe('awin')} />
      );
    case ProductCatalogConnectionType.CJ:
      return <PlatformWithColor partner={getKnownPartnerForKeyUnsafe('cj')} />;
    default:
      console.log('Unknown type', type);
      return null;
  }
};
