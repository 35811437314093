import { HANDLER_CONFIGS } from '../../../../../domainTypes/reporting';
import { IApiReportHandler } from '../types';

export const WEBGAINS: IApiReportHandler = {
  type: 'API',
  partnerKey: 'webgains',
  configName: 'webgainsV1',
  configFields: HANDLER_CONFIGS.webgainsV1,
  status: 'deprecated'
};
