import Stripe from 'stripe';
import { ISpace } from './space';

export type Plan =
  | 'LITE_1M_USD'
  | 'LITE_1Y_USD'
  | 'SOLO_CREATOR_1M_USD'
  | 'SOLO_CREATOR_1Y_USD'
  | 'PORTFOLIO_1M_USD'
  | 'PORTFOLIO_1Y_USD'
  | 'PORTFOLIO_1Y_USD2'
  | 'MEDIA_COMPANY_1M_USD'
  | 'MEDIA_COMPANY_1Y_USD'
  | 'CUSTOM_1M_USD' // Used for enterprise pricing
  | 'CUSTOM_1Y_USD' // Used for enterprise pricing
  // Old plan names
  | 'STANDARD_1M_USD'
  | 'STANDARD_1Y_USD'
  | 'PRO_1M_USD'
  | 'PRO_1Y_USD'
  | 'STANDARD_FOREVER_FREE'
  | 'INDIVIDUAL_1M_USD'
  | 'INDIVIDUAL_1Y_USD'
  | 'BUSINESS_250K_1M_USD'
  | 'BUSINESS_250K_1Y_USD'
  | 'BUSINESS_500K_1M_USD'
  | 'BUSINESS_500K_1Y_USD'
  | 'BUSINESS_750K_1M_USD'
  | 'BUSINESS_750K_1Y_USD'
  | 'BUSINESS_1MIL_1M_USD'
  | 'BUSINESS_1MIL_1Y_USD'
  | 'BUSINESS_2MIL_1M_USD'
  | 'BUSINESS_2MIL_1Y_USD'
  | 'ENTERPRISE_3MIL_1M_USD'
  | 'ENTERPRISE_3MIL_1Y_USD';

// MICRO, STANDARD, PRO, and CUSTOM are legacy plan types
// INDIVIDUAL, BUSINESS, BUSINESS_500K, BUSINESS_750K, BUSINESS_1M, BUSINESS_2M, ENTERPRISE_3M are current types

export type PlanType =
  | 'MICRO'
  | 'STANDARD'
  | 'PRO'
  | 'CUSTOM'
  | 'INDIVIDUAL'
  | 'BUSINESS'
  | 'ENTERPRISE';

export type PlanFrequency = 'm' | 'y' | '∞';

export interface IPlanDetails {
  type: PlanType;
  monthly: Stripe.Plan;
  yearly: Stripe.Plan;
}

export type Features =
  | 'team'
  | 'priority_support'
  | 'account_manager'
  | 'early_access'
  | 'zoom_support';

export type IPlanType = {
  type: PlanType;
  frequency: PlanFrequency;
  status: 'active' | 'legacy';
  pageviews: number;
  marker: number;
  sites: number;
  integrations: number;
  features: Features[];
  label: string;
  free?: boolean;
  price: number;
  revenue: {
    start: number;
    end: number;
  };
};

export type IPlanTypeNew = {
  status: 'active';
  revenue: {
    start: number;
    end: number;
  };
  historicalData: number; // in months
} & IPlanType;

// PLAN IDS NEED TO BE FEED IN FROM AN ENV FILE!
export const PLAN_TYPES: { [K in Plan]: IPlanType | IPlanTypeNew } = {
  // New plans
  LITE_1M_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Lite',
    frequency: 'm',
    pageviews: 100000,
    marker: 80000,
    integrations: 20,
    sites: 1,
    features: [],
    price: 29,
    revenue: {
      start: 0,
      end: 10000
    },
    historicalData: 12
  },
  LITE_1Y_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Lite',
    frequency: 'y',
    pageviews: 100000,
    marker: 5000,
    integrations: 20,
    sites: 1,
    features: [],
    price: 290,
    revenue: {
      start: 0,
      end: 10000
    },
    historicalData: 12
  },
  SOLO_CREATOR_1M_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Solo Creator',
    frequency: 'm',
    pageviews: 1000000,
    marker: 10000,
    integrations: 50,
    sites: 5,
    features: [],
    price: 99,
    revenue: {
      start: 0,
      end: 25000
    },
    historicalData: 24
  },
  SOLO_CREATOR_1Y_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Solo Creator',
    frequency: 'y',
    pageviews: 1000000,
    marker: 10000,
    integrations: 50,
    sites: 5,
    features: [],
    price: 990,
    revenue: {
      start: 0,
      end: 25000
    },
    historicalData: 24
  },
  PORTFOLIO_1M_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Portfolio',
    frequency: 'm',
    pageviews: 3000000,
    marker: 50000,
    integrations: Infinity,
    sites: 10,
    features: [],
    price: 374,
    revenue: {
      start: 25001,
      end: 75000
    },
    historicalData: 48
  },
  PORTFOLIO_1Y_USD: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Portfolio',
    frequency: 'y',
    pageviews: 3000000,
    marker: 50000,
    integrations: Infinity,
    sites: 10,
    features: [],
    price: 3740,
    revenue: {
      start: 25001,
      end: 75000
    },
    historicalData: 48
  },
  PORTFOLIO_1Y_USD2: {
    type: 'INDIVIDUAL',
    status: 'active',
    label: 'Portfolio',
    frequency: 'y',
    pageviews: 3000000,
    marker: 50000,
    integrations: Infinity,
    sites: 10,
    features: [],
    price: 4488,
    revenue: {
      start: 25001,
      end: 75000
    },
    historicalData: 48
  },
  MEDIA_COMPANY_1M_USD: {
    type: 'INDIVIDUAL',
    status: 'legacy',
    label: 'Media Company',
    frequency: 'm',
    pageviews: 7000000,
    marker: 100000,
    integrations: Infinity,
    sites: 20,
    features: [],
    price: 749,
    revenue: {
      start: 75001,
      end: 155000
    }
  },
  MEDIA_COMPANY_1Y_USD: {
    type: 'INDIVIDUAL',
    status: 'legacy',
    label: 'Media Company',
    frequency: 'y',
    pageviews: 7000000,
    marker: 100000,
    integrations: Infinity,
    sites: 20,
    features: [],
    price: 7490,
    revenue: {
      start: 75001,
      end: 155000
    }
  },

  // Old plans
  INDIVIDUAL_1M_USD: {
    type: 'INDIVIDUAL',
    frequency: 'm',
    status: 'legacy',
    pageviews: 100000,
    marker: 80000,
    integrations: 20,
    sites: 3,
    features: [],
    label: '100K',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 29
  },
  INDIVIDUAL_1Y_USD: {
    type: 'INDIVIDUAL',
    status: 'legacy',
    pageviews: 100000,
    marker: 80000,
    label: '100K',
    sites: 3,
    integrations: 20,
    features: [],
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 290
  },
  BUSINESS_250K_1M_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 250000,
    marker: 250000,
    sites: 10,
    features: ['team'],
    integrations: 50,
    label: '250K',
    frequency: 'm',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 59
  },
  BUSINESS_250K_1Y_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 250000,
    marker: 250000,
    integrations: 50,
    sites: 10,
    features: ['team'],
    label: '250K',
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 590
  },
  BUSINESS_500K_1M_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 500000,
    marker: 500000,
    integrations: 50,
    sites: 25,
    features: ['team', 'priority_support'],
    label: '500K',
    frequency: 'm',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 99
  },
  BUSINESS_500K_1Y_USD: {
    type: 'BUSINESS',
    pageviews: 500000,
    marker: 500000,
    label: '500K',
    status: 'legacy',
    integrations: 50,
    sites: 25,
    features: ['team', 'priority_support'],
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 990
  },
  BUSINESS_750K_1M_USD: {
    type: 'BUSINESS',
    pageviews: 750000,
    marker: 750000,
    label: '750K',
    status: 'legacy',
    integrations: 50,
    sites: 50,
    features: ['team', 'priority_support'],
    frequency: 'm',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 149
  },
  BUSINESS_750K_1Y_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 750000,
    marker: 750000,
    label: '750K',
    sites: 50,
    integrations: 50,
    features: ['team', 'priority_support'],
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 1490
  },
  BUSINESS_1MIL_1M_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 1000000,
    marker: 1000000,
    sites: 50,
    integrations: 100,
    features: ['team', 'priority_support', 'early_access'],
    label: '1M',
    frequency: 'm',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 199
  },
  BUSINESS_1MIL_1Y_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 1000000,
    marker: 1000000,
    sites: 50,
    integrations: 100,
    features: ['team', 'priority_support', 'early_access'],
    label: '1M',
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 1990
  },
  BUSINESS_2MIL_1M_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 2000000,
    marker: 1250000,
    sites: 50,
    integrations: 100,
    features: ['team', 'priority_support', 'early_access'],
    label: '2M',
    frequency: 'm',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 399
  },
  BUSINESS_2MIL_1Y_USD: {
    type: 'BUSINESS',
    status: 'legacy',
    pageviews: 2000000,
    marker: 1250000,
    sites: 50,
    integrations: 100,
    features: ['team', 'priority_support', 'early_access'],
    label: '2M',
    revenue: {
      start: 0,
      end: 25000
    },
    frequency: 'y',
    price: 3990
  },
  ENTERPRISE_3MIL_1M_USD: {
    type: 'ENTERPRISE',
    status: 'legacy',
    pageviews: 3000000,
    marker: 1500000,
    sites: 50,
    integrations: 100,
    revenue: {
      start: 0,
      end: 25000
    },
    features: [
      'team',
      'priority_support',
      'account_manager',
      'early_access',
      'zoom_support'
    ],
    label: '3M',
    frequency: 'm',
    price: 599
  },
  ENTERPRISE_3MIL_1Y_USD: {
    type: 'ENTERPRISE',
    status: 'legacy',
    pageviews: 3000000,
    marker: 1500000,
    sites: 50,
    integrations: 100,
    features: [
      'team',
      'priority_support',
      'account_manager',
      'early_access',
      'zoom_support'
    ],
    label: '3M',
    frequency: 'y',
    revenue: {
      start: 0,
      end: 25000
    },
    price: 5990
  },
  // Following plans are legacy
  STANDARD_FOREVER_FREE: {
    type: 'STANDARD',
    frequency: '∞',
    free: true,
    status: 'legacy',
    pageviews: 0,
    marker: 0,
    sites: 1,
    integrations: 10,
    features: [],
    label: 'Free',
    revenue: {
      start: 0,
      end: 250000
    },
    price: 0
  },
  STANDARD_1M_USD: {
    type: 'STANDARD',
    status: 'legacy',
    marker: 0,
    pageviews: 100000,
    integrations: 10,
    sites: 3,
    features: [],
    label: '100K',
    frequency: 'm',
    revenue: {
      start: 0,
      end: 250000
    },
    price: 29
  },
  STANDARD_1Y_USD: {
    type: 'STANDARD',
    status: 'legacy',
    marker: 0,
    integrations: 10,
    pageviews: 100000,
    sites: 3,
    features: [],
    label: '100K',
    frequency: 'y',
    revenue: {
      start: 0,
      end: 250000
    },
    price: 300
  },
  PRO_1M_USD: {
    type: 'PRO',
    status: 'legacy',
    marker: 0,
    pageviews: 250000,
    integrations: 20,
    label: '250K',
    sites: 10,
    features: ['team'],
    frequency: 'm',
    revenue: {
      start: 0,
      end: 250000
    },
    price: 59
  },
  PRO_1Y_USD: {
    type: 'PRO',
    status: 'legacy',
    marker: 0,
    pageviews: 250000,
    integrations: 20,
    sites: 10,
    features: ['team'],
    label: '250K',
    frequency: 'y',
    revenue: {
      start: 0,
      end: 250000
    },
    price: 624
  },
  CUSTOM_1M_USD: {
    type: 'CUSTOM',
    status: 'active',
    marker: 0,
    frequency: 'm',
    sites: 10,
    integrations: Infinity,
    features: ['team', 'priority_support', 'account_manager'],
    pageviews: 0,
    revenue: {
      start: 0,
      end: 10000000000 // Effectively unlimited
    },
    label: 'Enterprise',
    price: 0
  },
  CUSTOM_1Y_USD: {
    type: 'CUSTOM',
    status: 'active',
    marker: 0,
    frequency: 'y',
    integrations: Infinity,
    sites: 10,
    features: ['team', 'priority_support', 'account_manager'],
    pageviews: 0,
    revenue: {
      start: 0,
      end: 10000000000 // Effectively unlimited
    },
    label: 'Enterprise',
    price: 0
  }
};

export const getPlanConfig = (planKey: Plan | null): IPlanType | null => {
  if (!planKey) {
    return null;
  }
  return PLAN_TYPES[planKey] || null;
};

export const getPlanType = (planKey: Plan | null): PlanType | '' => {
  const planConfig = getPlanConfig(planKey);
  return planConfig ? planConfig.type : '';
};

export const getPlanFrequency = (planKey: Plan | null): PlanFrequency | '' => {
  const planConfig = getPlanConfig(planKey);
  return planConfig ? planConfig.frequency : '';
};

export interface ICard {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

export interface ISubscriptionDetails {
  id: string;
  subscription: Stripe.Subscription;
  plan: Plan;
  cards: ICard[];
}

export interface IInvoice extends Stripe.Invoice {
  effective_at: number;
}

export interface ICoupon extends Stripe.Coupon {}

export interface ISubscriptionCreateParams {
  spaceId: string;
  email: string;
  plan: string;
  token: string;
  coupon: string;
}

const ONE_DAY = 24 * 60 * 60 * 1000;
export const DEFAULT_GRACE_PERIOD = 4 * ONE_DAY;
export const DEFAULT_TRIAL_PERIOD = 15 * ONE_DAY;
export const ENTERPRISE_GRACE_PERIOD = 30 * ONE_DAY;

export interface IBillingData {
  customerId: string;
  subscriptionId: string;
  activePlan: Plan | null;
  subscribedUntil: number | null;
  trialUntil: number | null;
  gracePeriod: number; // ms duration
}

export const isInTrial = (billing: IBillingData, now: number) => {
  return billing.trialUntil && billing.trialUntil > now;
};

export const isInTrialIncludingGracePeriod = (
  billing: IBillingData,
  now: number
) => {
  return isInTrial(billing, now - billing.gracePeriod);
};

export const isUsingForFree = (billing: IBillingData) => {
  const plan = getPlanConfig(billing.activePlan);
  return plan && !!plan.free;
};

export const hasTrialExpired = (billing: IBillingData, now: number) => {
  return !!(
    billing.trialUntil &&
    billing.trialUntil < now &&
    !billing.activePlan
  );
};

export const isInTrialGracePeriod = (billing: IBillingData, now: number) => {
  return (
    billing.trialUntil &&
    hasTrialExpired(billing, now) &&
    billing.trialUntil + billing.gracePeriod > now
  );
};

export const hasSubscriptionExpired = (billing: IBillingData, now: number) => {
  return !!(
    !isInTrial(billing, now) &&
    !isUsingForFree(billing) &&
    billing.subscribedUntil &&
    billing.subscribedUntil < now
  );
};

export const isInSubscriptionGracePeriod = (
  billing: IBillingData,
  now: number
) => {
  return (
    hasSubscriptionExpired(billing, now) &&
    billing.subscribedUntil &&
    billing.subscribedUntil + billing.gracePeriod > now
  );
};

export const isPayingCustomer = (billing: IBillingData, now: number) => {
  return (
    !isInTrial(billing, now) &&
    !isUsingForFree(billing) &&
    billing.subscribedUntil &&
    billing.subscribedUntil > now
  );
};

export type BillingStatus =
  | 'PAYING'
  | 'TRIAL'
  | 'TRIAL_GRACE'
  | 'TRIAL_EXPIRED'
  | 'SUBSCRIPTION_GRACE'
  | 'SUBSCRIPTION_EXPIRED'
  | 'FREE';

export const getBillingStatus = (space: ISpace, now: number): BillingStatus => {
  const { billing } = space;
  if (hasTrialExpired(billing, now)) {
    return isInTrialGracePeriod(billing, now) ? 'TRIAL_GRACE' : 'TRIAL_EXPIRED';
  }
  if (isInTrial(billing, now)) {
    return 'TRIAL';
  }
  if (isUsingForFree(billing)) {
    return 'FREE';
  }
  if (hasSubscriptionExpired(billing, now)) {
    return isInSubscriptionGracePeriod(billing, now)
      ? 'SUBSCRIPTION_GRACE'
      : 'SUBSCRIPTION_EXPIRED';
  }

  return 'PAYING';
};

export const isLockedOut = (space: ISpace, now: number) => {
  const status = getBillingStatus(space, now);
  return status === 'SUBSCRIPTION_EXPIRED' || status === 'TRIAL_EXPIRED';
};

export type IBenefitList = {
  [K in PlanType]: IBenefit[];
};

export interface IBenefit {
  content: string;
  description?: string;
}

export const toPlanName = (plan: Plan) => {
  if (plan.includes('PORTFOLIO')) {
    return 'Portfolio';
  }
  if (plan.includes('MEDIA_COMPANY')) {
    return 'Media Company';
  }
  if (plan.includes('SOLO_CREATOR')) {
    return 'Solo Creator';
  }
  if (plan.includes('CUSTOM')) {
    return 'Enterprise';
  }
  if (plan.includes('100K')) {
    return '100K Legacy';
  }
  if (plan.includes('250K')) {
    return '250K Legacy';
  }
  if (plan.includes('500K')) {
    return '500K Legacy';
  }
  if (plan.includes('750K')) {
    return '750K Legacy';
  }
  if (plan.includes('1M')) {
    return '1M Legacy';
  }
  if (plan.includes('2M')) {
    return '2M Legacy';
  }
  return 'Legacy';
};

export enum PlanType2024 {
  SOLO_CREATOR = 'SOLO_CREATOR',
  MEDIA_COMPANY = 'MEDIA_COMPANY',
  PORTFOLIO = 'PORTFOLIO',
  ENTERPRISE = 'ENTERPRISE',
  LEGACY = 'LEGACY',
  UNKNOWN = 'UNKNOWN'
}

export const getPlanType2024 = (plan: Plan) => {
  if (plan.includes('SOLO_CREATOR')) {
    return PlanType2024.SOLO_CREATOR;
  }
  if (plan.includes('MEDIA_COMPANY')) {
    return PlanType2024.MEDIA_COMPANY;
  }
  if (plan.includes('PORTFOLIO')) {
    return PlanType2024.PORTFOLIO;
  }
  if (plan.includes('CUSTOM')) {
    return PlanType2024.ENTERPRISE;
  }
  return PlanType2024.LEGACY;
};

// Handle with a bit of care - pass mainly known values to e.g.
// get a nice Enterprise string, but avoid showing "Legacy"
export const planType2024ToString = (type: PlanType2024) => {
  switch (type) {
    case PlanType2024.SOLO_CREATOR:
      return 'Solo Creator';
    case PlanType2024.MEDIA_COMPANY:
      return 'Media Company';
    case PlanType2024.PORTFOLIO:
      return 'Portfolio';
    case PlanType2024.ENTERPRISE:
      return 'Enterprise';
    case PlanType2024.LEGACY:
      return 'Legacy';
    default:
      console.log(`UNKNOWN PLAN TYPE - ${type} - cannot render string`);
      return 'Unknown';
  }
};

export const getPlanType2024ForSpace = (space: ISpace): PlanType2024 => {
  const { activePlan } = space.billing;
  if (!activePlan) {
    return PlanType2024.UNKNOWN;
  }
  return getPlanType2024(activePlan);
};
