import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { BooleanComponent } from '../../../../../components/Boolean';
import { CheckboxSimple } from '../../../../../components/CheckboxSimple';
import { CopyId } from '../../../../../components/CopyButton';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  SearchInput,
  toSearchRegexp
} from '../../../../../components/SearchInput';
import { IColumn } from '../../../../../components/Table/Column';
import { Truncated } from '../../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  ISegmentCampaign,
  segmentCampaignStatuses
} from '../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../domainTypes/document';
import {
  CampaignStatusIcon,
  campaignStatusTitle
} from '../../../../../features/Campaigns/service/segment-campaign';
import { CanvasBar } from '../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import {
  useBooleanQueryParam,
  useStringQueryParam
} from '../../../../../routes';
import { SpaceProfileLabel } from '../../../../components/SpaceProfileLabel';
import { CampaignPartnerProfileLabel } from '../../components/CampaignPartnerProfileLabel';
import { useSegmentCampaigns } from '../../service';

type D = Doc<ISegmentCampaign>;

const SORTERS: ItemSorters<D> = {
  id: {
    key: 'id',
    items: {
      sort: (d) => d.id,
      dir: 'asc'
    }
  },
  name: {
    key: 'name',
    items: {
      sort: (d) => d.data.name,
      dir: 'asc'
    }
  },
  status: {
    key: 'status',
    items: {
      sort: (d) => segmentCampaignStatuses.indexOf(d.data.status),
      dir: 'asc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.name;

const rowToKey = (d: D) => d.id;

const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <CopyId id={d.id} />,
    align: 'left',
    width: 100,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'space',
    head: () => 'Space',
    cell: (d) => <SpaceProfileLabel spaceId={d.data.spaceId} />,
    align: 'left',
    width: 180,
    flexGrow: 0
  },
  {
    key: 'partner',
    head: () => 'Partner',
    align: 'left',
    cell: (d) => <CampaignPartnerProfileLabel campaign={d} />,
    width: 250,
    flexGrow: 0
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => (
      <FlexContainerVertical spacing={1} fullWidth>
        <FlexContainer alignItems="center">
          <CampaignStatusIcon status={d.data.status} />
          <Typography variant="body2">
            {campaignStatusTitle(d.data.status)}
          </Typography>
        </FlexContainer>
      </FlexContainerVertical>
    ),
    align: 'left',
    width: 100,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'paused',
    head: () => 'Paused',
    cell: (d) => <BooleanComponent value={d.data.paused} trueIsGood={false} />,
    align: 'center',
    width: 50,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (c) => (
      <WithHoverIndicator>
        <Truncated title={c.data.name} />
      </WithHoverIndicator>
    ),
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  }
];

const SegmentCampaignsTable = ({
  rows,
  sorter,
  sortDirection,
  setSort
}: {
  rows: Doc<ISegmentCampaign>[];
  sorter: ItemSorter<D>;
  sortDirection: 'asc' | 'desc';
  setSort: (args: [ItemSorter<D>, 'asc' | 'desc' | undefined]) => void;
}) => {
  return (
    <RowsRenderer
      variant="contained"
      rows={rows}
      columns={COLUMNS}
      rowToKey={rowToKey}
      rowToHref={(d) => `/campaigns/${d.id}`}
      sorter={sorter}
      sortDirection={sortDirection}
      onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
      chunkSize={100}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.dense}
      renderHead
      otherProps={undefined}
    />
  );
};

export const PageCampaignsList = () => {
  const [campaigns] = useSegmentCampaigns();
  const [showCompleted, setShowCompleted] = useBooleanQueryParam(
    'showCompleted',
    false
  );
  const [q, setQ] = useStringQueryParam('q');
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const rows = useMemo(() => {
    if (!campaigns) {
      return null;
    }

    const re = toSearchRegexp(q);
    let res = campaigns;
    res = showCompleted
      ? res
      : res.filter((campaign) => campaign.data.status !== 'completed');
    res = re
      ? res.filter((campaign) =>
          [campaign.data.name, campaign.id].some((s) => s.match(re))
        )
      : campaigns;
    return res;
  }, [campaigns, q, showCompleted]);
  return (
    <Page width="FULL">
      <Section>
        <CanvasBar>
          <SearchInput
            value={q}
            onChange={setQ}
            placeholder="Search by campaign name"
            autoFocus={true}
            width={300}
          />
          <CheckboxSimple
            label="Show completed"
            checked={showCompleted}
            onChange={setShowCompleted}
            color="primary"
          />
        </CanvasBar>
        {!rows && <Loader height={500} />}
        {rows && (
          <SegmentCampaignsTable
            rows={rows}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={dir || DEFAULT_SORTER.items.dir}
            setSort={setSort}
          />
        )}
      </Section>
    </Page>
  );
};
