import { compact, padStart } from 'lodash';
import { formatNumber } from '../../components/Number';
import { isLocalhost } from '../localhost';
import { toCloudFunctionGen1LogLink } from '../logging';

export const getLogModeDefault = (): 'full' | 'compact' | 'off' => {
  try {
    const url = new URL(window.location.href);
    const urlLogMode = url.searchParams.get('logMode') || '';
    if (['full', 'compact', 'off'].includes(urlLogMode)) {
      return urlLogMode as 'full' | 'compact' | 'off';
    }
  } finally {
    return 'off' as const;
  }
};

export const createLogFn = <
  X extends {
    q: {
      logId?: string;
    };
    time: number;
    rows: any[];
  }
>(
  cfName: string,
  name: string,
  shortName: string
) => (
  r: X,
  start: number,
  cached: boolean,
  logMode: 'full' | 'compact',
  label?: string
) => {
  const totalTime = Date.now() - start;
  const dbTime = r.time;
  const networkOverhead = totalTime - dbTime;

  const logId = r.q.logId || '';
  const logLink =
    logId && isLocalhost()
      ? `${toCloudFunctionGen1LogLink(cfName, {
          logType: 'CLICKHOUSE_QUERY',
          logId
        })}`
      : '';

  if (logMode === 'full') {
    const rs = compact([
      [
        'Total time',
        `${formatNumber({
          n: totalTime / 1000,
          digits: 3
        })}s`
      ],
      !cached && [
        'DB time',
        `${formatNumber({
          n: dbTime / 1000,
          digits: 3
        })}s`
      ],
      !cached && [
        'Network Overhead',
        `${formatNumber({
          n: networkOverhead / 1000,
          digits: 3
        })}s`
      ],
      ['Rows', `${r.rows.length}`]
    ]);
    const maxLen =
      Math.max(...rs.map((r) => r[0].length + r[1].length + 2)) || 0;
    const rows = rs.map((r) => {
      return `${r[0]}: ${padStart(r[1], maxLen - r[0].length, ' ')}`;
    });
    console.log(
      `${compact([name, label, logId, cached && 'CACHED']).join(
        ' - '
      )}\n\n${rows.map((r) => `  ${r}`).join('\n')}\n\nLogs: ${
        logLink ? `${logLink}\n\n` : ''
      }`,
      r
    );
  }
  if (logMode === 'compact') {
    if (!cached) {
      console.log(
        compact([
          shortName,
          label,
          logId,
          r.q.logId || '',
          `${formatNumber({
            n: totalTime / 1000,
            digits: 3
          })}s (${formatNumber({ n: dbTime / 1000, digits: 3 })}s)`
        ]).join(' - '),
        logLink ? `\n${logLink}` : ''
      );
    }
  }
};
