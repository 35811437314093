import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { UserAvatarWithNameNextToIt } from '../../../components/UserAvatars';
import { usePromise } from '../../../hooks/usePromise';
import { FlexContainer } from '../../../layout/Flex';
import { getUser } from '../../../services/user';
import * as ENV from '../../env.json';

type Props = {
  userId: string;
};

export const AdminUserAvatar: React.FC<Props> = ({ userId }) => {
  const u: { initials: string; color: string } = (ENV.users as any)[userId] || {
    initials: '?',
    color: ''
  };
  return <Avatar style={{ fontSize: 14 }}>{u.initials}</Avatar>;
};

export const UserAvatarWithNameNextToItLazy = ({
  userId,
  size = 16,
  className
}: {
  userId: string;
  size?: number;
  className?: string;
}) => {
  const [user] = usePromise(() => {
    return getUser(userId);
  }, [userId]);
  if (!user || !user.data) {
    return (
      <FlexContainer>
        <Skeleton variant="circle" width={size} height={size} />
        <Skeleton variant="text" width={50} />
      </FlexContainer>
    );
  }
  return (
    <UserAvatarWithNameNextToIt user={user} size={size} className={className} />
  );
};
