import firebase from 'firebase/app';

export type DocReference = {
  id: string;
  collection: string;
};

export type Doc<T> = {
  id: string;
  collection: string;
  data: T;
};

export const toTimestamp = (
  t: { seconds: number; nanoseconds: number } | undefined
) => {
  return t ? new firebase.firestore.Timestamp(t.seconds, t.nanoseconds) : t;
};

export const collectionFromSnapshot = (
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => {
  return d.ref.path.substring(0, d.ref.path.length - d.id.length - 1);
};

export const toDoc = <T>(
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot,
  normalize?: (data: T, id: string) => T
): Doc<T> => ({
  id: d.id,
  collection: collectionFromSnapshot(d),
  data: normalize ? normalize(d.data() as any, d.id) : (d.data() as T)
});

export const generateToDocFn = <T>(normalize?: (data: T, id: string) => T) => (
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => toDoc<T>(d, normalize);

export const toDocD = <T>(
  d: firebase.database.DataSnapshot,
  normalize?: (data: T) => T
): Doc<T> => ({
  id: d.key!,
  collection: d.ref
    .toString()
    .substring(d.ref.root.toString().length, d.key!.length - 1),
  data: normalize ? normalize(d.val()) : (d.val() as T)
});

export const generateToDocDFn = <T>(normalize?: (data: T) => T) => (
  d: firebase.database.DataSnapshot
) => toDocD<T>(d, normalize);

export const toDocsFromRdbObject = <T>(
  value: { [key: string]: T },
  normalize?: (data: T) => T
): Doc<T>[] => {
  const results: Doc<T>[] = [];
  for (const key in value) {
    const v = value[key];
    // TODO - need to refactor the consumers to pass more data into this fn to determine the collection path
    results.push({
      id: key,
      collection: 'NOT_IMPLEMENTED',
      data: normalize ? normalize(v) : v
    });
  }
  return results;
};
