import { AdvertiserAppSpace } from '../../domainTypes/advertiserApp';
import { generateToDocFn } from '../../domainTypes/document';
import { store } from '../../services/db';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from '../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../services/firecache/documentListener';
import { FS } from '../../versions';

const collection = () => store().collection(FS.advertiserSpaces);

export const toAdvertiserAppSpaceDoc = generateToDocFn<AdvertiserAppSpace>(
  (d) => {
    d.marketplaceAdvertisers = d.marketplaceAdvertisers || [];
    return d;
  }
);

export const advertiserAppSpaceStore = createDocumentListenerGetter(
  (spaceId) => collection().doc(spaceId),
  toAdvertiserAppSpaceDoc
);

const getSpaceListener = createSingleCollectionListenerStore(
  () => new CollectionListener(collection(), toAdvertiserAppSpaceDoc)
);

export const useAdvertiserAppSpaces = () => {
  return useCollectionListener(getSpaceListener());
};

export const useAdvertiserSpace = (spaceId: string) => {
  return useDocumentListener(advertiserAppSpaceStore(spaceId));
};

export const matchAdvertiserAppSpace = (
  space: AdvertiserAppSpace,
  term: string
) =>
  space.id.includes(term) ||
  !!space.config.name.toLowerCase().includes(term.toLowerCase());
