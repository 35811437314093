import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { compact, groupBy, keyBy, last, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AlertBox } from '../../../components/AlertBox';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { CheckboxSimple } from '../../../components/CheckboxSimple';
import { Chip } from '../../../components/Chip';
import { DeleteButton } from '../../../components/DeletionConfirmation';
import { DialogActionsHeader } from '../../../components/DialogActionsHeader';
import { DialogActionsWithSlots } from '../../../components/DialogActionsWithSlots';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../components/GroupableList';
import { Loader } from '../../../components/Loader';
import { SearchInput } from '../../../components/SearchInput';
import { IColumn } from '../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { EtlProcessLinksToPg } from '../../../domainTypes/etl';
import { getSpaceDomainNames, ISpace } from '../../../domainTypes/space';
import { CanvasBar } from '../../../layout/Canvas';
import { Centered } from '../../../layout/Centered';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useStringQueryParam } from '../../../routes';
import {
  removeDoc,
  store,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { toLogLink } from '../../../services/logging';
import { fromMoment } from '../../../services/time';
import { DataGrid } from '../../components/DataGrid';
import { Json } from '../../components/Json';
import { publishInstruction } from '../../services/pubsub';
import { matchSpace, useSpaces } from '../../services/space';

const toEtlProcessDoc = generateToDocFn<EtlProcessLinksToPg>((d) => {
  return d;
});
const collection = () => store().collection('etlProcessLinksToPgV1');
const etlStore = createCollectionListenerStore(
  () => new CollectionListener(collection(), toEtlProcessDoc)
);
const useEtlProcesses = () => useCollectionListener(etlStore(''));

const MAX_IN_PROGRESS = 5;
const AutoSeed = ({
  ds
}: {
  ds: {
    space: Doc<ISpace>;
    latestProcess: Doc<EtlProcessLinksToPg> | null;
  }[];
}) => {
  const inProgress = useRef<string[]>([]);
  const alreadyWorkedOn = useRef<string[]>([]);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (!active) {
      return;
    }
    const allBySpaceId = keyBy(ds, (d) => d.space.id);
    const sorted = sortBy(ds, (d) => getSpaceDomainNames(d.space.data));
    const notDone = sorted.filter(
      (d) =>
        d.latestProcess?.data.step !== 'DONE' &&
        d.latestProcess?.data.step !== 'ERROR'
    );
    const todo = notDone.filter(
      (d) =>
        !inProgress.current.includes(d.space.id) &&
        !alreadyWorkedOn.current.includes(d.space.id)
    );

    if (!todo.length) {
      console.log('ALL DONE');
      return;
    }

    const inProgressItems = compact(
      inProgress.current.map((spaceId) => allBySpaceId[spaceId])
    );
    console.log(
      `Currently in progress - ${inProgressItems.length}`,
      inProgressItems
    );
    const nextInProgressItems = inProgressItems.filter(
      (d) =>
        d.latestProcess?.data.step !== 'DONE' &&
        d.latestProcess?.data.step !== 'ERROR'
    );
    inProgress.current = nextInProgressItems.map((d) => d.space.id);

    while (inProgress.current.length < MAX_IN_PROGRESS) {
      const nextItem = todo.shift();
      if (!nextItem) {
        console.log('Cannot find next item');
        return;
      }
      inProgress.current.push(nextItem.space.id);
      alreadyWorkedOn.current.push(nextItem.space.id);

      // FIRE OFF THE FUNCTION TO START THE PROCESS
      startProcessForSpace(nextItem.space.id); // async - but no need to wait
    }
  }, [ds, active]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => setActive((x) => !x)}
    >
      {active ? 'Deactivate' : 'Activate'} Autorun
    </Button>
  );
};

const EltStatusChip = ({ d }: { d: Doc<EtlProcessLinksToPg> }) => {
  return (
    <Chip
      variant={d.data.running ? 'outlined' : 'default'}
      label={d.data.step}
      type={
        d.data.step === 'DONE'
          ? 'SUCCESS'
          : d.data.step === 'LOAD'
          ? 'PENDING'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : 'NONE'
      }
    />
  );
};

const numberFormatter = new Intl.NumberFormat();

const formatNumbers = (nums: number[]) =>
  nums.map((n) => numberFormatter.format(n)).join('/');

const getTotalFilesFromProcess = (d: Doc<EtlProcessLinksToPg>) => {
  return Object.keys(d.data.files).length;
};

const getDoneFilesFromProcess = (d: Doc<EtlProcessLinksToPg>) => {
  return Object.values(d.data.files).filter((f) => f.status === 'DONE').length;
};

const getTotalLinksFromProcess = (d: Doc<EtlProcessLinksToPg>) => {
  return Object.values(d.data.files).reduce((x, f) => x + f.count, 0);
};

const getDoneLinksFromProcess = (d: Doc<EtlProcessLinksToPg>) => {
  return Object.values(d.data.files)
    .filter((f) => f.status === 'DONE')
    .reduce((x, f) => x + f.count, 0);
};

const getDroppedItemsFromProcess = (d: Doc<EtlProcessLinksToPg>) => {
  return Object.values(d.data.files).reduce((x, f) => x + f.droppedItems, 0);
};

type D = {
  space: Doc<ISpace>;
  latestProcess: Doc<EtlProcessLinksToPg> | null;
};
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'spaceId',
    head: () => 'Space ID',
    cell: (d) => d.space.id,
    align: 'left',
    width: 150,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'space',
    head: () => 'Space',
    cell: (d) => getSpaceDomainNames(d.space.data),
    align: 'left',
    width: 200,
    flexGrow: 3,
    sortable: true
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => {
      return d.latestProcess ? <EltStatusChip d={d.latestProcess} /> : null;
    },
    align: 'left',
    width: 75
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => (
      <Typography variant="caption">
        {(d.latestProcess?.data.labels || []).join(', ')}
      </Typography>
    ),
    align: 'left',
    width: 75
  },
  {
    key: 'processStart',
    head: () => 'Start',
    cell: (d) =>
      d.latestProcess
        ? moment(d.latestProcess.data.startedAt).utc().format('YYYY-MM-DD')
        : null,
    align: 'left',
    width: 100,
    sortable: true,
    flexGrow: 0
  },
  {
    key: 'processEnd',
    head: () => 'End',
    cell: (d) =>
      d.latestProcess?.data.finishedAt
        ? moment(d.latestProcess.data.finishedAt).utc().format('YYYY-MM-DD')
        : null,
    align: 'left',
    width: 100,
    sortable: true,
    flexGrow: 0
  },
  {
    key: 'files',
    head: () => 'Files',
    cell: (d) => {
      if (!d.latestProcess) {
        return null;
      }
      const total = getTotalFilesFromProcess(d.latestProcess);
      const done = getDoneFilesFromProcess(d.latestProcess);
      return formatNumbers([done, total]);
    },
    align: 'right',
    width: 100,
    sortable: true
  },
  {
    key: 'links',
    head: () => 'Links',
    cell: (d) => {
      if (!d.latestProcess) {
        return null;
      }
      const total = getTotalLinksFromProcess(d.latestProcess);
      const done = getDoneLinksFromProcess(d.latestProcess);
      return formatNumbers([done, total]);
    },
    align: 'right',
    width: 150,
    sortable: true
  },
  {
    key: 'droppedItems',
    head: () => 'Drops',
    cell: (d) => {
      if (!d.latestProcess) {
        return null;
      }
      return Object.values(d.latestProcess.data.files).reduce(
        (x, f) => x + f.droppedItems,
        0
      );
    },
    align: 'right',
    width: 75,
    sortable: true
  }
];

const SORTERS: ItemSorters<D> = {
  spaceId: {
    key: 'spaceId',
    items: { sort: (d) => d.space.id, dir: 'asc' }
  },
  space: {
    key: 'space',
    items: { sort: (d) => getSpaceDomainNames(d.space.data), dir: 'asc' }
  },
  processStart: {
    key: 'processStart',
    items: {
      sort: (d) => {
        if (!d.latestProcess) {
          return 0;
        }
        return new Date(d.latestProcess.data.startedAt).valueOf();
      },
      dir: 'asc'
    }
  },
  processEnd: {
    key: 'processEnd',
    items: {
      sort: (d) => {
        if (!d.latestProcess?.data.finishedAt) {
          return 0;
        }
        return new Date(d.latestProcess.data.finishedAt).valueOf();
      },
      dir: 'asc'
    }
  },
  files: {
    key: 'files',
    items: {
      sort: (d) => {
        if (!d.latestProcess) {
          return 0;
        }
        return getTotalFilesFromProcess(d.latestProcess);
      },
      dir: 'desc'
    }
  },
  links: {
    key: 'links',
    items: {
      sort: (d) => {
        if (!d.latestProcess) {
          return 0;
        }
        return getTotalFilesFromProcess(d.latestProcess);
      },
      dir: 'desc'
    }
  },
  droppedItems: {
    key: 'droppedItems',
    items: {
      sort: (d) => {
        if (!d.latestProcess) {
          return 0;
        }
        return getDroppedItemsFromProcess(d.latestProcess);
      },
      dir: 'desc'
    }
  }
};
const DEFAULT_SORTER = SORTERS.space;

const rowToKey = (d: D) => d.space.id;

const AUTO_INSERT = true;
const startProcessForSpace = async (spaceId: string) => {
  console.log(`Starting process for ${spaceId}`);
  return publishInstruction({
    topic: 'dataMigrations-linksToPg-start',
    payload: {
      spaceId,
      labels: [],
      insert: AUTO_INSERT
    }
  });
};

const loadProcess = async (spaceId: string, processId: string) => {
  return publishInstruction({
    topic: 'dataMigrations-linksToPg-load',
    payload: {
      spaceId,
      processId
    }
  });
};

const getLogLinkForProcess = (d: D) => {
  if (!d.latestProcess) {
    return '';
  }
  return toLogLink(
    {
      type: 'linksToPg',
      processId: d.latestProcess.id || '',
      spaceId: d.space.id
    },
    {},
    {
      aroundTime: fromMoment(
        moment(d.latestProcess.data.startedAt).add(6, 'days')
      ),
      duration: 'P7D'
    }
  );
};

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: D;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActionsHeader
        onClose={onClose}
        left={
          <>
            {d.latestProcess ? <EltStatusChip d={d.latestProcess} /> : null}
            <Typography variant="h6">
              {d.space.id} - {d.latestProcess?.id || 'NOT RUN YET'}
            </Typography>
          </>
        }
      >
        {d.latestProcess && (
          <Button
            target="_blank"
            href={getLogLinkForProcess(d)}
            size="small"
            color="primary"
          >
            Open Logs
          </Button>
        )}
      </DialogActionsHeader>
      <DialogContent>
        {d.latestProcess ? (
          <FlexContainerVertical fullWidth marginBottom={2}>
            <DataGrid
              items={[
                ['Started at', d.latestProcess.data.startedAt],
                ['Finished at', d.latestProcess.data.finishedAt || '-'],
                [
                  'Files',
                  formatNumbers([
                    getDoneFilesFromProcess(d.latestProcess),
                    getTotalFilesFromProcess(d.latestProcess)
                  ])
                ],
                [
                  'Links',
                  formatNumbers([
                    getDoneLinksFromProcess(d.latestProcess),
                    getTotalLinksFromProcess(d.latestProcess)
                  ])
                ],
                [
                  'Dropped Links',
                  formatNumbers([getDroppedItemsFromProcess(d.latestProcess)])
                ]
              ]}
            />
            <Json data={d.latestProcess} />
          </FlexContainerVertical>
        ) : (
          <Centered height={500}>Not run yet</Centered>
        )}
      </DialogContent>
      <DialogActionsWithSlots
        left={
          d.latestProcess ? (
            <DeleteButton
              variant="outlined"
              color="secondary"
              onDelete={async () => {
                if (!d.latestProcess) {
                  return;
                }
                await removeDoc(d.latestProcess);
              }}
            >
              Delete
            </DeleteButton>
          ) : null
        }
        right={
          <FlexContainer justifyContent="flex-end">
            <Button onClick={onClose}>Close</Button>
            {d.latestProcess && d.latestProcess.data.step !== 'EXTRACT' && (
              <ButtonWithPromise
                pending="Loading..."
                variant={d.latestProcess ? 'outlined' : 'contained'}
                color="primary"
                onClick={async () => {
                  if (!d.latestProcess) {
                    return;
                  }
                  await loadProcess(d.space.id, d.latestProcess.id);
                }}
              >
                Load
              </ButtonWithPromise>
            )}
            <ButtonWithPromise
              pending="Starting..."
              variant={d.latestProcess ? 'outlined' : 'contained'}
              color="primary"
              onClick={() => {
                return startProcessForSpace(d.space.id);
              }}
            >
              Start
            </ButtonWithPromise>
          </FlexContainer>
        }
      />
    </Dialog>
  );
};

const Body = ({ ds }: { ds: D[] }) => {
  const [showDone, setShowDone] = useState(true);
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [selectedSpaceId, setSelectedSpaceId] = useStringQueryParam(
    'spaceId',
    ''
  );
  const selectedD = useMemo(
    () => ds.find((d) => d.space.id === selectedSpaceId) || null,
    [selectedSpaceId, ds]
  );

  const [labelFilter, setLabelFilter] = useState('');
  const [spaceFilter, setSpaceFilter] = useState('');

  const filteredDs = useMemo(() => {
    let res = ds;
    if (labelFilter) {
      res = res.filter((d) =>
        d.latestProcess?.data.labels.includes(labelFilter)
      );
    }
    if (spaceFilter) {
      res = res.filter((d) => matchSpace(d.space.data, spaceFilter));
    }
    if (!showDone) {
      res = res.filter((d) => d.latestProcess?.data.step !== 'DONE');
    }
    return res;
  }, [ds, labelFilter, spaceFilter, showDone]);

  return (
    <Page>
      <Section>
        <FlexContainerVertical fullWidth>
          <AlertBox variant="pending" style={{ width: '100%' }}>
            <FlexContainerVertical fullWidth>
              <strong>How does Autorun work?</strong>
              <ul>
                <li>
                  Goes through every space (max {MAX_IN_PROGRESS} in parallel)
                  and starts a seed process
                </li>
                <li>
                  It only touches spaces which have neither a DONE nor an ERROR
                  process already
                </li>
                <li>
                  This means if you want to start a new reseed process
                  altogether, clean out old processes first (e.g. just dump the{' '}
                  <strong>etlProcessLinksToPgV1</strong> collection)
                </li>
                <li>
                  98% will be done after ~15 minutes - there are some spaces
                  that take much longer than the rest
                </li>
                <li>Everything will be done after roughly 30 minutes</li>
                <li>
                  To start click the Activate button, keep this browser window
                  open and watch everything seeding
                </li>
              </ul>
            </FlexContainerVertical>
          </AlertBox>
          <AutoSeed ds={ds} />
        </FlexContainerVertical>
      </Section>
      <Section>
        <CanvasBar alignItems="center">
          <SearchInput
            placeholder="Filter by space"
            value={spaceFilter}
            onChange={setSpaceFilter}
          />
          <div>
            {ds.length} spaces -{' '}
            {ds.filter((d) => d.latestProcess?.data.step === 'DONE').length}{' '}
            done
          </div>
          <div>
            <CheckboxSimple
              label="Show DONE"
              color="primary"
              checked={showDone}
              onChange={setShowDone}
            />
          </div>
          <SearchInput
            placeholder="Filter by label"
            value={labelFilter}
            onChange={setLabelFilter}
          />
        </CanvasBar>
        <RowsRenderer
          variant="contained"
          rows={filteredDs}
          columns={COLUMNS}
          rowToKey={rowToKey}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          renderHead
          otherProps={undefined}
          onRowClick={(d) => setSelectedSpaceId(d.space.id)}
        />
      </Section>

      {selectedD && (
        <ProcessDialog
          open={true}
          onClose={() => setSelectedSpaceId('')}
          d={selectedD}
        />
      )}
    </Page>
  );
};

export const PagePgSeedLinks = () => {
  const [ds] = useMappedLoadingValue(
    useCombineLoadingValues(useSpaces(), useEtlProcesses()),
    ([spaces, ps]) => {
      const activeSpaces = spaces.filter((s) => s.data.active);
      const sortedPs = groupBy(
        sortBy(ps, (p) => p.data.startedAt),
        (p) => p.data.spaceId
      );
      return activeSpaces.map((s) => {
        const latestProcess = last(sortedPs[s.id] || []) || null;
        return {
          space: s,
          latestProcess
        };
      });
    }
  );
  if (!ds) {
    return <Loader height={300} />;
  }
  return <Body ds={ds} />;
};
