import shortid from 'shortid';
import { Doc, generateToDocFn } from '../../../../domainTypes/document';
import { PARTNERS } from '../../../../domainTypes/partners';
import {
  AttributionRule,
  AttributionRuleFields,
  AttributionRuleMap
} from '../../../../domainTypes/performanceLabelRules';
import { batchSetDocs, setDoc } from '../../../../services/db';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import {
  getKnownPartnerForKey,
  getKnownPartnerForKeyUnsafe
} from '../../../../services/partner';
import { now } from '../../../../services/time';
import { removeTrailingSlash } from '../../../../services/url';
import { FS } from '../../../../versions';
import { RuleBuilderConfig } from './RuleBuilder';

export const toAttributionRuleDoc = generateToDocFn<AttributionRule>();
export const toAttributionRuleMapDoc = generateToDocFn<AttributionRuleMap>();

const rebuildAttributionRuleMap = (spaceId: string) => {
  // Doing this because Insider and A360 have too many
  // rules so it fails to rebuild the map
  if (['WOO2CVcgO', 'UoEcF-b67'].includes(spaceId)) {
    return Promise.resolve();
  }
  return callFirebaseFunction('labelRules-rebuildAttributionRuleMap', {
    spaceId
  });
};

const normalizeTrailingSlashesInAttributionRule = (d: Doc<AttributionRule>) => {
  d.data.apply.forEach((a) => {
    if (a.type === 'PAGE') {
      a.value = removeTrailingSlash(a.value);
    }
    if (a.type === 'SITE') {
      a.value = removeTrailingSlash(a.value);
    }
  });
  return d;
};

export const createAttributionRule = async (rule: AttributionRule) => {
  const doc = normalizeTrailingSlashesInAttributionRule({
    id: shortid(),
    collection: FS.attributionRules,
    data: {
      ...rule,
      createdAt: now()
    }
  });
  await setDoc(doc);
  await rebuildAttributionRuleMap(doc.data.spaceId);
  return doc;
};

export const createAttributionRules = async (rules: AttributionRule[]) => {
  const n = now();
  const ruleDocs: Doc<AttributionRule>[] = rules.map((r) => {
    return normalizeTrailingSlashesInAttributionRule({
      id: shortid(),
      collection: FS.attributionRules,
      data: {
        ...r,
        createdAt: n
      }
    });
  });

  await batchSetDocs(ruleDocs);
  const spaceIds = [...new Set(ruleDocs.map((d) => d.data.spaceId))];
  await Promise.all(spaceIds.map(rebuildAttributionRuleMap));
  return ruleDocs;
};

export const config: RuleBuilderConfig<AttributionRuleFields> = {
  trackingLabel: {
    key: 'trackingLabel',
    label: 'SubID / Tracking Label',
    dataType: 'string'
  },
  advertiserName: {
    key: 'advertiserName',
    label: 'Advertiser name',
    dataType: 'string'
  },
  partnerProductName: {
    key: 'partnerProductName',
    label: 'Product Name',
    dataType: 'string'
  },
  partnerProductId: {
    key: 'partnerProductId',
    label: 'Product SKU / ID',
    dataType: 'string'
  },
  partnerKey: {
    key: 'partnerKey',
    label: 'Platform / Network',
    dataType: 'string',
    options: {
      autocomplete: {
        renderOption: (p) => {
          console.log(p);
          return p ? getKnownPartnerForKeyUnsafe(p).name : p;
        },
        renderInputLabel: (p) => (p ? getKnownPartnerForKey(p)?.name || p : p),
        // getOptionLabel: (p) => (p ? getKnownPartnerForKeyUnsafe(p).name : p),
        getItems: async () => {
          return PARTNERS.map((p) => p.key).sort();
        }
      }
    }
  }
};
