import { Card } from '@material-ui/core';
import { compact } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { advertiserAppSpaceStore } from '../../../../../../advertiser-app/services/spaces';
import { Loader } from '../../../../../../components/Loader';
import { MarketplaceAdvertiser } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { FlexContainerVertical } from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { useDocumentListeners } from '../../../../../../services/firecache/documentListener';

export const AdvertiserAppSpaces = ({
  d
}: {
  d: Doc<MarketplaceAdvertiser>;
}) => {
  const [ds] = useMappedLoadingValue(
    useDocumentListeners(
      d.data.advertiserAppSpaces.map((id) => advertiserAppSpaceStore(id))
    ),
    compact
  );
  return (
    <Section>
      <CanvasBar>
        <div>Advertiser App Spaces</div>
      </CanvasBar>
      <Card>
        {!ds && <Loader height={50} />}
        {ds && (
          <FlexContainerVertical>
            {ds.map((d) => (
              <Link key={d.id} to={`/spaces/advertisers/${d.id}`}>
                <div>{d.data.config.name}</div>
              </Link>
            ))}
          </FlexContainerVertical>
        )}
      </Card>
    </Section>
  );
};
