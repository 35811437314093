import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { ProfileLabel } from '../../../components/ProfileLabel';
import { Dash } from '../../../components/Table/CountCell';
import { useSpace } from '../../services/space';

export const SpaceProfileLabel = ({ spaceId }: { spaceId: string }) => {
  const [space, loading] = useSpace(spaceId);
  if (loading) {
    return <Skeleton />;
  }
  if (!space) {
    return <Dash />;
  }
  const { profile } = space.data;
  if (!profile.name) {
    return <Typography variant="caption">{spaceId}</Typography>;
  }
  return <ProfileLabel profile={profile} />;
};
