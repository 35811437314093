import { Card, CardContent } from '@material-ui/core';
import { keyBy, partition, sortBy, uniq } from 'lodash';
import React from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { DAY_FORMAT } from '../../../domainTypes/analytics';
import { IDenormalizedClickEvent } from '../../../domainTypes/denormalization';
import { Doc, toDocsFromRdbObject } from '../../../domainTypes/document';
import {
  IPageScreenshotQueueItem,
  QueueStatus
} from '../../../domainTypes/page';
import { toTrackedSaleDoc } from '../../../features/PerformanceNew/services/sale';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { CanvasBar } from '../../../layout/Canvas';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import {
  batchSet,
  rdb,
  refreshTimestamp,
  store,
  toRef
} from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { toMoment } from '../../../services/time';
import { CF, FS, RDB } from '../../../versions';
import { ButtonContainer } from '../../components/ButtonContainer';
import { SafeExecuteButton } from '../../components/SafeExecuteButton';
import { checkAllSpacesForModifications } from '../../services/modifications';
import { resumeAllQueues } from '../../services/pageQueue';
import { getSpaceIds } from '../../services/space';
import { updateAllUserEngagementData } from '../../services/userEngagement';

/*
  Missing click data files.
  Take what you like. Can't put them in a constant, as it will
  break the type inference.
    '../../data/missing-click-data-2019-11-07-2020-01-18.json'
    '../../data/missing-click-data-2020-01-19-2020-02-19.json'
    '../../data/missing-click-data-2019-11-04-2020-03-03.json'
const getMissingClickData = async (): Promise<IDenormalizedClickEvent[]> => {
  const missingClicks = await import(
    '../../data/missing-click-data-2019-11-04-2020-03-03.json'
  );
  return missingClicks.default.map((m) => {
    return {
      pvId: m.page_view_id,
      device: m.device as Device,
      country: m.geo_country,
      href: m.href,
      pId: m.product_id,
      tId: m.tracking_id,
      occ: parseInt(m.occurrence, 10),
      createdAt: tsFromMillis(new Date(m.created_at).valueOf() + 30000)
    };
  });
};
*/

const abortPageQueueItems = async (execute: boolean) => {
  return rdb()
    .ref(RDB.pageQueue)
    .once('value', async (s) => {
      const data: { [spaceId: string]: any } = s.val();
      const spaceIds = Object.keys(data);
      for (const spaceId of spaceIds) {
        const items = toDocsFromRdbObject<IPageScreenshotQueueItem>(
          data[spaceId]
        );
        const abortedItems = items
          .filter((i) => i.data.status === QueueStatus.QUEUED)
          .map<Doc<IPageScreenshotQueueItem>>((i) => {
            const status = QueueStatus.ABORTED;
            return {
              ...i,
              data: {
                ...i.data,
                status,
                sortKey: `${status}-${i.data.queuedAt}`
              }
            };
          })
          .reduce<{ [id: string]: IPageScreenshotQueueItem }>((m, i) => {
            m[i.id] = i.data;
            return m;
          }, {});

        if (execute) {
          if (Object.keys(abortedItems).length) {
            await rdb().ref(toRef(RDB.pageQueue, spaceId)).update(abortedItems);
          }
        } else {
          console.log('NOT EXECUTING', spaceId, abortedItems);
        }
      }
    });
};

const createAndLinkSyntheticClicksWithSales = async (execute: boolean) => {
  const sales = await store()
    .collection(FS.sales)
    .where('sale.trackingId', '>', '')
    .where('click', '==', null)
    .get()
    .then((s) => s.docs.map(toTrackedSaleDoc));

  const sorted = sortBy(sales, (s) => s.data.sale.saleDate.toMillis());
  const first = sorted[0];
  const last = sorted[sorted.length - 1];

  const start = toMoment(first.data.sale.saleDate)
    .subtract(3, 'd')
    .format(DAY_FORMAT);
  const end = toMoment(last.data.sale.saleDate).add(1, 'd').format(DAY_FORMAT);

  const uniqTrackingIds = uniq(sorted.map((s) => s.data.sale.trackingId));

  const tz = 'UTC';
  // const query = `
  //   select
  //     space_id,
  //     format_datetime("%E4Y%j", datetime(created_at, "${tz}")) as time_key,
  //     href,
  //     product_id,
  //     page_view_id,
  //     device,
  //     geo_country,
  //     occurrence,
  //     type,
  //     tracking_id,
  //     created_at
  //   from ${BQ.events}
  //   where
  //     type = "viewed"
  //     and tracking_id in (${uniqTrackingIds.map((id) => `"${id}"`).join(', ')})
  //     and created_at
  //       between timestamp_trunc(timestamp("${start}", "${tz}"), day, "${tz}")
  //       and timestamp_trunc(timestamp("${end}", "${tz}"), day, "${tz}")
  //   order by created_at
  // `;
  // console.log(query);
  console.log(uniqTrackingIds, uniqTrackingIds.length);

  const missingData = await callFirebaseFunction<IDenormalizedClickEvent[]>(
    'analytics-getMissingClickData',
    {
      start,
      end,
      tz,
      trackingIds: uniqTrackingIds
    }
  ).then((xs) => {
    xs.forEach((x) => {
      x.createdAt = refreshTimestamp(x.createdAt);
    });
    return xs;
  });
  const missingDataByTrackingId = keyBy(missingData, (c) => c.tId);
  const [found, missing] = partition(sales, (s) => {
    const click = missingDataByTrackingId[s.data.sale.trackingId || ''];
    if (click) {
      s.data.click = click;
    }
    return !!click;
  });

  const clickDocs: Doc<IDenormalizedClickEvent>[] = missingData.map((d) => ({
    id: d.tId,
    collection: FS.clicksWithTrackingLabel,
    data: d
  }));

  console.log('click docs: ', clickDocs);
  console.log('relinked: ', found);
  console.log('still missing: ', missing);

  if (execute) {
    await batchSet(FS.clicksWithTrackingLabel, clickDocs);
    await batchSet(FS.sales, found);
  }
};

export const PageDenormalizations = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Section>
        <CanvasBar>Tracking Config</CanvasBar>
        <Card>
          <CardContent>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              onClick={async () => {
                const spaceIds = await getSpaceIds();
                await Promise.all(
                  spaceIds.map((spaceId) => {
                    return callFirebaseFunction(
                      CF.trackingConfig_v2.refreshTrackingConfig,
                      {
                        spaceId
                      }
                    );
                  })
                ).then(() => {
                  enqueueSnackbar('All done!', {
                    variant: 'success'
                  });
                });
              }}
              pending="Refreshing..."
            >
              Refresh All Tracking Config V2s (Redis)
            </ButtonWithPromise>
          </CardContent>
        </Card>
      </Section>

      <Section>
        <CanvasBar>Screenshot Page Queue</CanvasBar>
        <Card>
          <CardContent>
            <ButtonContainer>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Resuming..."
                onClick={resumeAllQueues}
              >
                Resume all queues
              </ButtonWithPromise>

              <SafeExecuteButton
                variant="contained"
                color="primary"
                onClick={abortPageQueueItems}
              >
                Cancel all queued items
              </SafeExecuteButton>
            </ButtonContainer>
          </CardContent>
        </Card>
      </Section>

      <Section>
        <CanvasBar>Check for modifications</CanvasBar>
        <Card>
          <CardContent>
            <ButtonContainer>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Resuming..."
                onClick={() => checkAllSpacesForModifications(false)}
              >
                Check all spaces for modifications
              </ButtonWithPromise>
            </ButtonContainer>
          </CardContent>
        </Card>
      </Section>

      <Section>
        <CanvasBar>Sales</CanvasBar>
        <Card>
          <CardContent>
            <ButtonContainer>
              <SafeExecuteButton
                variant="contained"
                color="primary"
                onClick={createAndLinkSyntheticClicksWithSales}
              >
                Create and link synthetic clicks with sales
              </SafeExecuteButton>
            </ButtonContainer>
          </CardContent>
        </Card>
      </Section>

      <Section>
        <CanvasBar>User Engagement</CanvasBar>
        <Card>
          <CardContent>
            <ButtonContainer>
              <ButtonWithPromise
                pending="Updating..."
                variant="contained"
                color="primary"
                onClick={() => updateAllUserEngagementData()}
              >
                Update all data
              </ButtonWithPromise>
            </ButtonContainer>
          </CardContent>
        </Card>
      </Section>
    </Page>
  );
};
