import { Button, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { useAdvertiserSpace } from '../../../../../advertiser-app/services/spaces';
import { AlertBox } from '../../../../../components/AlertBox';
import { Loader } from '../../../../../components/Loader';
import { toArchivePath } from '../../../../../domainTypes/space';
import { styled } from '../../../../../emotion';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { formatBytes } from '../../../../../services/file';
import { useDownloadUrlWithCustomMetadata } from '../../../../../services/storage';
import { PRECISE_DATE } from '../../../../../services/time';
import {
  AnchoredSections,
  AnchorLinks,
  ISection
} from '../../../../layout/AnchoredSection';
import { TopNav } from '../../../../layout/TopNav';
import { AccessibleCatalogs } from './components/AccessibleCatalogs';
import { Data } from './components/Data';
import { General } from './components/General';
import { ImpersonationLink } from './components/ImpersonationLink';
import { MarketplaceAdvertisers } from './components/MarketplaceAdvertisers';

type Props = {
  spaceId: string;
};

const Header = styled('h2')`
  display: flex;
  align-items: center;
  color: white;

  a {
    color: white;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const NavHeader = ({ title }: { title: string }) => {
  return (
    <Header>
      <Link to="/spaces/advertisers">Advertiser Spaces</Link>
      <ChevronRight />
      <div>{title}</div>
    </Header>
  );
};

const ArchiveBody = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const NoSpace = ({ spaceId }: { spaceId: string }) => {
  const [archived, loading] = useDownloadUrlWithCustomMetadata<{
    name: string;
    archivedAt: string;
  }>(toArchivePath(spaceId));
  if (loading) {
    return <Loader />;
  }
  if (!archived) {
    return <Centered>Space not found.</Centered>;
  }

  const { metadata, url } = archived;

  return (
    <>
      <TopNav>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <NavHeader title={`${metadata.name} - ARCHIVED`} />
          </Grid>
        </Grid>
      </TopNav>
      <Page style={{ marginTop: 50 }}>
        <ArchiveBody>
          <p>
            Archived on{' '}
            {moment(metadata.customMetadata.archivedAt).format(PRECISE_DATE)}
          </p>
          <p>
            <Button
              href={url}
              download
              target="_blank"
              variant="contained"
              color="primary"
            >
              Download space ({formatBytes(archived.metadata.size)})
            </Button>
          </p>
        </ArchiveBody>
      </Page>
    </>
  );
};

export const PageAdvertiserAppSpaceDetails = ({ spaceId }: Props) => {
  const [doc, loading] = useAdvertiserSpace(spaceId);
  if (loading) {
    return <Loader />;
  }
  if (!doc) {
    return <NoSpace spaceId={spaceId} />;
  }

  const SECTIONS: ISection[] = [
    { anchor: 'general', label: 'General', el: <General space={doc} /> },
    {
      anchor: 'marketplaceAdvertisers',
      label: 'Marketplace Advertisers',
      el: <MarketplaceAdvertisers space={doc} />
    },
    {
      anchor: 'catalogs',
      label: 'Accessible Catalogs',
      el: <AccessibleCatalogs spaceId={spaceId} />
    },
    { anchor: 'data', label: 'Data', el: <Data space={doc} /> }
  ];

  return (
    <>
      <TopNav>
        <FlexContainer justifyContent="space-between">
          <NavHeader title={doc.data.config.name} />
          <ImpersonationLink spaceId={spaceId} />
        </FlexContainer>
        <AnchorLinks sections={SECTIONS} offset={100} />
      </TopNav>
      <Page style={{ marginTop: 108 }}>
        {!doc.data.active && (
          <Section>
            <AlertBox variant="error">SPACE DEACTIVATED</AlertBox>
          </Section>
        )}
        <AnchoredSections sections={SECTIONS} />
      </Page>
    </>
  );
};
