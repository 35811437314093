import React from 'react';
import { ArrowUpRight } from 'react-feather';
import { FlexContainer } from '../../../../../../layout/Flex';
import { AdvertiserAppLink } from '../../../../../components/AppLink';

export const ImpersonationLink: React.FC<{ spaceId: string }> = ({
  spaceId,
  children
}) => {
  return (
    <AdvertiserAppLink pathname={'/'} query={{ spaceId }}>
      <FlexContainer alignItems="center">
        <div>{children || 'Impersonate'}</div>
        <ArrowUpRight size={14} />
      </FlexContainer>
    </AdvertiserAppLink>
  );
};
