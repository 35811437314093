import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { mapValues, sortBy, sum } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { AlertBox } from '../../../components/AlertBox';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Chip } from '../../../components/Chip';
import { DeleteButton } from '../../../components/DeletionConfirmation';
import { DialogActionsHeader } from '../../../components/DialogActionsHeader';
import { DialogActionsWithSlots } from '../../../components/DialogActionsWithSlots';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../components/GroupableList';
import { Loader } from '../../../components/Loader';
import { formatNumber, toPercent } from '../../../components/Number';
import { SwitchWithLabel } from '../../../components/SwitchWithLabel';
import { IColumn } from '../../../components/Table/Column';
import { IsoDate } from '../../../components/Time/IsoDate';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { RealtimeEtlProcessSales } from '../../../domainTypes/etl';
import { css, styled } from '../../../emotion';
import { wait } from '../../../helpers';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useBooleanQueryParam, useStringQueryParam } from '../../../routes';
import { removeDoc, store, updateDoc } from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { toLogLink } from '../../../services/logging';
import { fromMoment } from '../../../services/time';
import { Json } from '../../components/Json';
import { LinkExternal } from '../../components/LinkExternal';
import { processParallelCapped } from '../../services/denormalization';
import { publishInstruction } from '../../services/pubsub';
import {
  getCloudStorageDirLink,
  getCloudStorageLink
} from '../../services/storage';

const CUTOFF = moment.utc().subtract(72, 'h');

const triggerLoad = async (d: Doc<RealtimeEtlProcessSales>) => {
  return Promise.all([
    publishInstruction({
      topic: 'dataMigrations-salesToClickhouse-realTime-load',
      payload: {
        processId: d.id,
        force: false
      }
    })
  ]);
};

const toRealtimeEtlProcessDoc = generateToDocFn<RealtimeEtlProcessSales>();
const collection = () => store().collection('realtimeEtlProcessSalesV1');
const etlStore = createCollectionListenerStore(
  () =>
    // bit hardcoded - last two calendar days at most
    new CollectionListener(
      collection().where('createdAt', '>', CUTOFF.toISOString()),
      toRealtimeEtlProcessDoc
    )
);
const useRealtimeEtlProcesses = () => useCollectionListener(etlStore(''));

const EltStatusChip = ({ d }: { d: Doc<RealtimeEtlProcessSales> }) => {
  return (
    <Chip
      label={d.data.step}
      type={
        d.data.step === 'DONE'
          ? 'SUCCESS'
          : d.data.step === 'EXTRACT' || d.data.step === 'LOAD'
          ? 'PENDING'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : 'NONE'
      }
    />
  );
};

const numberFormatter = new Intl.NumberFormat();

const Labels = ({ labels }: { labels: string[] }) => {
  return (
    <FlexContainer spacing={0.5}>
      {labels.map((l, i) => (
        <Chip
          key={i}
          size="small"
          label={l}
          variant={l === 'auto' ? 'default' : 'outlined'}
        />
      ))}
    </FlexContainer>
  );
};

const Monospace = styled('div')((p) => ({
  fontFamily: 'monospace'
}));

type D = Doc<RealtimeEtlProcessSales>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 95,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => {
      return <EltStatusChip d={d} />;
    },
    align: 'left',
    width: 100
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => <Labels labels={d.data.labels} />,
    align: 'left',
    width: 75
  },

  {
    key: 'insertionId',
    head: () => 'Insertion Id',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.data.insertionId}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 200,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'at',
    head: () => 'Created at',
    cell: (d) => <IsoDate d={d.data.createdAt} />,
    align: 'left',
    width: 150,
    sortable: true
  },
  {
    key: 'total',
    head: () => 'Total',
    cell: (d) => <div>{numberFormatter.format(d.data.rows.total)}</div>,
    align: 'right',
    width: 80,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'updated',
    head: () => 'Updated',
    cell: (d) => <div>{numberFormatter.format(d.data.rows.updated)}</div>,
    align: 'right',
    width: 80,
    flexGrow: 0,
    sortable: true
  }
];

const SORTERS: ItemSorters<D> = {
  at: {
    key: 'at',
    items: { sort: (d) => new Date(d.data.createdAt).valueOf(), dir: 'desc' }
  },
  insertionId: {
    key: 'insertionId',
    items: { sort: (d) => d.data.insertionId, dir: 'desc' }
  },
  total: {
    key: 'total',
    items: { sort: (d) => d.data.rows.total, dir: 'desc' }
  },
  updated: {
    key: 'updated',
    items: { sort: (d) => d.data.rows.updated, dir: 'desc' }
  }
};
const DEFAULT_SORTER = SORTERS.at;

const rowToKey = (d: D) => d.id;

const DataGridContainer = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gridColumnGap: p.theme.spacing(1),
  gridRowGap: p.theme.spacing(0.5)
}));

const DataGrid = ({ items }: { items: [string, React.ReactNode][] }) => {
  return (
    <DataGridContainer>
      {items.map(([left, right]) => (
        <React.Fragment key={left}>
          <strong>{left}</strong>
          {right}
        </React.Fragment>
      ))}
    </DataGridContainer>
  );
};

const getLogLinkForProcess = (d: Doc<RealtimeEtlProcessSales>) => {
  return toLogLink(
    {
      kind: 'realTimeSalesToClickhouseExtract',
      processId: d.id
    },
    {},
    {
      aroundTime: fromMoment(moment(d.data.createdAt)),
      duration: 'P1D'
    }
  );
};

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<RealtimeEtlProcessSales>;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActionsHeader
        onClose={onClose}
        left={
          <>
            <EltStatusChip d={d} />
            <Labels labels={d.data.labels} />
            <Typography variant="h6">
              {d.id} - {d.data.insertionId}
            </Typography>
          </>
        }
      >
        <Button
          target="_blank"
          href={getLogLinkForProcess(d)}
          size="small"
          color="primary"
        >
          Open Logs
        </Button>
      </DialogActionsHeader>
      <DialogContent>
        <Section>
          <DataGrid
            items={[
              ['Created at', d.data.createdAt],
              ['Finished at', d.data.finishedAt || <div />],
              [
                'Cloud Storage Dir',
                <LinkExternal
                  href={getCloudStorageDirLink(d.data.cloudStorageDir)}
                >
                  {d.data.cloudStorageDir}
                </LinkExternal>
              ],
              ['Total rows', numberFormatter.format(d.data.rows.total)],
              ['Updated rows', numberFormatter.format(d.data.rows.updated)],
              ['Affected Spaces', d.data.affectedSpaces.join(', ')]
            ]}
          />
        </Section>
        {Object.values(d.data.files).map((f) => {
          if (f.status === 'ERROR') {
            return (
              <div key={f.fileName}>
                <LinkExternal href={getCloudStorageLink(f.fileName)}>
                  {f.fileName} - {f.count}
                </LinkExternal>
              </div>
            );
          }
          return null;
        })}

        <Json data={d.data} shouldCollapse={(x) => x.name === 'files'} />
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <DeleteButton
            variant="outlined"
            color="secondary"
            onDelete={async () => {
              await removeDoc(d);
            }}
          >
            Delete
          </DeleteButton>
        }
        right={
          <FlexContainer justifyContent="flex-end">
            {d.data.step === 'EXTRACT' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                onClick={() =>
                  publishInstruction({
                    topic: 'dataMigrations-salesToClickhouse-realTime',
                    payload: {
                      insertionId: d.data.insertionId,
                      processId: d.id,
                      totalRowCount: d.data.rows.total,
                      insert: true
                    }
                  })
                }
              >
                Retry
              </ButtonWithPromise>
            )}
            {d.data.step === 'PRE_LOAD' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                onClick={() => triggerLoad(d)}
              >
                Load
              </ButtonWithPromise>
            )}

            {d.data.step === 'ERROR' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                onClick={() => triggerLoad(d)}
              >
                Attempt re-load
              </ButtonWithPromise>
            )}
            {d.data.step === 'DONE' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                onClick={async () => {
                  await updateDoc(d, (x) => ({
                    step: 'PRE_LOAD' as const,
                    files: mapValues(x.files, (f) => ({
                      ...f,
                      status: 'PENDING' as const
                    }))
                  }));
                }}
              >
                Revert to pre-load
              </ButtonWithPromise>
            )}
          </FlexContainer>
        }
      />
    </Dialog>
  );
};

const getRowClassName = (d: D) => {
  if (d.data.step !== 'DONE' || d.data.rows.updated !== 0) {
    return '';
  }
  return css(() => ({
    opacity: 0.3
  }));
};

const Body = ({ ds }: { ds: Doc<RealtimeEtlProcessSales>[] }) => {
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [
    showRowsWithoutUpdates,
    setShowRowsWithoutUpdates
  ] = useBooleanQueryParam('showRowsWithoutUpdates', false);
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );
  const selectedProcess = useMemo(
    () => ds.find((d) => d.id === selectedProcessId) || null,
    [selectedProcessId, ds]
  );

  const filteredDs = useMemo(() => {
    return showRowsWithoutUpdates
      ? ds
      : ds.filter((d) => d.data.step !== 'DONE' || d.data.rows.updated > 0);
  }, [ds, showRowsWithoutUpdates]);

  const counts = useMemo(() => {
    return {
      total: sum(ds.map((d) => d.data.rows.total)),
      updated: sum(ds.map((d) => d.data.rows.updated))
    };
  }, [ds]);

  return (
    <Page width="L">
      <Section>
        <AlertBox variant="pending">
          All times shown are UTC - showing data since{' '}
          {CUTOFF.format('YYYY-MM-DD HH:mm')}
        </AlertBox>

        <FlexContainer justifyContent="space-between" marginBottom={1}>
          <SwitchWithLabel
            label="Show rows without updates"
            checked={showRowsWithoutUpdates}
            onChange={setShowRowsWithoutUpdates}
          />
          <div>
            Processes: {ds.length} (
            {ds.filter((d) => d.data.rows.updated > 0).length})
          </div>
          <div>
            {[
              `Total: ${numberFormatter.format(counts.total)}`,
              `Updated: ${numberFormatter.format(counts.updated)}`,
              `Ratio: ${formatNumber({
                n: toPercent(counts.updated, counts.total),
                format: 'percent'
              })}`
            ].join(' | ')}
          </div>

          <Button
            onClick={async () => {
              console.log('cleanup');
              const extractedAndStuck = sortBy(
                ds,
                (d) => d.data.createdAt
              ).filter((d) => d.data.step === 'EXTRACT');
              console.log(extractedAndStuck.length, 'are stuck');

              await processParallelCapped(
                extractedAndStuck.map((d) => async () => {
                  console.log('go', d.id);
                  publishInstruction({
                    topic: 'dataMigrations-salesToClickhouse-realTime',
                    payload: {
                      insertionId: d.data.insertionId,
                      processId: d.id,
                      totalRowCount: d.data.rows.total,
                      insert: true
                    }
                  });
                  await wait(5000);
                }),
                5
              );
            }}
          >
            cleanup
          </Button>
        </FlexContainer>
        <RowsRenderer
          variant="contained"
          rows={filteredDs}
          columns={COLUMNS}
          rowToKey={rowToKey}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          rowToClassName={getRowClassName}
          renderHead
          otherProps={undefined}
          onRowClick={(d) => setSelectedProcessId(d.id)}
        />
      </Section>

      {selectedProcess && (
        <ProcessDialog
          open={true}
          onClose={() => setSelectedProcessId('')}
          d={selectedProcess}
        />
      )}
    </Page>
  );
};

export const PageRealtimeSalesProcesses = () => {
  const [ds] = useRealtimeEtlProcesses();
  if (!ds) {
    return <Loader height={300} />;
  }
  return <Body ds={ds} />;
};
