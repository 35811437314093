import React from 'react';
import { ChevronRight } from 'react-feather';
import { useMarketplaceAdvertiser } from '../../../../../advertiser-app/services/marketplaceAdvertiser';
import { Loader } from '../../../../../components/Loader';
import { styled } from '../../../../../emotion';
import { useErrorLoggerWithLabel } from '../../../../../hooks/useErrorLogger';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import {
  AnchoredSections,
  AnchorLinks,
  ISection
} from '../../../../layout/AnchoredSection';
import { TopNav } from '../../../../layout/TopNav';
import { AdvertiserAppSpaces } from './components/AdvertiserAppSpaces';
import { Catalogs } from './components/Catalogs';
import { Data } from './components/Data';
import { General } from './components/General';

const Header = styled('h2')`
  display: flex;
  align-items: center;
  color: white;

  a {
    color: white;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

export const PageMarketplaceAdvertiserDetails = ({ id }: { id: string }) => {
  const [d, loading, error] = useMarketplaceAdvertiser(id);
  useErrorLoggerWithLabel('useMarketplaceAdvertiser', error);

  if (loading) {
    return <Loader />;
  }
  if (!d) {
    return <Centered height={500}>Marketplace Advertiser not found</Centered>;
  }

  const SECTIONS: ISection[] = [
    { anchor: 'general', label: 'General', el: <General d={d} /> },
    {
      anchor: 'advertiserAppSpaces',
      label: 'Advertiser App Spaces',
      el: <AdvertiserAppSpaces d={d} />
    },
    {
      anchor: 'catalogs',
      label: 'Catalogs',
      el: <Catalogs ma={d} />
    },
    { anchor: 'data', label: 'Data', el: <Data d={d} /> }
  ];

  return (
    <>
      <TopNav>
        <FlexContainer justifyContent="space-between">
          <Header>
            <div>Marketplace Advertisers</div>
            <ChevronRight />
            <div>{d?.data.profile.name}</div>
          </Header>
        </FlexContainer>
        <AnchorLinks sections={SECTIONS} offset={100} />
      </TopNav>
      <Page width="FULL" style={{ marginTop: 108 }}>
        <AnchoredSections sections={SECTIONS} />
      </Page>
    </>
  );
};
