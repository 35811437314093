import firebase from 'firebase/app';
import { ISOTimeRange } from '../../../domainTypes/analytics_v2';
import {
  CampaignGoals,
  ISegmentCampaign
} from '../../../domainTypes/campaigns';
import { ISpace } from '../../../domainTypes/space';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { saveSegmentCampaign, updateFnSegmentCampaign3 } from './index';
import { SegmentCampaign } from './segment-campaign';

export interface CampaignFormFields
  extends Pick<
    ISegmentCampaign,
    'name' | 'partner' | 'incentives' | 'team' | 'managers' | 'segments'
  > {
  goals: CampaignGoals;
}

export type SegmentCampaignDraft = Pick<
  CampaignFormFields,
  'name' | 'partner' | 'incentives' | 'team' | 'managers' | 'goals' | 'segments'
>;

export const saveSegmentCampaignDraft = async (
  space: ISpace,
  campaignId: string,
  userId: string,
  draft: SegmentCampaignDraft,
  campaign: SegmentCampaign
) => {
  const campaignToSave: Omit<ISegmentCampaign, 'id' | 'spaceId' | 'type'> = {
    ...campaign,
    name: draft.name ?? campaign.name,
    partner: draft.partner ?? campaign.partner,
    incentives: draft.incentives ?? campaign.incentives,
    goals: draft.goals ?? campaign.goals,
    team: draft.team ?? campaign.team,
    managers: draft.managers ?? campaign.managers,
    segments: draft.segments ?? campaign.segments,
    timeframe: null
  };

  return saveSegmentCampaign(space.id, campaignId, userId, campaignToSave);
};

export const proposeSegmentCampaign = async (
  campaignId: string,
  userId: string
) => {
  return updateFnSegmentCampaign3(campaignId, (c) => ({
    status: 'proposed',
    log: [
      ...c.log,
      {
        operation: 'propose',
        timestamp: firebase.firestore.Timestamp.now(),
        userId
      }
    ]
  }));
};

export const scheduleSegmentCampaign = async (
  spaceId: string,
  campaignId: string,
  timeframe: ISOTimeRange
) => {
  await callFirebaseFunction('campaigns-pub_scheduleSegmentCampaign', {
    spaceId,
    campaignId,
    range: timeframe
  });
};

export const pauseSegmentCampaign = async (
  spaceId: string,
  campaignId: string,
  paused: boolean
) => {
  await callFirebaseFunction('campaigns-pub_pauseSegmentCampaign', {
    spaceId,
    campaignId,
    paused
  });
};
