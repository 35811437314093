export type Conditional = 'all' | 'any';
export type Operator =
  | '<'
  | '<='
  | '>'
  | '>='
  | '=='
  | '!='
  | '=~'
  | '!~'
  | 'contains'
  | 'not-contains';

export type MatchValue = number | string | boolean;
export type Condition<Key> = {
  op: Operator;
  k: Key;
  v: MatchValue;
};

export type Rule<Key extends string> = {
  type: Conditional;
  els: (Rule<Key> | Condition<Key>)[];
};

export const isRule = <Key extends string>(
  x: Rule<Key> | Condition<Key>
): x is Rule<Key> => {
  return !!(x as any).type;
};

export type FieldGetter<RuleKey, T> = (key: RuleKey, entity: T) => MatchValue;

export const EMPTY_RULE_GROUP: Rule<any> = {
  type: 'all',
  els: []
};
