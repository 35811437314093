import { DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

export const DialogTitleWithTypography: React.FC<{
  component?: any;
  variant?: any;
}> = ({ component = 'h2', variant = 'h4', children }) => {
  return (
    <DialogTitle disableTypography>
      <Typography component={component} variant={variant}>
        {children}
      </Typography>
    </DialogTitle>
  );
};
