import { IPubSubInstruction } from '../../../domainTypes/pubsub';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';

/**
 * @deprecated Use publish instruction instead
 */
export const publishTopic = (d: IPubSubInstruction) => {
  return callFirebaseFunction('pubsub-publishJson', d);
};

export const enqueueCloudTask = (d: {
  taskName: string;
  data: any;
  opts?: {
    dispatchDeadlineSeconds?: number;
    id?: string;
  }; // see backend for exact type - it's not exposed in the frontend types
  location?: string;
}) => {
  return callFirebaseFunction('cloudTasks-enqueueCloudTask', d);
};
