export type UtmParams = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
};

export type Query = {
  [key: string]: string | string[] | null | undefined;
};

export const withoutEmpty = (query: Query) =>
  Object.keys(query).reduce((m, k) => {
    const v = query[k];
    if (v && v.length) {
      m[k] = v;
    }
    return m;
  }, {} as Query);

export const withQuery = (
  url: string,
  params: Query,
  stringify: (o: { [key: string]: any }) => string
) => {
  const queryParams = withoutEmpty(params);
  return `${url}${
    Object.keys(queryParams).length ? `?${stringify(queryParams)}` : ''
  }`;
};

export const toUtm = ({
  medium,
  source,
  campaign,
  content,
  term
}: UtmParams) => {
  const result: { [key: string]: string } = {};
  medium && (result['utm_medium'] = medium);
  source && (result['utm_source'] = source);
  campaign && (result['utm_campaign'] = campaign);
  content && (result['utm_content'] = content);
  term && (result['utm_term'] = term);
  return result;
};

export const mutableWithoutUndefined = <T>(obj: {
  [key: string]: T | undefined;
}): { [key: string]: T } => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return (obj as unknown) as { [key: string]: T };
};

export const interpolatePath = (
  pathTemplate: string,
  params: { [key: string]: string },
  splat?: string
) => {
  const url = Object.keys(params).reduce(
    (path, key) => path.replace(':' + key, params[key]),
    pathTemplate
  );
  return splat ? url.replace(/\*$/, splat) : url;
};
