/* eslint-disable */

import { keyBy } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { toPercent } from '../../components/Number';
import {
  DAY_FORMAT,
  EMPTY_DAILY_COUNTER,
  EMPTY_DEVICE_COUNTER,
  ICountryCounter,
  IDailyCounter,
  IDeviceCounter,
  IPageCounter,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../domainTypes/analytics';
import { AnalyticsIntervalUnit } from '../../domainTypes/analytics_v2';
import { IPageWithCountsAndTrends } from '../../domainTypes/page';
import { ISpace } from '../../domainTypes/space';
import { usePromise } from '../../hooks/usePromise';
import { getCurrentSpace, getCurrentUser } from '../currentUser';
import { LoadingValueLike } from '../db';
import { getAverage, getMedian, getPercentile } from '../math';
import { INTERVALS_TO_TK } from '../time';

const EMPTY_ARR = () => [];

export type SignificanceReferencevalue = 'count' | 'lastCount';

export const getSignificantPages = (
  pages: IPageWithCountsAndTrends[],
  field: SignificanceReferencevalue
) => {
  const getViews = (p: IPageWithCountsAndTrends) => p.counts.pageViews[field];
  const dynamicThreshold = Math.round(
    (Math.max(...pages.map(getViews)) / 100) * 5
  );
  const prefilteredPages = pages.filter((p) => getViews(p) > dynamicThreshold);
  const views = prefilteredPages.map(getViews);
  const median = getMedian(views);
  const average = getAverage(views);
  const threshold = Math.min(median, average);
  return prefilteredPages.filter((p) => getViews(p) >= threshold);
};

export const getPagesAbovePercentile = (
  pages: IPageWithCountsAndTrends[],
  percentile: number
) => {
  const getViews = (p: IPageWithCountsAndTrends) => p.counts.pageViews.count;
  const threshold = getPercentile(percentile, pages.map(getViews));
  return pages.filter((p) => getViews(p) >= threshold);
};

export const getTrend = (before: number, after: number) => {
  if (!after && !before) {
    return 0;
  }
  if (!before) {
    return 1;
  }
  return toPercent(after, before) - 1;
};

export const getViewRatio = (counter: {
  viewed: number;
  served: number;
}): number => {
  return toPercent(counter.viewed, counter.served);
};

export const getClickRatio = (counter: {
  clicked: number;
  viewed: number;
}): number => {
  return toPercent(counter.clicked, counter.viewed);
};

export const getCpm = (counter: {
  pageViews: number;
  clicked: number;
}): number => {
  if (counter.pageViews === 0) {
    return 0;
  }

  return counter.clicked / counter.pageViews;
};

export const getCommissionRate = (
  commission: number,
  salesVolume: number
): number => {
  if (salesVolume === 0) {
    return 0;
  }
  return commission / salesVolume;
};

export const getRpm = (pageViews: number, revenueInCents: number) => {
  if (!pageViews || pageViews === 0) {
    return 0;
  }
  return revenueInCents ? (revenueInCents / pageViews) * 1000 : 0;
};

export const getEpc = (clicks: number, revenueInCents: number) => {
  if (!clicks) {
    return 0;
  }
  return revenueInCents ? revenueInCents / clicks : 0;
};

export const getAvgComm = (quantity: number, revenueInCents: number) => {
  if (!quantity || revenueInCents === 0) {
    return 0;
  }
  return revenueInCents ? revenueInCents / quantity : 0;
};

export const getAov = (orders: number, revenueInCents: number) => {
  if (!orders || revenueInCents === 0) {
    return 0;
  }
  return revenueInCents ? revenueInCents / orders : 0;
};

export const formatTimeKey = (timeKey: string, format: string = 'MMM DD') => {
  const year = +timeKey.slice(0, 4);
  const dayOfYear = +timeKey.slice(4);
  return moment().year(year).dayOfYear(dayOfYear).format(format);
};

export const getTimeKeyRange = (
  start: moment.Moment,
  end: moment.Moment
): string[] => {
  const range = diffDays(start, end);
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, 'days').format(TIMEKEY_FORMAT);
    result.push(x);
  }
  return result;
};

export const getTimeKeyRangeWithInterval = (
  start: moment.Moment,
  end: moment.Moment,
  interval: AnalyticsIntervalUnit
): string[] => {
  const range = Math.abs(start.diff(end, interval));
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, interval).format(INTERVALS_TO_TK[interval]);
    result.push(x);
  }
  return result;
};

export const padCountsForTimeframe = (
  counts: IDailyCounter[],
  timeframe: Timeframe
): IDailyCounter[] => {
  const start = moment.tz(timeframe.start, DAY_FORMAT, timeframe.tz);
  const end = moment.tz(timeframe.end, DAY_FORMAT, timeframe.tz);
  const days = diffDays(start, end);

  if (counts.length === days) {
    return counts;
  }

  const range = getTimeKeyRange(start, end);
  return padCounts(counts, range);
};

const padCounts = (
  counts: IDailyCounter[],
  timeKeyRange: string[]
): IDailyCounter[] => {
  if (counts.length === timeKeyRange.length) {
    return counts;
  }

  const dict = keyBy(counts, (t) => t.timeKey);
  return timeKeyRange.map((timeKey) => {
    return dict[timeKey] || EMPTY_DAILY_COUNTER(timeKey);
  });
};

export const allTime_ = (space: ISpace, tz: string): Timeframe => {
  const start = moment(space.createdAt.toDate())
    .tz(tz)
    .startOf('day')
    .format(DAY_FORMAT);
  const end = moment().tz(tz).startOf('day').format(DAY_FORMAT);
  return { start, end, tz };
};

export const allTime = (tz?: string): Timeframe => {
  return allTime_(getCurrentSpace(), tz || getCurrentUser().tz);
};

export const today = (tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const start = moment.tz(tz).startOf('day');
  const end = moment(start).add(1, 'days');
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const timeToDate = (unit: 'month' | 'year', tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz);
  const start = moment(end).startOf(unit);
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const lastTimeframe = (
  n: number,
  unit: 'day' | 'month' | 'year',
  tz?: string
): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz).startOf(unit);
  const start = moment(end).subtract(n, unit);
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const lastDays = (n: number, tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz).startOf('day');
  const start = moment(end).subtract(n, 'days');
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const getComparisonTimeframe = (t: Timeframe) => {
  const tz = t.tz;
  const start = moment.tz(t.start, DAY_FORMAT, tz);
  const end = moment.tz(t.end, DAY_FORMAT, tz);
  const days = diffDays(start, end);
  return {
    start: start.subtract(days, 'days').format(DAY_FORMAT),
    end: end.subtract(days, 'days').format(DAY_FORMAT),
    tz
  };
};

export const multiplyTimeframe = (
  t: Timeframe,
  multiplier: number
): Timeframe => {
  const tz = t.tz;
  const start = moment.tz(t.start, DAY_FORMAT, tz);
  const end = moment.tz(t.end, DAY_FORMAT, tz);
  const days = diffDays(start, end) * multiplier;
  return {
    start: moment(end).subtract(days, 'days').format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const toComparableTimeframe = (tf: Timeframe): Timeframe => {
  const m = multiplyTimeframe(tf, 2);
  return {
    start: m.start,
    end: tf.start,
    tz: tf.tz
  };
};

export const diffDays = (start: moment.Moment, end: moment.Moment): number => {
  return Math.abs(start.diff(end, 'days'));
};

// -------------- Product stuff ------------------ //

export const useCountsInTimeframePerPageForProductPg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValueLike<IPageCounter[]> => {
  const { start, end, tz } = timeframe;
  return useMemo(() => {
    return [EMPTY_ARR(), false, null];
  }, [spaceId, productId, start, end, tz, compare]);
};

export const useCountryClickCountsForProductInTimeframePg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValueLike<ICountryCounter[]> => {
  const { start, end, tz } = timeframe;
  return useMemo(() => {
    return [EMPTY_ARR(), false, null];
  }, [spaceId, productId, start, end, tz, compare]);
};

export const useDeviceClickCountsForProductInTimeframePg = (
  spaceId: string,
  productId: string,
  tf: Timeframe
): LoadingValueLike<IDeviceCounter> => {
  const { start, end, tz } = tf;
  return useMemo(() => {
    return [EMPTY_DEVICE_COUNTER(), false, null];
  }, [spaceId, productId, start, end, tz]);
};

// TODO remove
// Only used in currently unused LinkDetais
// This is just kept for reference atm.
// There is no way to get to the old links page atm.
// We don't remove it right away, so that we can refer to it
// while we rebuild it.
// We're however removing the endpoints behind it.
export const useCountsInTimeframeForProductPg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe
) => {
  return usePromise(async () => {
    return padCountsForTimeframe([], timeframe);
  }, [spaceId, productId, timeframe.start, timeframe.end, timeframe.tz]);
};
