import React from 'react';
import { Badge } from '../../../../../../components/Badge';
import { ProductCatalogConnectionStatus } from '../../../../../../domainTypes/advertiserApp';
import { COLORS } from '../../../../../../domainTypes/colors';

const CONFIG = {
  [ProductCatalogConnectionStatus.DRAFT]: {
    color: COLORS.gold.gold8,
    bgColor: COLORS.gold.gold2,
    label: 'In Review'
  },
  [ProductCatalogConnectionStatus.CONNECTED]: {
    color: COLORS.green.green8,
    bgColor: COLORS.green.green2,
    label: 'Connected'
  },
  [ProductCatalogConnectionStatus.DISABLED]: {
    color: COLORS.volcano.volcano8,
    bgColor: COLORS.volcano.volcano2,
    label: 'Disabled'
  }
};

export const ProductCatalogConnectionStatusBadge = ({
  status
}: {
  status: ProductCatalogConnectionStatus;
}) => {
  const config = CONFIG[status];
  return (
    <Badge color={config.color} bgColor={config.bgColor}>
      {config.label}
    </Badge>
  );
};
