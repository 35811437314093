import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { ProfileLabel } from '../../../../components/ProfileLabel';
import { Dash } from '../../../../components/Table/CountCell';
import { ISegmentCampaign } from '../../../../domainTypes/campaigns';
import { Doc } from '../../../../domainTypes/document';
import { useMappedLoadingValue } from '../../../../services/db';
import { useMarketplaceAdvertiserProfilesPublic } from '../../../../services/marketplaceAdvertisers';

export const CampaignPartnerProfileLabel = ({
  campaign
}: {
  campaign: Doc<ISegmentCampaign>;
}) => {
  const [
    partner,
    loading
  ] = useMappedLoadingValue(
    useMarketplaceAdvertiserProfilesPublic(campaign.data.spaceId),
    (partners) => partners.find((p) => p.id === campaign.data.partner?.id)
  );
  if (loading) {
    return <Skeleton />;
  }
  if (!partner) {
    return <Dash />;
  }
  return <ProfileLabel profile={partner} />;
};
