import {
  Card,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { ProductCatalogConnectionStatusBadge } from '../../../../../../advertiser-app/features/ProductCatalog/pages/Connections/components/StatusBadge';
import { ProductCatalogConnectionTypeBadge } from '../../../../../../advertiser-app/features/ProductCatalog/pages/Connections/components/TypeBadge';
import {
  useProductCatalogConnections,
  useProductCatalogDescriptor
} from '../../../../../../advertiser-app/features/ProductCatalog/pages/Connections/service';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../../components/GroupableList';
import { Loader } from '../../../../../../components/Loader';
import { IColumn } from '../../../../../../components/Table/Column';
import {
  MarketplaceAdvertiser,
  ProductCatalogConnection,
  ProductCatalogConnectionStatus
} from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Centered } from '../../../../../../layout/Centered';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { updateDoc } from '../../../../../../services/db';
import { FirestoreLink } from '../../../../../components/FirestoreLink';
import { Json } from '../../../../../components/Json';

type D = Doc<ProductCatalogConnection>;
type ColumnName = 'name' | 'type' | 'status' | 'catalogId';
type Column = IColumn<D, ColumnName>;

const COLUMNS: Column[] = [
  {
    key: 'type',
    head: () => 'Type',
    cell: (d) => (
      <ProductCatalogConnectionTypeBadge type={d.data.details.type} />
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => d.data.name,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'catalogId',
    head: () => 'Catalog ID',
    cell: (d) => d.data.catalogId,
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: false
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => <ProductCatalogConnectionStatusBadge status={d.data.status} />,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  }
];

const SORTERS: ItemSorters<D> = {
  name: {
    key: 'name',
    items: {
      sort: [(d) => d.data.name],
      dir: 'asc'
    }
  },
  type: {
    key: 'type',
    items: {
      sort: [(d) => d.data.details.type, (d) => d.data.name],
      dir: 'asc'
    }
  },
  status: {
    key: 'status',
    items: {
      sort: [
        (d) => d.data.status,
        (d) => d.data.details.type,
        (d) => d.data.name
      ],
      dir: 'asc'
    }
  }
};

const ROW_TO_KEY = (d: D) => d.id;

const Descriptor = ({ catalogId }: { catalogId: string }) => {
  const [d, loading] = useProductCatalogDescriptor(catalogId);
  if (loading) {
    return <Loader height={100} />;
  }
  if (!d) {
    return <Centered>No descriptor found</Centered>;
  }
  return <Json data={d} fullWidth />;
};

export const ConnectionDialog = ({
  d: _d,
  onClose
}: {
  d: { ma: Doc<MarketplaceAdvertiser>; d: Doc<ProductCatalogConnection> };
  onClose: () => void;
}) => {
  const { d } = _d;
  const [catalogId, setCatalogId] = useState<string>(d.data.catalogId);
  const [status, setStatus] = useState<ProductCatalogConnectionStatus>(
    d.data.status
  );
  useEffect(() => {
    setCatalogId(d.data.catalogId);
  }, [d.data.catalogId]);
  useEffect(() => {
    setStatus(d.data.status);
  }, [d.data.status]);
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <FlexContainerVertical fullWidth spacing={3}>
          <FlexContainerVertical spacing={2} fullWidth>
            <FlexContainer fullWidth justifyContent="space-between">
              <Typography variant="h6">Connection</Typography>
              <FlexContainer>
                <Select
                  value={status}
                  onChange={(e) =>
                    setStatus(e.target.value as ProductCatalogConnectionStatus)
                  }
                >
                  {[
                    ProductCatalogConnectionStatus.CONNECTED,
                    ProductCatalogConnectionStatus.DRAFT,
                    ProductCatalogConnectionStatus.DISABLED
                  ].map((s) => (
                    <MenuItem key={s} value={s}>
                      <ProductCatalogConnectionStatusBadge status={s} />
                    </MenuItem>
                  ))}
                </Select>
                <ButtonWithPromise
                  pending={'Saving...'}
                  onClick={async () => {
                    await updateDoc(d, () => ({ status }));
                  }}
                  variant="contained"
                  color="primary"
                  disabled={status === d.data.status}
                >
                  Save
                </ButtonWithPromise>
              </FlexContainer>
              <FirestoreLink doc={d} />
            </FlexContainer>
            <FlexContainer fullWidth justifyContent="space-between">
              <TextField
                label="Catalog ID"
                value={catalogId}
                onChange={(e) => setCatalogId(e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              />
              <ButtonWithPromise
                pending={'Saving...'}
                onClick={async () => {
                  try {
                    await updateDoc(d, () => ({ catalogId }));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                variant="contained"
                color="primary"
                disabled={catalogId === d.data.catalogId}
              >
                Save
              </ButtonWithPromise>
            </FlexContainer>

            <Json data={d} fullWidth />
          </FlexContainerVertical>
          <FlexContainerVertical spacing={2} fullWidth>
            <FlexContainer fullWidth justifyContent="space-between">
              <Typography variant="h6">Descriptor</Typography>
              {d.data.catalogId && (
                <FirestoreLink
                  doc={{
                    collection: 'productCatalogDescriptorsV1',
                    id: d.data.catalogId
                  }}
                />
              )}
            </FlexContainer>
            {!d.data.catalogId && (
              <Centered>No catalog ID assigned yet</Centered>
            )}
            {d.data.catalogId && <Descriptor catalogId={d.data.catalogId} />}
          </FlexContainerVertical>
        </FlexContainerVertical>
      </DialogContent>
    </Dialog>
  );
};

export const Catalogs = ({ ma }: { ma: Doc<MarketplaceAdvertiser> }) => {
  const [ds] = useProductCatalogConnections([ma.id]);
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [selectedId, setSelectedId] = useState('');
  const selected = useMemo(() => {
    return ds ? ds.find((r) => r.id === selectedId) || null : null;
  }, [selectedId, ds]);
  return (
    <Section>
      <CanvasBar>
        <div>Catalog Connections</div>
      </CanvasBar>
      <Card>
        {!ds && <Loader height={100} />}
        {ds && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={ds}
            rowToKey={ROW_TO_KEY}
            sorter={sorter || SORTERS.name}
            sortDirection={dir}
            renderHead={true}
            headProps={{ sticky: true, offset: 48 }}
            onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.ma, d])}
            chunkSize={30}
            rootMargin="300px"
            rowHeight={ROW_HEIGHTS.dense}
            otherProps={undefined}
            onRowClick={(d) => setSelectedId(d.id)}
          />
        )}
      </Card>
      {selected && (
        <ConnectionDialog
          d={{ ma, d: selected }}
          onClose={() => setSelectedId('')}
        />
      )}
    </Section>
  );
};
