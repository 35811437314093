import { IAutoLabelConfigItem, ITrackingConfigSmall } from './tracking';

// This code contains three main parts:
// 1. Constants for generating tracking IDs
// 2. Shared functions for determining which auto-label config to use
//    and applying Smart Labels to affiliate links
// 3. Temporary tracking configs we want to test out

// necessary bits for the tracking script, which doesn't need the whole file
export const TRACKING_ID_PREFIX = 'amcid-';
// ALT needed for places where dashes not in allowed subid
// character set
export const TRACKING_ID_PREFIX_ALT = 'amcid_';
export const TRACKING_ID_DEFAULT_DELIMITER = '_';
export const toTrackingId = (id: string, useAlt?: boolean) =>
  !useAlt ? `${TRACKING_ID_PREFIX}${id}` : `${TRACKING_ID_PREFIX_ALT}${id}`;

export const DEFAULT_ID_LENGTH = 21;

export const getAutoLabelConfigForUrl = ({
  url,
  partnerKey,
  config
}: {
  url: string;
  partnerKey: string | null;
  config: ITrackingConfigSmall;
}) => {
  if (!partnerKey) {
    return undefined;
  }

  const configsByPartnerKey = config.autoLabelling.reduce(
    (memo, c) => {
      if (!memo[c.key]) {
        memo[c.key] = [];
      }
      memo[c.key].push(c);
      return memo;
    },
    {} as { [key: string]: IAutoLabelConfigItem[] }
  );
  const pkConfigs: IAutoLabelConfigItem[] | undefined =
    configsByPartnerKey[partnerKey];

  if (!pkConfigs) {
    // Means they don't have smart labels turned on for this partner
    return undefined;
  }

  let autoLabelConfig: IAutoLabelConfigItem | undefined = undefined;

  try {
    // TODO: If the href starts with :// or just /, then
    // we probably need to fill this in for the user
    const { hostname } = new URL(url);

    const configsForPkByDomain = pkConfigs
      ? pkConfigs.reduce(
          (memo, c) => {
            if (c.domain) {
              memo[c.domain] = c;
            }
            return memo;
          },
          {} as { [key: string]: IAutoLabelConfigItem }
        )
      : {};

    if (configsForPkByDomain[hostname]) {
      autoLabelConfig = configsForPkByDomain[hostname];
    } else if (pkConfigs && pkConfigs.length) {
      for (const c of pkConfigs) {
        // for partners, which have different tracking
        // parameters for different subdomains
        if (c.domain && hostname.match(new RegExp(`${c.domain}$`))) {
          autoLabelConfig = c;
          break;
        }
      }
    }

    // If we couldn't match based on domain, default
    // to the first provided autolabel config for
    // this partnerkey
    if (!autoLabelConfig && pkConfigs) {
      autoLabelConfig = pkConfigs[0];
    }
  } catch (err) {
    return undefined;
  }
  return autoLabelConfig;
};

const GENIUSLINK_PARAM = 'GR_URL';

export const isLongGeniuslink = (url: string) => {
  if (url.indexOf('geni.us') === -1) {
    return false;
  }
  try {
    const u = new URL(url);
    return u.searchParams.has(GENIUSLINK_PARAM);
  } catch (err) {
    return false;
  }
};

export const getNestedGeniusLinkUrl = (url: string) => {
  try {
    const u = new URL(url);
    const nestedUrl = u.searchParams.get(GENIUSLINK_PARAM);
    if (nestedUrl) {
      return nestedUrl;
    }
    return null;
  } catch (err) {
    // Do nothing
    return null;
  }
};

export const deepUpdateGeniuslink = (
  u: URL,
  paramName: string,
  paramValue: string
) => {
  const nestedUrl = u.searchParams.get(GENIUSLINK_PARAM);
  if (nestedUrl) {
    try {
      const nestedUrlObj = new URL(nestedUrl);
      nestedUrlObj.searchParams.set(paramName, paramValue);
      u.searchParams.set(GENIUSLINK_PARAM, nestedUrlObj.href);
    } catch (err) {
      return u;
      // Do nothing
    }
  }
  return u;
};

// toLabelledUrl - Server side
export const toTrackingLabel = (
  pageSlug: string,
  trackingId: string,
  partnerKey: string,
  maxLength: number | undefined,
  alphanumeric: boolean | undefined = false,
  delimiter: string = TRACKING_ID_DEFAULT_DELIMITER
) => {
  const maxL = maxLength;
  let slug = pageSlug;

  if (alphanumeric) {
    slug = slug.replace(/\//g, '');
    slug = slug.replace(/[-_/]/g, '');
  }

  // Get rid of slashes
  if (
    partnerKey === 'pap' ||
    partnerKey === 'partnerize' ||
    partnerKey === 'affilae' ||
    partnerKey === 'booking'
  ) {
    slug = slug.replace(/\//g, '');
  }

  // Get rid of slashes and dashes
  if (partnerKey === 'travelpayouts') {
    slug = slug.replace(/[/-]+/g, '');
  }

  if (partnerKey === 'tradetracker') {
    return trackingId;
  }

  if (!maxL) {
    return `${slug.substr(0, 50)}${delimiter}${trackingId}`;
  }

  if (partnerKey === 'clickbank') {
    slug = slug.replace(/[-_/]/g, '');
  }

  // Use the alt tracking ID with clickbank and travelpayouts
  // because they cannot process dashes
  if (
    partnerKey === 'clickbank' ||
    partnerKey === 'travelpayouts' ||
    partnerKey === 'idevaffiliate'
  ) {
    const altTrackingId = trackingId.replace(
      TRACKING_ID_PREFIX,
      TRACKING_ID_PREFIX_ALT
    );
    const remainingChars = maxL - trackingId.length - 1;
    return `${slug.substr(0, remainingChars)}${delimiter}${altTrackingId}`;
  }

  // add a threshold under which it would make no real sense to add the page slug
  if (maxL <= trackingId.length + 5) {
    return trackingId;
  }

  const remainingCharsFinal = maxL - trackingId.length - 1;
  return `${slug.substr(0, remainingCharsFinal)}${delimiter}${trackingId}`;
};
export const toLabelledUrl = (
  href: string,
  pageSlug: string,
  trackingId: string,
  config: IAutoLabelConfigItem | undefined
) => {
  if (config) {
    try {
      const label = toTrackingLabel(
        pageSlug,
        trackingId,
        config.key,
        config.maxLabelLength,
        config.alphanumeric
      );
      const url = new URL(href);

      if (config.replace) {
        const regex = new RegExp(config.replace.match);
        const matches = regex.exec(url.href);

        if (matches && matches.length) {
          // Replace any existing, in URL subids
          const toReplace =
            ['travelpayouts', 'tradetracker'].indexOf(config.key) !== -1
              ? matches[1]
              : matches[0];

          const templateLabel = config.replace.template.replace(
            'TRACKING_LABEL',
            label
          );

          url.href = url.href.replace(toReplace, templateLabel);

          // If a replace match happens, do not also try to add a query parameter
          return url.href;
        } else if (
          config.replace.fallbackMatch &&
          config.replace.fallbackTemplate
        ) {
          // Or if not present, add if replacement is possible
          const fallbackMatch = new RegExp(config.replace.fallbackMatch).exec(
            url.href
          );
          if (fallbackMatch && fallbackMatch.length) {
            const [toReplace] = fallbackMatch;
            const templateLabel = config.replace.fallbackTemplate
              .replace('TRACKING_LABEL', label)
              .replace('MATCH', toReplace);

            url.href = url.href
              .replace(toReplace, templateLabel)
              // Replace any duplicate slashes for Partnerize
              .replace('//destination', '/destination')
              .replace('//pubref', '/pubref')
              .replace('//camref', '/camref')
              // Replace double underscores for TradeTracker
              .replace('__', '_');

            // If a fallback match happens, do not also try to add a query parameter
            return url.href;
          }
        }
      }
      if (config.labelParam) {
        if (config.key === 'amazon' && isLongGeniuslink(url.href)) {
          deepUpdateGeniuslink(url, config.labelParam, label);
          return url.href;
        }

        // Temporarily don't add label to webgains because it breaks some links
        if (config.key !== 'webgains') {
          url.searchParams.set(config.labelParam, label);
        }

        // Hotfix, to fix properly later
        if (config.key === 'affilae') {
          url.searchParams.set('aev', label);
          url.searchParams.set(`aev${label}`, '');
        }
      }
      return url.href;
    } catch (err) {
      // Whoever is using this should catch the error and log it
      // using whichever is available: client or server-side code
      throw err;
    }
  }
  return null;
};

const withoutTrailingSlashAtAnyPosition = (t: string) =>
  t.replace(/\/([?#$])/, '$1');

export const isSuperDynamicLink = (href: string) => {
  // If the link is super dynamic, do not
  // send events about it at all
  const badParams = [
    'fbclid',
    'gclid',
    'tblci',
    'cjevent', // CJ mastertag
    '_aw_m_', // Awin mastertag
    'xuuid', // Skimlinks
    'view_instance_uuid', // CNET
    'nrtv_cid', // Howl script
    'uuid',
    'custData',
    'ascsubtag',
    'ntv_ui' // exchange.postrelease.com
  ];
  // This one can appear as either a hash or a query param
  if (href.indexOf('#tblci') !== -1) {
    return true;
  }

  // Amazon aax-us-east.amazon-adsystem.com
  if (href.indexOf('anonymizedLogId') !== -1) {
    return true;
  }

  // Ignore google ad manager URLs
  if (href.indexOf('adclick.g.doubleclick.net') !== -1) {
    return true;
  }

  // Ignore links with Trackonomics subids in them
  if (
    href.indexOf('xid:fr') !== -1 ||
    href.indexOf('xid-fr') !== -1 ||
    new RegExp(/fr\d{7,}[a-z]{3,}/).test(href)
  ) {
    return true;
  }

  // Check for these bad params ANYWHERE in
  // the URL, including encoded within a URL
  // parameter
  return badParams.find((param) => {
    return (
      new RegExp(`${param}=`).test(href) || new RegExp(`${param}%3D`).test(href)
    );
  });
};

export const toCleanHref = (
  href: string,
  options: {
    trimTrailingSlash: boolean;
    trimDanglingQuestionMark: boolean;
  }
) => {
  let cleanHref = href;

  try {
    if (href.indexOf('/') === 0) {
      return href;
    }
  } catch (err) {
    return '';
  }

  // For CNET, extract the actual URL from the tracking URL
  const cnetDomain = 'cc.cnet.com';
  const urlParam = 'url';
  if (href.indexOf(cnetDomain) !== -1) {
    try {
      const url = new URL(href);
      const urlParamValue = url.searchParams.get(urlParam);
      if (urlParamValue) {
        cleanHref = urlParamValue;
      }
    } catch (err) {
      // Do nothing
    }
  }

  try {
    // If it's a partnerize link, remove the dynamic partnerize pubref
    const url = new URL(cleanHref);
    const matchesPartnerize = new RegExp(/pubref:.+fr\d{7,}[a-z]{3,}\//).exec(
      url.href
    );

    if (matchesPartnerize) {
      const [match] = matchesPartnerize;
      const { index } = matchesPartnerize;
      const beginningOfUrl = url.href.substr(0, index);
      const endOfUrl = url.href.substr(index + match.length, url.href.length);
      return `${beginningOfUrl}${endOfUrl}`;
    }
  } catch (err) {
    return href;
  }

  try {
    let url = new URL(cleanHref);

    // If it's a geniuslink, extract the tracked URL and clean that
    const isGeniuslink = new RegExp(/buy\.geni.us\/Proxy\.ashx/).test(
      cleanHref
    );

    if (isGeniuslink) {
      try {
        const trackedUrl = url.searchParams.get('GR_URL');
        if (trackedUrl) {
          url = new URL(trackedUrl);
        }
      } catch (err) {}
    }

    const searchParams = url.searchParams;
    const allParams: string[] = Array.from(searchParams.keys());

    const unwantedParams = allParams.filter((p) => {
      const value = searchParams.get(p);

      if (!value) {
        return false;
      }

      const dynamicSubIDParams = [
        'afftrack',
        'ascsubtag',
        'asc_source',
        'asc_refurl',
        'asc_campaign',
        'article_url',
        'article_name',
        'click_date',
        'clickref',
        'custref',
        'clickref2',
        'clickref3',
        'clickref4',
        'clickref5',
        'cmp',
        'ctc',
        'ccid',
        'customid',
        'data1',
        'data2',
        'fobs',
        'fobs2',
        'imprToken', // Maybe Amazon?
        's1',
        's2',
        'sid',
        'sid1',
        'sid2',
        'sub1',
        'sub2',
        'sub3',
        'sub4',
        'sub5',
        'sub_id',
        'sub-id',
        'subid',
        'subid1',
        'subid2',
        'subid3',
        'subid4',
        'tid',
        'u1',
        'uniqueid',
        // Skimlinks
        'xcust',
        'xjsf',
        'xs',
        'xtz',
        'jv',
        'isjs',

        'epi',
        'aff_sub',
        'aff_sub2',
        'aff_sub3',
        'aff_sub4',
        'aff_sub5',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_channel', // Added for Bankrate
        'ref_url',
        'xuuid',
        'xid'
      ];

      if (dynamicSubIDParams.indexOf(p.toLowerCase()) !== -1) {
        return true;
      }

      if (url.href.indexOf('squaremouth.com') !== -1 && p === 'tag') {
        return true;
      }

      if (
        url.href.indexOf('www.cardratings.com') !== -1 &&
        ['var2', 'var3'].indexOf(p) !== -1
      ) {
        return true;
      }

      if (
        url.href.indexOf('amazon.com') !== -1 &&
        ['crid', 'dib', 'qid'].indexOf(p) !== -1
      ) {
        return true;
      }

      if (
        url.href.indexOf('https://fiona.com') === 0 &&
        p.indexOf('tag.') !== -1
      ) {
        return true;
      }

      if (url.href.indexOf('thepointsguy.com') !== -1 && p === 'cid') {
        return true;
      }

      if (url.href.indexOf('smartasset.com') !== -1 && p === 'cid') {
        return true;
      }

      if (url.href.indexOf('www.credible.com') !== -1 && p === 'utm_campaign') {
        return true;
      }

      // Remove GGR's subId3
      if (p === 'subId3' && ['yes', 'no'].indexOf(value) !== -1) {
        return true;
      }

      // And segment IDs
      if (value.indexOf('5_o_d_') !== -1) {
        return true;
      }

      // Remove Squirrel subIds
      if (value.indexOf('squirrel-sqr') !== -1) {
        return true;
      }

      // Remove trackonomics subids from reference urls
      if (
        value.indexOf('xid:fr') !== -1 ||
        value.indexOf('xid-fr') !== -1 ||
        new RegExp(/fr\d{7,}[a-z]{3,}/).test(value)
      ) {
        return true;
      }

      // Remove params containing our amcid- which
      // may have been copy/pasted by editors and contaminates
      // our tracking
      if (value.indexOf('_amcid-') !== -1 || value.indexOf('5_o_d_') !== -1) {
        return true;
      }

      return false;
    });

    // CJ dyamic subids can be smack in the middle of the URL
    // so ignore those by slicing them out altogether
    if (url.href.indexOf('/dlg/sid/') !== -1) {
      const matched = url.href.match(/(\/sid\/[^/]+)/);
      if (matched && matched[1]) {
        return href.replace(matched[1], '');
      }
    }

    // If there were no unwantedParams, return a totally
    // untouched cleanHref unless it's a Geniuslink, then
    // return the unwrapped geniuslink
    if (unwantedParams.length === 0) {
      return isGeniuslink ? url.href : cleanHref;
    }

    // Otherwise, remove the problematic params before matching for tracking
    unwantedParams.forEach((p) => {
      url.searchParams.delete(p);
    });

    // If there is just a hanging questionmark at the end,
    // trim it out
    const hasDanglingQuestionMark =
      url.href.lastIndexOf('?') === url.href.length - 1;

    const nearFinalHref = url.toString();
    cleanHref =
      hasDanglingQuestionMark && options.trimDanglingQuestionMark
        ? nearFinalHref.substr(0, nearFinalHref.length - 1)
        : nearFinalHref;

    // Then, respect whether the original URL had a trailing slash or not
    const originalHadTrailingSlash = new RegExp(/(\/\?)/).test(href);

    return originalHadTrailingSlash && options.trimTrailingSlash
      ? withoutTrailingSlashAtAnyPosition(cleanHref)
      : cleanHref;
  } catch (_) {
    // Do nothing
  }

  // If they didn't make it to the end of the try block,
  // just return the original url
  return href;
};
