import React from 'react';
import { Check, X } from 'react-feather';
import { COLORS } from '../../domainTypes/colors';

export const BooleanComponent = ({
  value,
  trueIsGood = true,
  size = 16
}: {
  value: boolean;
  trueIsGood?: boolean;
  size?: number;
}) => {
  const colors = trueIsGood
    ? [COLORS.green.green5, COLORS.red.red5]
    : [COLORS.red.red5, COLORS.green.green5];

  return value ? (
    <Check color={colors[0]} size={size} />
  ) : (
    <X color={colors[1]} size={size} />
  );
};
