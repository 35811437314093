import { CircularProgress, Omit } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { useState } from 'react';
import { styled } from '../../emotion';
import useIsMounted from '../../hooks/useIsMounted';

export type ButtonWithPromiseProps<T> = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<T | void>;
  pending: React.ReactNode;
} & Omit<ButtonProps, 'onClick'>;

export const ButtonWithPromiseLoader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;

  svg {
    color: inherit;
  }
`;

export const ButtonWithPromise = <T extends any>(
  props: ButtonWithPromiseProps<T>
) => {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const { onClick, disabled, pending, children, startIcon, ...other } = props;
  const done = () => isMounted.current && setLoading(false);
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLoading(true);
    return onClick(e).then(done, done);
  };

  return (
    <Button
      {...other}
      disabled={disabled || loading}
      onClick={handleClick}
      startIcon={loading ? null : startIcon}
    >
      {loading ? (
        <>
          <ButtonWithPromiseLoader /> {pending}
        </>
      ) : (
        children
      )}
    </Button>
  );
};
