import { Card } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Pause, Play } from 'react-feather';
import { BooleanComponent } from '../../../../../../components/Boolean';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { CopyId } from '../../../../../../components/CopyButton';
import { ISegmentCampaign } from '../../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../../domainTypes/document';
import { campaignStatusTitle } from '../../../../../../features/Campaigns/service/segment-campaign';
import { pauseSegmentCampaign } from '../../../../../../features/Campaigns/service/segment-campaign-form';
import { CanvasBar } from '../../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { DataGrid } from '../../../../../components/DataGrid';
import { SpaceProfileLabel } from '../../../../../components/SpaceProfileLabel';
import { CampaignPartnerProfileLabel } from '../../../components/CampaignPartnerProfileLabel';
import { forceSyncItemsToRedis } from '../../../service';

const PauseButton = ({ campaign }: { campaign: Doc<ISegmentCampaign> }) => {
  return campaign.data.paused ? (
    <ButtonWithPromise
      variant="contained"
      color="primary"
      startIcon={<Play size={16} />}
      pending={'Unpausing...'}
      onClick={async () => {
        await pauseSegmentCampaign(campaign.data.spaceId, campaign.id, false);
      }}
    >
      Unpause
    </ButtonWithPromise>
  ) : (
    <ButtonWithPromise
      variant="contained"
      color="primary"
      startIcon={<Pause size={16} />}
      pending={'Pausing...'}
      onClick={async () => {
        await pauseSegmentCampaign(campaign.data.spaceId, campaign.id, true);
      }}
    >
      Pause
    </ButtonWithPromise>
  );
};

export const General = ({ campaign }: { campaign: Doc<ISegmentCampaign> }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Section>
      <CanvasBar>
        <div>General</div>
      </CanvasBar>
      <Card>
        <FlexContainerVertical spacing={3} fullWidth>
          <DataGrid
            items={[
              ['ID', <CopyId id={campaign.id} />],
              ['Name', campaign.data.name],
              ['Status', campaignStatusTitle(campaign.data.status)],
              [
                'Paused',
                <BooleanComponent
                  value={campaign.data.paused}
                  trueIsGood={false}
                />
              ],
              ['Space', <SpaceProfileLabel spaceId={campaign.data.spaceId} />],
              ['Partner', <CampaignPartnerProfileLabel campaign={campaign} />]
            ]}
          />

          <FlexContainer fullWidth justifyContent="flex-start">
            <PauseButton campaign={campaign} />
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending={'Syncing...'}
              onClick={async () => {
                await forceSyncItemsToRedis(campaign.id);
                enqueueSnackbar(
                  'Synced items to Redis - please reload its data',
                  {
                    variant: 'success'
                  }
                );
              }}
            >
              Force sync items to redis
            </ButtonWithPromise>
          </FlexContainer>
        </FlexContainerVertical>
      </Card>
    </Section>
  );
};
