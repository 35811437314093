import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { CheckboxSimple } from '../../../components/CheckboxSimple';
import { Loader } from '../../../components/Loader';
import { useMaintenanceSettings } from '../../../features/Maintenance/service';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { setDoc, updateDataInDoc } from '../../../services/db';

export const PageMaintenanceSettings = () => {
  const [
    maintenanceSettings,
    maintenanceSettingsLoading
  ] = useMaintenanceSettings();
  return (
    <Page width="L">
      <Section>
        <Card>
          <CardContent>
            {maintenanceSettingsLoading && <Loader height={50} />}
            {maintenanceSettings && (
              <CheckboxSimple
                label="Show Maintenance page to everyone"
                checked={maintenanceSettings.data.enabled}
                onChange={(nextChecked) => {
                  setDoc(
                    updateDataInDoc(maintenanceSettings, {
                      enabled: nextChecked
                    })
                  );
                }}
              />
            )}
          </CardContent>
        </Card>
      </Section>
    </Page>
  );
};
