import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { AdvertiserAppSpace } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Section } from '../../../../../../layout/Section';
import { FS } from '../../../../../../versions';
import { FirestoreLink } from '../../../../../components/FirestoreLink';
import { Json } from '../../../../../components/Json';

export const Data: React.FC<{
  space: Doc<AdvertiserAppSpace>;
}> = ({ space }) => {
  return (
    <Section>
      <CanvasBar>
        <div>Data</div>
        <FirestoreLink
          doc={{ collection: FS.advertiserSpaces, id: space.id }}
        />
      </CanvasBar>
      <Card>
        <CardContent>
          <Json
            data={space}
            shouldCollapse={({ namespace }) =>
              namespace.length > 3 && namespace[2] === 'permissions'
            }
          />
        </CardContent>
      </Card>
    </Section>
  );
};
