import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import firebase from 'firebase/app';
import { get, keyBy, orderBy, set } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, PlusCircle, XCircle } from 'react-feather';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../components/AdditionalActionsMenu';
import {
  AutocompleteMultiFreeSolo,
  AutocompleteSingle,
  AutocompleteSingleFreeSolo
} from '../../../components/Autocomplete';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound';
import { SomethingWentWrong } from '../../../components/SomethingWentWrong';
import { Doc } from '../../../domainTypes/document';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { Timestamp } from '../../../domainTypes/time';
import { styled } from '../../../emotion';
import { FlexContainer, FlexItem } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useQueryParam } from '../../../routes';
import { store } from '../../../services/db';
import {
  CollectionListener,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  DocumentListener,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import { traverseTimestampAware } from '../../../services/objects';
import { useTheme } from '../../../themes';
import { Json } from '../../components/Json';

const DocumentContainer = styled('div')((p) => ({
  marginBottom: p.theme.spacing(1)
}));

const DocumentHeader = styled(FlexContainer)((p) => ({
  cursor: 'pointer'
}));

const withTimestampsAsDates = (d: any) => {
  const toChange: [(string | number)[], Timestamp][] = [];
  traverseTimestampAware(d, (path, value) => {
    if (value instanceof firebase.firestore.Timestamp) {
      toChange.push([path, value]);
    }
  });
  return toChange.reduce<any>(
    (m, [path, value]) => set(m, path, value.toDate()),
    d
  );
};

export const Document = ({
  d,
  actions,
  i
}: {
  d: Doc<any>;
  actions?: CollectionDefinition['actions'];
  i?: number;
}) => {
  const [open, setOpen] = useState(true);
  const x = useMemo(() => withTimestampsAsDates(d), [d]);
  const options = useMemo(() => (actions ? actions(x) : undefined), [
    x,
    actions
  ]);
  return (
    <>
      <DocumentContainer>
        <DocumentHeader
          justifyContent="space-between"
          fullWidth
          onClick={() => setOpen((x) => !x)}
          role="button"
        >
          <strong>
            {i !== undefined && `${i}. `}
            {d.collection} - {d.id}
          </strong>

          <FlexContainer justifyContent="flex-end">
            {options && <AdditionalActionsMenu options={options} />}
            <IconButton>
              {open && <ChevronUp size={16} />}
              {!open && <ChevronDown size={16} />}
            </IconButton>
          </FlexContainer>
        </DocumentHeader>
        {open && <Json data={x} />}
      </DocumentContainer>
    </>
  );
};

const CollRenderer = ({
  d,
  actions,
  sorting
}: {
  d: CollectionListener<any>;
  actions?: CollectionDefinition['actions'];
  sorting: {
    field: string;
    dir: 'asc' | 'desc';
  };
}) => {
  const [value, loading, error] = useCollectionListener(d);
  if (loading) {
    return <Loader height={300} />;
  }
  if (error) {
    console.log('ERROR', error);
    return <SomethingWentWrong height={300} />;
  }
  if (value && !value.length) {
    return <NotFound height={300} />;
  }
  if (value) {
    const sortedDocs = sorting.field
      ? orderBy(
          value,
          (v) => {
            const sortVal = get(v.data, sorting.field);
            if (sortVal instanceof firebase.firestore.Timestamp) {
              return sortVal.toMillis();
            }
            return sortVal;
          },
          sorting.dir
        )
      : value;
    console.log('DOCS', sortedDocs);
    return (
      <>
        <FlexContainer fullWidth justifyContent="flex-end">
          <Typography variant="caption">
            Total documents found: {sortedDocs.length}
          </Typography>
        </FlexContainer>
        {sortedDocs.map((v, i) => (
          <Document key={v.id} d={v} actions={actions} i={i} />
        ))}
      </>
    );
  }

  return null;
};

type Operator =
  | '<'
  | '<='
  | '=='
  | '>='
  | '>'
  | 'in'
  | 'not-in'
  | 'array-contains'
  | 'array-contains-any';

type WhereInstruction = {
  field: string;
  op: Operator;
  value: any;
};

const OPERATORS: Operator[] = [
  '<',
  '<=',
  '==',
  '>=',
  '>',
  'in',
  'not-in',
  'array-contains',
  'array-contains-any'
];

const ARRAY_OPERATORS: Operator[] = ['in', 'not-in', 'array-contains-any'];

const isArrayOperator = (op: Operator) => ARRAY_OPERATORS.includes(op);

const EMPTY_WHERE_INSTRUCTION = (): WhereInstruction => ({
  field: '',
  op: '==',
  value: ''
});

const QueryBuilderContainer = styled('div')((p) => ({
  minWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: p.theme.spacing(0.5)
}));

const QueryBuilderRow = ({
  value,
  onChange,
  autoFocus,
  knownFields = EMPTY_ARR
}: {
  value: WhereInstruction;
  onChange: (nextValue: WhereInstruction | null) => void;
  autoFocus?: boolean;
  knownFields?: FieldDefinition[];
}) => {
  const fields = useMemo(() => knownFields.map((f) => f.name), [knownFields]);
  const fieldsByName = useMemo(() => keyBy(knownFields, (f) => f.name), [
    knownFields
  ]);
  const { field } = value;
  const values = useMemo(() => fieldsByName[field]?.values || EMPTY_ARR, [
    fieldsByName,
    field
  ]);

  const theme = useTheme();
  return (
    <FlexContainer spacing={0} fullWidth alignItems="flex-start">
      <AutocompleteSingleFreeSolo
        label="Field"
        fullWidth
        value={field}
        autoFocus={autoFocus}
        options={fields}
        onChange={(nextField) =>
          onChange({
            ...value,
            field: nextField
          })
        }
        disableClearable
      />
      <AutocompleteSingle
        value={value.op}
        onChange={(nextOp) => {
          if (!nextOp) {
            return;
          }
          let nextValue = value.value;
          if (isArrayOperator(value.op) && !isArrayOperator(nextOp)) {
            if (Array.isArray(nextValue)) {
              // should always be true
              nextValue = nextValue.length ? nextValue[0] : '';
            }
          }
          if (!isArrayOperator(value.op) && isArrayOperator(nextOp)) {
            if (!Array.isArray(nextValue)) {
              // should always be true
              nextValue = nextValue === '' ? [] : [nextValue];
            }
          }
          return nextOp && onChange({ ...value, op: nextOp, value: nextValue });
        }}
        options={OPERATORS}
        style={{ minWidth: 120 }}
        disableClearable
      />
      {Array.isArray(value.value) ? (
        <AutocompleteMultiFreeSolo
          label="Value"
          fullWidth
          value={value.value}
          options={values}
          onChange={(nextValue) =>
            onChange({
              ...value,
              value: nextValue
            })
          }
          disableClearable
        />
      ) : (
        <AutocompleteSingleFreeSolo
          label="Value"
          fullWidth
          value={value.value}
          options={values}
          onChange={(nextValue) =>
            onChange({
              ...value,
              value: nextValue
            })
          }
          disableClearable
        />
      )}
      <IconButton
        style={{ marginLeft: theme.spacing(1), alignSelf: 'center' }}
        onClick={() => onChange(null)}
      >
        <XCircle size={16} />
      </IconButton>
    </FlexContainer>
  );
};

const QueryBuilder = ({
  qs,
  onChange,
  knownFields
}: {
  qs: WhereInstruction[];
  onChange: (nextQs: WhereInstruction[]) => void;
  knownFields?: FieldDefinition[];
}) => {
  const initialRender = useRef(true);
  useEffect(() => {
    initialRender.current = false;
  }, []);
  return (
    <QueryBuilderContainer>
      {qs.map((q, i) => (
        <QueryBuilderRow
          key={i}
          value={q}
          autoFocus={!initialRender.current && i === qs.length - 1}
          onChange={(nextQ) => {
            const nextQs = [...qs];
            if (nextQ) {
              nextQs[i] = nextQ;
            } else {
              nextQs.splice(i, 1);
            }

            onChange(nextQs);
          }}
          knownFields={knownFields}
        />
      ))}
      <Button
        color="primary"
        startIcon={<PlusCircle size={16} />}
        onClick={() => {
          onChange([...qs, EMPTY_WHERE_INSTRUCTION()]);
        }}
      >
        Add clause
      </Button>
    </QueryBuilderContainer>
  );
};

export const CollMode = ({
  modeSwitch,
  collectionAutocomplete,
  state,
  setState,
  collectionsById
}: {
  modeSwitch: React.ReactNode;
  collectionAutocomplete: React.ReactNode;
  state: QueryParams;
  setState: (nextState: QueryParams) => void;
  collectionsById: { [name: string]: CollectionDefinition };
}) => {
  const [collListener, setCollListener] = useState<CollectionListener<
    any
  > | null>(null);

  return (
    <>
      <Section>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            if (state.collection && state.qs.length) {
              const validQs = state.qs.filter((q) => !!q.field);
              let query: firebase.firestore.Query = store().collection(
                state.collection
              );
              for (const q of validQs) {
                query = query.where(q.field, q.op, q.value);
              }
              query = query.limit(state.limit);
              const next = new CollectionListener(query);
              setCollListener((prev) =>
                prev && prev.isQueryEqual(next) ? prev : next
              );
            }
          }}
        >
          <FlexContainer alignItems="flex-start" marginBottom={2}>
            {modeSwitch}
            {collectionAutocomplete}
            <QueryBuilder
              qs={state.qs}
              onChange={(nextQs) => setState({ ...state, qs: nextQs })}
              knownFields={collectionsById[state.collection]?.fields}
            />
            <TextField
              variant="outlined"
              label="Limit"
              type="number"
              value={state.limit}
              inputProps={{ min: 0, max: 1000 }}
              style={{ minWidth: 80 }}
              onChange={(ev) => setState({ ...state, limit: +ev.target.value })}
            />
            <Button variant="contained" color="primary" type="submit">
              Get
            </Button>
          </FlexContainer>
        </form>
      </Section>

      <Section>
        {collListener && (
          <CollRenderer
            d={collListener}
            actions={collectionsById[state.collection]?.actions}
            sorting={state.sortBy}
          />
        )}
      </Section>
    </>
  );
};

const DocRenderer = ({
  d,
  actions
}: {
  d: DocumentListener<any>;
  actions?: CollectionDefinition['actions'];
}) => {
  const [value, loading, error] = useDocumentListener(d);
  if (loading) {
    return <Loader height={300} />;
  }
  if (error) {
    console.log('ERROR', error);
    return <SomethingWentWrong height={300} />;
  }
  if (value === null) {
    return <NotFound height={300} />;
  }
  if (value) {
    console.log('DOC', value);
    return <Document d={value} actions={actions} />;
  }

  return null;
};

export const DocMode = ({
  modeSwitch,
  collectionAutocomplete,
  state,
  setState
}: {
  modeSwitch: React.ReactNode;
  collectionAutocomplete: React.ReactNode;
  state: DocParams;
  setState: (nextState: DocParams) => void;
}) => {
  const [docListener, setDocListener] = useState<DocumentListener<any> | null>(
    null
  );

  return (
    <>
      <Section>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            if (state.collection && state.id) {
              const next = new DocumentListener(
                store().collection(state.collection).doc(state.id)
              );
              setDocListener((prev) =>
                prev && prev.isEqualRef(next) ? prev : next
              );
            }
          }}
        >
          <FlexContainer marginBottom={2}>
            {modeSwitch}
            {collectionAutocomplete}
            <TextField
              variant="outlined"
              value={state.id}
              onChange={(ev) => setState({ ...state, id: ev.target.value })}
              label="ID"
              fullWidth
            />

            <Button variant="contained" color="primary" type="submit">
              Get
            </Button>
          </FlexContainer>
        </form>
      </Section>

      <Section>{docListener && <DocRenderer d={docListener} />}</Section>
    </>
  );
};

type DocParams = {
  mode: 'DOC';
  collection: string;
  id: string;
};

type QueryParams = {
  mode: 'QUERY';
  collection: string;
  qs: WhereInstruction[];
  sortBy: {
    field: string;
    dir: 'asc' | 'desc';
  };
  limit: number;
};

type Params = DocParams | QueryParams;

export type FieldDefinition = {
  name: string;
  values: string[];
};

export type CollectionDefinition = {
  name: string;
  fields: FieldDefinition[];
  actions?: (d: Doc<any>) => AdditionActionsMenuOption[];
};

export const PageFirestoreInspector = ({
  collections = EMPTY_ARR
}: {
  collections?: CollectionDefinition[];
}) => {
  const collectionOptions = useMemo(() => collections.map((c) => c.name), [
    collections
  ]);
  const collectionsById = useMemo(() => keyBy(collections, (c) => c.name), [
    collections
  ]);

  const [state, setState] = useQueryParam<Params>(
    'params',
    (p) =>
      p
        ? (JSON.parse(decodeURIComponent(p)) as Params)
        : {
            mode: 'DOC',
            collection: '',
            id: ''
          },
    (p) => encodeURIComponent(JSON.stringify(p))
  );
  const setMode = (mode: 'DOC' | 'QUERY') =>
    setState(
      mode === 'DOC'
        ? {
            mode: 'DOC',
            collection: state.collection,
            id: ''
          }
        : {
            mode: 'QUERY',
            collection: state.collection,
            qs: [EMPTY_WHERE_INSTRUCTION()],
            sortBy: {
              field: '',
              dir: 'asc'
            },
            limit: 50
          }
    );

  const modeSwitch = (
    <ToggleButtonGroup
      exclusive
      value={state.mode}
      onChange={(ev, v: 'DOC' | 'QUERY') => setMode(v)}
      size="small"
    >
      <ToggleButton value="DOC" aria-label="Document by ID">
        Doc
      </ToggleButton>
      <ToggleButton value="QUERY" aria-label="Build a query ">
        Query
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const collectionAutocomplete = (
    <QueryBuilderContainer>
      <AutocompleteSingle
        value={state.collection || null}
        onChange={(x) => setState({ ...state, collection: x || '' })}
        options={collectionOptions}
        label="Collection"
        fullWidth
        autoFocus
      />
      {state.mode === 'QUERY' && state.collection && (
        <FlexContainer fullWidth>
          <FlexItem flex={1}>
            <AutocompleteSingleFreeSolo
              label="Sort by"
              fullWidth
              value={state.sortBy.field}
              options={
                collectionsById[state.collection]?.fields.map((f) => f.name) ||
                []
              }
              onChange={(nextField) =>
                setState({
                  ...state,
                  sortBy: {
                    ...state.sortBy,
                    field: nextField
                  }
                })
              }
              disableClearable
            />
          </FlexItem>
          <Select
            variant="outlined"
            value={state.sortBy.dir}
            onChange={(ev) =>
              setState({
                ...state,
                sortBy: {
                  ...state.sortBy,
                  dir: ev.target.value as 'asc' | 'desc'
                }
              })
            }
          >
            <MenuItem value={'asc'}>ASC</MenuItem>
            <MenuItem value={'desc'}>DESC</MenuItem>
          </Select>
        </FlexContainer>
      )}
    </QueryBuilderContainer>
  );
  return (
    <Page width="FULL">
      {state.mode === 'DOC' && (
        <DocMode
          state={state}
          setState={setState}
          modeSwitch={modeSwitch}
          collectionAutocomplete={collectionAutocomplete}
        />
      )}
      {state.mode === 'QUERY' && (
        <CollMode
          state={state}
          setState={setState}
          modeSwitch={modeSwitch}
          collectionAutocomplete={collectionAutocomplete}
          collectionsById={collectionsById}
        />
      )}
    </Page>
  );
};
