import { Card } from '@material-ui/core';
import React from 'react';
import { MarketplaceAdvertiser } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Section } from '../../../../../../layout/Section';
import { Json } from '../../../../../components/Json';

export const Data = ({ d }: { d: Doc<MarketplaceAdvertiser> }) => {
  return (
    <Section>
      <CanvasBar>
        <div>Data</div>
      </CanvasBar>
      <Card>
        <Json data={d} />
      </Card>
    </Section>
  );
};
