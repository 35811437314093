import { Typography, TypographyProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { Image } from 'react-feather';
import { MarketplaceAdvertiserProfilePublic } from '../../domainTypes/advertiserApp';
import { css } from '../../emotion';
import { FlexContainer } from '../../layout/Flex';
import { useCurrentUser } from '../../services/currentUser';
import { useMappedLoadingValue } from '../../services/db';
import { useMarketplaceAdvertiserProfilesPublic } from '../../services/marketplaceAdvertisers';

export const ProfileLabel = <
  Profile extends {
    name: string;
    logoUrl: string;
  }
>({
  profile,
  shape = 'square',
  size = 24,
  variant = 'normal',
  nameVariant = 'body2'
}: {
  profile: Profile;
  shape?: 'square' | 'circle';
  size?: number;
  variant?: 'strong' | 'normal';
  nameVariant?: TypographyProps['variant'];
}) => {
  const borderRadius = shape === 'circle' ? '50%' : 5;
  return (
    <FlexContainer spacing={1}>
      {profile.logoUrl ? (
        <img
          src={profile.logoUrl}
          alt={profile.name}
          className={css(() => ({
            width: size,
            height: size,
            borderRadius
          }))}
        />
      ) : (
        <Image size={size || 24} color="#828282" />
      )}
      <Typography variant={nameVariant}>
        {variant === 'strong' ? <strong>{profile.name}</strong> : profile.name}
      </Typography>
    </FlexContainer>
  );
};

export const LazyProfileLabel: React.FC<{
  maId: string;
  shape?: 'square' | 'circle';
  size?: number;
  variant?: 'strong' | 'normal';
  nameVariant?: TypographyProps['variant'];
}> = ({ maId, shape = 'square', size = 24, ...props }) => {
  const { space } = useCurrentUser();
  const findProfile = useCallback(
    (profiles: MarketplaceAdvertiserProfilePublic[]) => {
      return profiles.find((profile) => profile.id === maId);
    },
    [maId]
  );
  const [profile] = useMappedLoadingValue(
    useMarketplaceAdvertiserProfilesPublic(space.id),
    findProfile,
    true
  );

  if (!profile) {
    return (
      <FlexContainer>
        <Skeleton
          variant={shape === 'square' ? 'rect' : shape}
          width={size}
          height={size}
        />
        <Skeleton variant="text" height={size} width={150} />
      </FlexContainer>
    );
  }
  return (
    <ProfileLabel profile={profile} shape={shape} size={size} {...props} />
  );
};
