import {
  marketplaceAdvertisersCollection,
  toMarketplaceAdvertiserDoc
} from '../../../advertiser-app/services/marketplaceAdvertiser';
import { MarketplaceAdvertiser } from '../../../domainTypes/advertiserApp';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';

const marketplaceAdvertiserStore = createCollectionListenerStore<
  MarketplaceAdvertiser
>(
  (spaceId) =>
    new CollectionListener(
      spaceId === ''
        ? marketplaceAdvertisersCollection()
        : marketplaceAdvertisersCollection().where(
            'advertiserAppSpaces',
            'array-contains',
            spaceId
          ),
      toMarketplaceAdvertiserDoc
    )
);

export const _useMarketplaceAdvertisers = () => {
  return useCollectionListener(marketplaceAdvertiserStore(''));
};

export const _useMarketplaceAdvertisersForSpace = (spaceId: string) => {
  return useCollectionListener(marketplaceAdvertiserStore(spaceId));
};
