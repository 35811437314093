import { difference } from 'lodash';
import md5 from 'md5';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment } from '../../../../../services/time';
import { toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const removeEuros = (str: string) => {
  return str.replace('€ ', '');
};

const toStatus = (bolStatus: string) => {
  if (bolStatus === 'Geaccepteerd') {
    return 'Final';
  }
  if (bolStatus === 'Geweigerd') {
    return 'Cancelled';
  }
  if (bolStatus === 'Open') {
    return 'Pending';
  }
  return 'Unknown';
};

export const BOL: IFileReportHandler<string[]> = {
  type: 'XLSX',
  partnerKey: 'bol',
  parser: {
    name: 'Fee earnings report',
    csvHeaders: ['OrderDate'],
    parseOptions: { dynamicTyping: false },
    matches: (rows) => {
      const firstRows = [
        'OrderDate',
        'OrderDateTime',
        'OrderId',
        'OrderItemId',
        'ProductTitle',
        'ProductId',
        'Quantity',
        'ChunkName',
        'SiteName',
        'SiteCode',
        'FrameType',
        'Name',
        'SubId',
        'PriceExclVat',
        'PriceInclVat',
        'CommissionPercentage',
        'Commission',
        'Status',
        'StatusFinal',
        'ApprovedForPayment'
      ];
      return difference(rows[0], firstRows).length === 0;
    },
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      const currency: CurrencyCode = 'EUR';
      return rows.slice(1).map((row) => {
        const [
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          OrderDate,
          OrderDateTime,
          OrderId,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          OrderItemId,
          ProductTitle,
          ProductId,
          Quantity,
          ChunkName,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          SiteName,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          SiteCode,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          FrameType,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Name,
          SubId,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          PriceExclVat,
          PriceInclVat,
          CommissionPercentage,
          Commission,
          Status,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          StatusFinal,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ApprovedForPayment
        ] = row;

        const saleId = (() => {
          const slug = ProductTitle.toLowerCase().replace(/[^\w]/gi, '');
          return md5(`${OrderId}-${slug}`);
        })();
        const saleDate = moment.tz(OrderDateTime, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
        const trackingLabel = SubId;
        const trackingId = getTrackingId(trackingLabel, space);
        const status = toStatus(Status);
        const quantity = parseInt(Quantity, 10);
        const commissionPercent = CommissionPercentage / 100;
        const commission = toCents(removeEuros(Commission));
        const price = toCents(removeEuros(PriceInclVat));

        const sale: ISale = {
          saleId,
          orderId: OrderId,
          saleDate: fromMoment(saleDate),
          reportId,
          integrationId,
          trackingId,
          trackingLabel: trackingLabel || '',
          origin: null,
          completionDate: fromMoment(saleDate),
          status,
          partnerKey,
          partnerProductName: ProductTitle,
          partnerProductId: ProductId,
          payoutId: null,
          payoutDate: null,
          payoutStatus: null,
          lastModified: null,
          coupon: '',
          saleType: 'unknown',
          amount: {
            currency,
            revenue: null,
            price,
            commission
          },
          commissionPercent,
          quantity,
          advertiserId: partnerKey,
          advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name,
          metadata: {
            category: ChunkName
          }
        };

        if (sale.metadata && SubId) {
          sale.metadata.subId1 = SubId;
        }

        return sale;
      });
    }
  }
};
