import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { UNKNOWN, Unknown } from '../../domainTypes/analytics';
import { styled } from '../../emotion';
import { COUNTRY_ABBREVIATIONS } from '../../domainTypes/country';

type Props = {
  code: string | Unknown; // 2-letter country code
};

const FlagImg = styled('img')`
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const UnknownCountry = styled('div')`
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  margin-right: ${(p) => p.theme.spacing(1)}px;
  background-color: #fff;
`;

export const countryCodeToUnicodeFlag = (code: string) =>
  code === UNKNOWN || code === 'ZZ' ? (
    <UnknownCountry />
  ) : (
    <FlagImg src={`/images/flags/${code.toLowerCase()}.png`} />
  );

export const Flag: React.FC<Props> = ({ code }) => {
  const flag = countryCodeToUnicodeFlag(code);
  const name = COUNTRY_ABBREVIATIONS[code] || '';
  return (
    <Tooltip title={name}>
      <span>{flag}</span>
    </Tooltip>
  );
};

const FlagWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const FlagWithLabel: React.FC<Props> = ({ code }) => {
  const flag = countryCodeToUnicodeFlag(code);
  const name = COUNTRY_ABBREVIATIONS[code] || code;

  return (
    <FlagWrapper>
      {flag}
      <Typography variant="body2" component="span">
        {name}
      </Typography>
    </FlagWrapper>
  );
};

export const FlagWithLabelForFilterChip: React.FC<Props> = ({ code }) => {
  const flag = countryCodeToUnicodeFlag(code);
  const name = COUNTRY_ABBREVIATIONS[code] || code;

  return (
    <FlagWrapper style={{ marginLeft: `4px` }}>
      {flag}
      <Typography variant="body2" component="span">
        <strong> {name}</strong>
      </Typography>
    </FlagWrapper>
  );
};
