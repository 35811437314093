import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

export const IsoDate = ({
  d,
  highlightTime = false
}: {
  d?: string | null;
  highlightTime?: boolean;
}) => {
  if (!d) {
    return <span>-</span>;
  }
  const m = moment(d);
  const date = m.format('YYYY-MM-DD');
  const time = m.format('HH:mm');

  return (
    <Tooltip title={d}>
      <span>
        {date} {highlightTime ? <strong>{time}</strong> : time}
      </span>
    </Tooltip>
  );
};
