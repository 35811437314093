import { Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { toLogLink } from '../../../services/logging';
import { enqueueCloudTask } from '../../services/cloudTasks';

export const PageCloudTasks = () => {
  const [taskName, setTaskName] = useState('');
  const [data, setData] = useState('{\n  \n}');
  const [opts, setOpts] = useState('{\n  "dispatchDeadlineSeconds": 1800\n}');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Section>
        <FlexContainerVertical fullWidth spacing={2}>
          <TextField
            label="Task (CF name)"
            fullWidth
            spellCheck="false"
            value={taskName}
            onChange={(ev) => setTaskName(ev.target.value)}
            variant="outlined"
          />
          <TextField
            label="Data (JSON)"
            key="data"
            fullWidth
            spellCheck={false}
            inputProps={{
              style: {
                fontFamily: 'monospace'
              }
            }}
            value={data}
            onChange={(ev) => {
              setData(ev.target.value);
            }}
            multiline
            rows={15}
            variant="outlined"
          />
          <TextField
            label="Opts (JSON)"
            key="opts"
            fullWidth
            spellCheck={false}
            inputProps={{
              style: {
                fontFamily: 'monospace'
              }
            }}
            value={opts}
            onChange={(ev) => {
              setOpts(ev.target.value);
            }}
            multiline
            rows={10}
            variant="outlined"
          />
          <FlexContainer justifyContent="flex-end" fullWidth>
            <Button
              color="primary"
              disabled={!taskName}
              href={toLogLink(
                {},
                {
                  'resource.labels.service_name': taskName.toLowerCase()
                }
              )}
              target="_blank"
            >
              Show logs for task you're enqueuing
            </Button>
            <Button
              color="primary"
              href={toLogLink(
                {},
                {
                  'resource.labels.function_name': 'cloudTasks-enqueueCloudTask'
                }
              )}
              target="_blank"
            >
              Show logs for enqueue fn
            </Button>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Enqueuing..."
              onClick={async () => {
                try {
                  await enqueueCloudTask({
                    taskName: taskName.trim(),
                    data: data ? JSON.parse(data) : undefined,
                    opts: opts ? JSON.parse(opts) : undefined
                  });
                  enqueueSnackbar('Task enqueued');
                } catch (err) {
                  console.log('ERR', err);
                  enqueueSnackbar(`Something went wrong - ${err?.message}`, {
                    variant: 'error'
                  });
                }
              }}
            >
              Enqueue
            </ButtonWithPromise>
          </FlexContainer>
        </FlexContainerVertical>
      </Section>
    </Page>
  );
};
