import { Dialog, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { useMemo, useState } from 'react';
import { toProductCatalogDescriptorDoc } from '../../../../../advertiser-app/features/ProductCatalog/pages/Connections/service';
import { Badge } from '../../../../../components/Badge';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { CheckboxSimple } from '../../../../../components/CheckboxSimple';
import { CopyId } from '../../../../../components/CopyButton';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  SearchInput,
  toSearchRegexp
} from '../../../../../components/SearchInput';
import { IColumn } from '../../../../../components/Table/Column';
import { IsoDate } from '../../../../../components/Time/IsoDate';
import { COLORS } from '../../../../../domainTypes/colors';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import { NOOP } from '../../../../../domainTypes/emptyConstants';
import {
  ProductCatalogDescriptor,
  ProductCatalogVisibility,
  ProductCatalogVisibilityList
} from '../../../../../domainTypes/productCatalog';
import { styled } from '../../../../../emotion';
import { useModel } from '../../../../../hooks/useModel';
import { CanvasBar } from '../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import { store, updateDoc } from '../../../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../../../services/firecache/documentListener';
import { FirestoreLink } from '../../../../components/FirestoreLink';
import { Json } from '../../../../components/Json';

const collection = () => store().collection('productCatalogDescriptorsV1');
const descriptorStore = createCollectionListenerStore<ProductCatalogDescriptor>(
  () => new CollectionListener(collection(), toProductCatalogDescriptorDoc)
);
const useDescriptors = () => {
  return useCollectionListener(descriptorStore(''));
};

const toVisibilityListDoc = generateToDocFn<ProductCatalogVisibilityList>();

const visibilityListStore = createDocumentListenerGetter(
  (id) => store().collection('productCatalogVisibilityListsV1').doc(id),
  toVisibilityListDoc,
  (id) => ({ id, private: [] })
);

const useVisibilityList = (id: string = 'global') => {
  return useDocumentListener(visibilityListStore(id));
};

const Dot = styled('div')<{ color: string }>((p) => ({
  backgroundColor: p.color,
  width: 16,
  height: 16,
  borderRadius: '3px'
}));

type D = Doc<ProductCatalogDescriptor>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'disabled',
    head: () => '',
    cell: (d) =>
      d.data.disabled ? (
        <Tooltip title="Disabled">
          <Dot color={'#ddd'} />
        </Tooltip>
      ) : (
        <Tooltip title="Enabled">
          <Dot color={COLORS.green.green5} />
        </Tooltip>
      ),
    align: 'left',
    width: 50,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <CopyId id={d.data.catalogId} />,
    align: 'left',
    width: 124,
    flexGrow: 0
  },
  {
    key: 'visibility',
    head: () => 'Visibility',
    cell: (d) =>
      d.data.visibility === ProductCatalogVisibility.PUBLIC ? (
        <Badge color={COLORS.green.green5} bgColor={COLORS.green.green2}>
          Public
        </Badge>
      ) : d.data.visibility === ProductCatalogVisibility.PRIVATE ? (
        <Badge color={COLORS.gold.gold5} bgColor={COLORS.gold.gold2}>
          Private
        </Badge>
      ) : null,
    align: 'left',
    width: 100,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'retailerName',
    head: () => 'Retailer',
    cell: (d) => d.data.retailerName,
    align: 'left',
    width: 75,
    flexGrow: 2,
    sortable: true
  },
  {
    key: 'handlerName',
    head: () => 'Handler',
    cell: (d) => d.data.handlerName,
    align: 'left',
    width: 140,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'source',
    head: () => 'Source',
    cell: (d) => d.data.source.type,
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'lastModified',
    head: () => 'Last Modified',
    cell: (d) => <IsoDate d={d.data.lastModified} />,
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'lastRetrievalAttempt',
    head: () => 'Last Attempt',
    cell: (d) => <IsoDate d={d.data.lastRetrievalAttempt} />,
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'lastRetrievalSuccess',
    head: () => 'Last Success',
    cell: (d) => <IsoDate d={d.data.lastRetrievalSuccess} />,
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  }
];

const rowToKey = (d: D) => d.id;

const SORTERS: ItemSorters<D> = {
  disabled: {
    key: 'disabled',
    items: {
      sort: (d) => (d.data.disabled ? 1 : 0),
      dir: 'asc'
    }
  },
  visibility: {
    key: 'visibility',
    items: {
      sort: (d) => d.data.visibility,
      dir: 'asc'
    }
  },
  id: {
    key: 'id',
    items: {
      sort: (d) => d.data.catalogId,
      dir: 'asc'
    }
  },
  retailerName: {
    key: 'retailerName',
    items: {
      sort: (d) => d.data.retailerName,
      dir: 'asc'
    }
  },
  handlerName: {
    key: 'handlerName',
    items: {
      sort: (d) => d.data.handlerName,
      dir: 'asc'
    }
  },
  lastModified: {
    key: 'lastModified',
    items: {
      sort: (d) =>
        d.data.lastModified
          ? new Date(d.data.lastModified).valueOf()
          : -Infinity,
      dir: 'desc'
    }
  },
  source: {
    key: 'source',
    items: {
      sort: (d) => d.data.source.type,
      dir: 'asc'
    }
  },
  lastRetrievalSuccess: {
    key: 'lastRetrievalSuccess',
    items: {
      sort: (d) =>
        d.data.lastRetrievalSuccess
          ? new Date(d.data.lastRetrievalSuccess).valueOf()
          : -Infinity,
      dir: 'desc'
    }
  },
  lastRetrievalAttempt: {
    key: 'lastRetrievalAttempt',
    items: {
      sort: (d) =>
        d.data.lastRetrievalAttempt
          ? new Date(d.data.lastRetrievalAttempt).valueOf()
          : -Infinity,
      dir: 'desc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.retailerName;

const DescriptorDialog = ({
  d,
  onClose
}: {
  d: Doc<ProductCatalogDescriptor>;
  onClose: () => void;
}) => {
  console.log(d.data.visibility);
  const [model, setModel] = useModel(d.data);
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {d.data.retailerName} - {d.data.catalogId}
      </DialogTitle>
      <DialogContent>
        <FlexContainerVertical fullWidth spacing={3}>
          <FlexContainerVertical fullWidth>
            <CheckboxSimple
              color="primary"
              checked={!model.disabled}
              onChange={(nextChecked) =>
                setModel({ ...model, disabled: !nextChecked })
              }
              label="Active"
            />
            <CheckboxSimple
              color="primary"
              checked={model.visibility === ProductCatalogVisibility.PUBLIC}
              onChange={(nextChecked) =>
                setModel({
                  ...model,
                  visibility: nextChecked
                    ? ProductCatalogVisibility.PUBLIC
                    : ProductCatalogVisibility.PRIVATE
                })
              }
              label="Publicly visible"
            />

            <ButtonWithPromise
              color="primary"
              variant="contained"
              disabled={isEqual(d.data, model)}
              pending="Saving..."
              onClick={async () => {
                try {
                  await updateDoc(d, () => ({
                    disabled: model.disabled,
                    visibility: model.visibility
                  }));
                } catch (e) {
                  console.error('Failed to update descriptor', e);
                }
              }}
            >
              Save
            </ButtonWithPromise>
          </FlexContainerVertical>
          <FlexContainerVertical fullWidth>
            <FlexContainer justifyContent="flex-end" fullWidth>
              <FirestoreLink doc={d} />
            </FlexContainer>
            <Json data={d} fullWidth />
          </FlexContainerVertical>
        </FlexContainerVertical>
      </DialogContent>
    </Dialog>
  );
};

export const ProductCatalogDescriptorsTable = ({
  rows,
  sorter = DEFAULT_SORTER,
  sortDirection = DEFAULT_SORTER.items.dir,
  setSort = NOOP
}: {
  rows: Doc<ProductCatalogDescriptor>[];
  sorter?: ItemSorter<D>;
  sortDirection?: 'asc' | 'desc' | undefined;
  setSort?: (args: [ItemSorter<D>, 'asc' | 'desc' | undefined]) => void;
}) => {
  const [selectedId, setSelectedId] = useState('');
  const selected = useMemo(() => {
    return rows.find((r) => r.id === selectedId) || null;
  }, [selectedId, rows]);
  return (
    <>
      <RowsRenderer
        variant="contained"
        rows={rows}
        columns={COLUMNS}
        rowToKey={rowToKey}
        sorter={sorter || DEFAULT_SORTER}
        sortDirection={sortDirection}
        onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
        chunkSize={100}
        rootMargin="400px"
        rowHeight={ROW_HEIGHTS.dense}
        renderHead
        otherProps={undefined}
        onRowClick={(d) => setSelectedId(d.id)}
      />
      {selected && (
        <DescriptorDialog d={selected} onClose={() => setSelectedId('')} />
      )}
    </>
  );
};

export const PageProductCatalogDescriptors = () => {
  const [q, setQ] = useStringQueryParam('q');
  const [ds] = useDescriptors();

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const rows = useMemo(() => {
    if (!ds) {
      return null;
    }
    const re = toSearchRegexp(q);
    return re
      ? ds.filter(
          (d) =>
            d.data.retailerName.match(re) ||
            d.data.handlerName.match(re) ||
            d.data.catalogId.match(re)
        )
      : ds;
  }, [ds, q]);

  const [globalVisibilityList] = useVisibilityList();
  return (
    <Page width="FULL">
      <Section>
        <CanvasBar>
          <SearchInput
            value={q}
            onChange={setQ}
            placeholder="Search by catalog"
            autoFocus={true}
            width={300}
          />
        </CanvasBar>
        {!rows && <Loader height={500} />}
        {rows && (
          <ProductCatalogDescriptorsTable
            rows={rows}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
            setSort={setSort}
          />
        )}
      </Section>

      <Section>
        <CanvasBar>Global Visibility List</CanvasBar>
        <Json data={globalVisibilityList} fullWidth />
      </Section>
    </Page>
  );
};
