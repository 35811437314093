import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { Check, X } from 'react-feather';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { CopyId } from '../../../../../../components/CopyButton';
import { AdvertiserAppSpace } from '../../../../../../domainTypes/advertiserApp';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { updateDoc } from '../../../../../../services/db';
import { UserAvatarWithNameNextToItLazy } from '../../../../../components/AdminUserAvatar';
import { DataGrid } from '../../../../../components/DataGrid';

const ICON_SIZE = 16;

export const General: React.FC<{
  space: Doc<AdvertiserAppSpace>;
}> = ({ space }) => {
  return (
    <Section>
      <CanvasBar>
        <div>General</div>
      </CanvasBar>
      <Card>
        <CardContent>
          <FlexContainerVertical spacing={3} fullWidth>
            <DataGrid
              items={[
                ['ID', <CopyId id={space.id} />],
                ['Name', space.data.config.name],
                ['Currency', space.data.config.currency],
                ['Timezone', space.data.config.tz],
                ['Created at', space.data.createdAt.toDate().toISOString()],
                [
                  'Created by',
                  <UserAvatarWithNameNextToItLazy
                    userId={space.data.createdBy}
                  />
                ],
                ['', <div style={{ height: 16 }} />],
                [
                  'Active',
                  space.data.active ? (
                    <Check size={ICON_SIZE} />
                  ) : (
                    <X size={ICON_SIZE} />
                  )
                ],
                [
                  'Verified',
                  space.data.verified ? (
                    <Check size={ICON_SIZE} />
                  ) : (
                    <X size={ICON_SIZE} />
                  )
                ]
              ]}
            />

            <FlexContainer fullWidth>
              {!space.data.verified && (
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={async () =>
                    await updateDoc(space, () => ({ verified: true }))
                  }
                  pending="Verifying..."
                  size="small"
                >
                  Verify
                </ButtonWithPromise>
              )}
              {space.data.verified && (
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  onClick={async () =>
                    await updateDoc(space, () => ({ verified: false }))
                  }
                  pending="Unverifying..."
                  size="small"
                >
                  Unverify
                </ButtonWithPromise>
              )}
              {!space.data.active && (
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={async () =>
                    await updateDoc(space, () => ({ active: true }))
                  }
                  pending="Activating..."
                  size="small"
                >
                  Activate
                </ButtonWithPromise>
              )}
              {space.data.active && (
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  onClick={async () =>
                    await updateDoc(space, () => ({ active: false }))
                  }
                  pending="Deactivating..."
                  size="small"
                >
                  Deactivate
                </ButtonWithPromise>
              )}
            </FlexContainer>
          </FlexContainerVertical>
        </CardContent>
      </Card>
    </Section>
  );
};
