import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { Doc } from '../../../../../../../domainTypes/document';
import { ISpace } from '../../../../../../../domainTypes/space';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import { FS } from '../../../../../../../versions';
import { FirestoreLink } from '../../../../../../components/FirestoreLink';
import { Json } from '../../../../../../components/Json';

type Props = {
  space: Doc<ISpace>;
};

export const Data: React.FC<Props> = ({ space }) => {
  return (
    <Section>
      <CanvasBar>
        <div>Data</div>
        <FirestoreLink doc={{ collection: FS.spaces, id: space.id }} />
      </CanvasBar>
      <Card>
        <CardContent>
          <Json
            data={space}
            shouldCollapse={({ namespace }) =>
              namespace.length > 3 && namespace[2] === 'permissions'
            }
          />
        </CardContent>
      </Card>
    </Section>
  );
};
