import firebase from 'firebase/app';
import { CurrencyCode } from './currency';
import { Doc } from './document';
import { Timestamp } from './time';
import { IUser } from './user';

export enum AdvertiserSpaceScope {}

export enum AdvertiserSpaceRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN'
}
export interface AdvertiserSpacePermissions {
  users: {
    [userId: string]: {
      roles: AdvertiserSpaceRole[];
      additionalScopes: AdvertiserSpaceScope[];
    };
  };
}

export interface AdvertiserAppSpace {
  id: string;
  config: {
    currency: CurrencyCode;
    name: string;
    tz: string;
  };
  active: boolean;
  verified: boolean;
  createdBy: string;
  createdAt: Timestamp;
  permissions: AdvertiserSpacePermissions;

  marketplaceAdvertisers: string[];
}
export interface AdvertiserAppCurrenUser extends IUser {
  id: string;
  space: AdvertiserAppSpace;
  authUser: firebase.User;
  marketplaceAdvertisers: MarketplaceAdvertiser[];
}

export type AdvertiserAppSignupRequest =
  | {
      type: 'NEW_USER';
      email: string;
      password: string;
      displayName: string;
      companyName: string;
      currency: CurrencyCode;
      tz: string;
    }
  | {
      type: 'EXISTING_USER';
      uid: string;
      companyName: string;
      currency: CurrencyCode;
      tz: string;
    };

export interface IAdvertiserAppUsersPerSpace {
  users: { [id: string]: Doc<IUser> | null };
}

export interface AdvertiserAppSpaceAccessLists {
  id: string;
  catalogIds: string[];
  segmentIds: string[];
}

// Can be moved later and shared between publisher app and advertiser app
type Profile = {
  name: string;
  description: string;
  url: string;
  logoUrl: string;
};

export type MarketplaceAdvertiserProfilePublic = { id: string } & Profile;

export enum MarketplaceAdvertiserRole {
  'OWNER' = 'OWNER',
  'ADMIN' = 'ADMIN',
  'VIEWER' = 'VIEWER'
}

export enum MarketplaceAdvertiserScope {}
export interface MarketplaceAdvertiserPermissions {
  advertiserAppSpaces: {
    [advertiserAppSpaceId: string]: {
      roles: MarketplaceAdvertiserRole[];
      additionalScopes: MarketplaceAdvertiserScope[];
    };
  };
}

export enum ProductCatalogConnectionStatus {
  DRAFT = 'DRAFT',
  CONNECTED = 'CONNECTED',
  DISABLED = 'DISABLED'
}

export enum ProductCatalogConnectionType {
  CUSTOM = 'CUSTOM',
  AWIN = 'AWIN',
  CJ = 'CJ'
}

export type ProductCatalogConnectionDetailsCustom = {
  type: ProductCatalogConnectionType.CUSTOM;
};

export type ProductCatalogConnectionDetailsAwin = {
  type: ProductCatalogConnectionType.AWIN;
  awinAdvertiserId: string; // we need this to establish the connection
};

export type ProductCatalogConnectionDetailsCj = {
  type: ProductCatalogConnectionType.CJ;
  cjAdvertiserId: string; // we need this to establish the connection
};

// This is the place where we connect information from an advertiser, allowing
// us to establish a connection with their product catalog and retrieve their
// data.
// In the beginning this will be a quite manual process.
export type ProductCatalogConnectionDetails =
  | ProductCatalogConnectionDetailsAwin
  | ProductCatalogConnectionDetailsCj
  | ProductCatalogConnectionDetailsCustom;

export interface ProductCatalogConnection {
  id: string;

  name: string; // customer provided name, not unique
  status: ProductCatalogConnectionStatus;

  createdBy: string;
  createdAt: Timestamp;

  updatedAt: Timestamp;

  marketplaceAdvertiserId: string;

  details: ProductCatalogConnectionDetails;

  catalogId: string; // set by Affilimate once the connection is established
}

export interface MarketplaceAdvertiser {
  id: string;
  profile: Profile;
  advertiserAppSpaces: string[]; // we also need a flat list of these for retrieval and querying!
  permissions: MarketplaceAdvertiserPermissions;
}

export interface MarketplaceAdvertiserPermissionsForUsers {
  id: string;
  // inherited from advertiserAppSpaces
  // most permissive role wins
  users: {
    [userId: string]: {
      roles: MarketplaceAdvertiserRole[];
      additionalScopes: MarketplaceAdvertiserScope[];
    };
  };
}
