import { useEffect, useState } from 'react';

export const useModel = <T>(value: T) => {
  const tuple = useState(value);
  const setModel = tuple[1];
  useEffect(() => {
    setModel(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return tuple;
};
