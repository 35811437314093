import { compact, keyBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProductCatalogConnectionStatusBadge } from '../../../../../advertiser-app/features/ProductCatalog/pages/Connections/components/StatusBadge';
import { ProductCatalogConnectionTypeBadge } from '../../../../../advertiser-app/features/ProductCatalog/pages/Connections/components/TypeBadge';
import { AdditionalActionsMenu } from '../../../../../components/AdditionalActionsMenu';
import { CopyId } from '../../../../../components/CopyButton';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { ProfileLabel } from '../../../../../components/ProfileLabel';
import {
  SearchInput,
  toSearchRegexp
} from '../../../../../components/SearchInput';
import { IColumn } from '../../../../../components/Table/Column';
import {
  MarketplaceAdvertiser,
  ProductCatalogConnection
} from '../../../../../domainTypes/advertiserApp';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import {
  store,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../../services/firecache/collectionListener';
import { ConnectionDialog } from '../../../MarketplaceAdvertisers/pages/Details/components/Catalogs';
import { _useMarketplaceAdvertisers } from '../../../MarketplaceAdvertisers/service';

const toProductCatalogConnectionDoc = generateToDocFn<
  ProductCatalogConnection
>();

const collection = () => store().collection('productCatalogConnectionsV1');
const connectionStore = createCollectionListenerStore<ProductCatalogConnection>(
  () => new CollectionListener(collection(), toProductCatalogConnectionDoc)
);

const useConnections = () => {
  return useCollectionListener(connectionStore(''));
};

type D = { d: Doc<ProductCatalogConnection>; ma: Doc<MarketplaceAdvertiser> };

const Actions = ({ d }: { d: D }) => {
  return <AdditionalActionsMenu options={[]} />;
};

const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: ({ d }) => <CopyId id={d.id} />,
    align: 'left',
    width: 85
  },
  {
    key: 'ma',
    head: () => 'Advertiser',
    cell: ({ ma }) => (
      <Link to={`/marketplaceAdvertisers/${ma.id}`}>
        <ProfileLabel profile={ma.data.profile} variant="strong" />
      </Link>
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'type',
    head: () => 'Type',
    cell: ({ d }) => (
      <ProductCatalogConnectionTypeBadge type={d.data.details.type} />
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: ({ d }) => d.data.name,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: ({ d }) => (
      <ProductCatalogConnectionStatusBadge status={d.data.status} />
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    align: 'right',
    sortable: false,
    width: 50,
    flexGrow: 0
  }
];

const rowToKey = (d: D) => d.d.id;

const SORTERS: ItemSorters<D> = {
  ma: {
    key: 'ma',
    items: {
      sort: [
        (d) => d.ma.data.profile.name,
        (d) => d.d.data.details.type,
        (d) => d.d.data.name
      ],
      dir: 'asc'
    }
  },
  name: {
    key: 'name',
    items: {
      sort: [(d) => d.d.data.name],
      dir: 'asc'
    }
  },
  type: {
    key: 'type',
    items: {
      sort: [
        (d) => d.d.data.details.type,
        (d) => d.ma.data.profile.name,
        (d) => d.d.data.name
      ],
      dir: 'asc'
    }
  },
  status: {
    key: 'status',
    items: {
      sort: [
        (d) => d.d.data.status,
        (d) => d.ma.data.profile.name,
        (d) => d.d.data.details.type,
        (d) => d.d.data.name
      ],
      dir: 'asc'
    }
  }
};
const DEFAULT_SORTER = SORTERS.ma;

export const PageProductCatalogConnections = () => {
  const [q, setQ] = useStringQueryParam('q');
  const [ds] = useMappedLoadingValue(
    useCombineLoadingValues(useConnections(), _useMarketplaceAdvertisers()),
    ([cs, ms]) => {
      const msById = keyBy(ms, (x) => x.id);

      return compact(
        cs.map((d) => {
          const ma = msById[d.data.marketplaceAdvertiserId];
          if (!ma) {
            return null;
          }
          return {
            d,
            ma
          };
        })
      );
    }
  );

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const rows = useMemo(() => {
    if (!ds) {
      return null;
    }
    const re = toSearchRegexp(q);
    return re
      ? ds.filter(
          (d) =>
            d.d.data.name.match(re) ||
            d.ma.data.profile.name.match(re) ||
            d.d.data.details.type.match(re)
        )
      : ds;
  }, [ds, q]);

  const [selectedId, setSelectedId] = useState('');
  const selected = useMemo(() => {
    return rows?.find((r) => r.d.id === selectedId) || null;
  }, [selectedId, rows]);
  return (
    <Page width="FULL">
      <Section>
        <CanvasBar>
          <SearchInput
            value={q}
            onChange={setQ}
            placeholder="Search by catalog name, marketplace advertiser..."
            autoFocus={true}
            width={300}
          />
        </CanvasBar>
        {!rows && <Loader height={500} />}
        {rows && (
          <RowsRenderer
            variant="contained"
            rows={rows}
            columns={COLUMNS}
            rowToKey={rowToKey}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
            onHeadClick={(c, d) =>
              setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
            }
            chunkSize={100}
            rootMargin="400px"
            rowHeight={ROW_HEIGHTS.dense}
            renderHead
            otherProps={undefined}
            onRowClick={(d) => setSelectedId(d.d.id)}
          />
        )}
      </Section>
      {selected && (
        <ConnectionDialog d={selected} onClose={() => setSelectedId('')} />
      )}
    </Page>
  );
};
