import firebase from 'firebase/app';

export const traverse = (
  obj: any,
  visitor: (path: (string | number)[], value: any) => void,
  path: (string | number)[] = []
) => {
  if (Array.isArray(obj)) {
    obj.forEach((v, i) => {
      const nextPath = [...path, i];
      traverse(v, visitor, nextPath);
    });
    return;
  }

  if (typeof obj === 'function') {
    visitor(path, obj);
    return;
  }

  if (typeof obj === 'object' && obj !== null) {
    Object.entries(obj).forEach(([k, v]) => {
      const nextPath = [...path, k];
      traverse(v, visitor, nextPath);
    });
    return;
  }

  visitor(path, obj);
};

export const traverseTimestampAware = (
  obj: any,
  visitor: (path: (string | number)[], value: any) => void,
  path: (string | number)[] = []
) => {
  if (Array.isArray(obj)) {
    obj.forEach((v, i) => {
      const nextPath = [...path, i];
      traverseTimestampAware(v, visitor, nextPath);
    });
    return;
  }

  if (typeof obj === 'function') {
    visitor(path, obj);
    return;
  }

  if (obj instanceof firebase.firestore.Timestamp) {
    visitor(path, obj);
    return;
  }

  if (typeof obj === 'object' && obj !== null) {
    Object.entries(obj).forEach(([k, v]) => {
      const nextPath = [...path, k];
      traverseTimestampAware(v, visitor, nextPath);
    });
    return;
  }

  visitor(path, obj);
};
