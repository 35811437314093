import { capitalize, compact, partition, snakeCase, uniq } from 'lodash';
import { ICountWithTrend, IDailyCounter } from '../../domainTypes/analytics';
import { IPartner, PARTNERS } from '../../domainTypes/partners';
import { IProduct } from '../../domainTypes/product';
import { PARTNERS_WITH_TRACKING } from '../../domainTypes/tracking';
import { COLOR_UNKNOWN } from '../color';
import { getDestinationUrlForProduct, getProductsBySpaceId } from '../products';

export type PartnerListItem = {
  partner: IPartner;
  counts: {
    links: number;
  };
};

export type PartnerListItemWithDailyCounters = {
  partner: IPartner;
  products: number;
  dailyCounters: IDailyCounter[];
};

export type PartnerListItemWithCountsAndTrends = {
  partner: IPartner;
  counts: {
    products: number;
    served: ICountWithTrend;
    viewed: ICountWithTrend;
    clicked: ICountWithTrend;
  };
};

export type PartnerListItemWithCountsAndTrendsAndSales = {
  sales: {
    earnings: ICountWithTrend;
    saleValue: ICountWithTrend;
    orderCount: ICountWithTrend;
    currency: string;
    earningsPerClick: ICountWithTrend;
    conversionRate: number;
    avgCommissionPercent: number;
  };
} & PartnerListItemWithCountsAndTrends;

export type PartnerList = {
  [domain: string]: number;
};

const shortenPartnerUrl = (url: string) => {
  const starters = ['https://www.', 'http://www.', 'https://', 'http://'];
  const toStripIndex = starters.findIndex((s) => url.indexOf(s) === 0);
  if (toStripIndex === -1) {
    return url;
  }
  const toStrip = starters[toStripIndex];
  return url.substr(toStrip.length, url.length);
};

const getPartnerUrlFromUrl = (url: string): string => {
  try {
    const { origin } = new URL(url);
    return origin;
  } catch (err) {
    return url;
  }
};

const constructPartnerForUrl = (url: string): IPartner => {
  const partnerUrl = getPartnerUrlFromUrl(url);
  const shortened = shortenPartnerUrl(partnerUrl);

  return {
    key: snakeCase(shortened),
    name: capitalize(shortened),
    matches: (url) => url.indexOf(shortened) !== -1,
    known: false,
    portalUrl: partnerUrl,
    color: COLOR_UNKNOWN
  };
};

export const constructPartnerForKey = (key: string): IPartner => {
  return {
    key,
    name: key.replace('_', '.'),
    matches: () => false,
    known: false,
    portalUrl: '',
    color: COLOR_UNKNOWN
  };
};

export const getPartnerForUrl = (url: string): IPartner => {
  return PARTNERS.find((p) => p.matches(url)) || constructPartnerForUrl(url);
};

export const getParnterKeyForUrl = (url: string): string => {
  const known = PARTNERS.find((p) => p.matches(url));
  if (known) {
    return known.key;
  }
  const partnerUrl = getPartnerUrlFromUrl(url);
  const shortened = shortenPartnerUrl(partnerUrl);
  return snakeCase(shortened);
};

export const getPartnersForProductInTimeSpan = (
  p: IProduct,
  start: number,
  end: number
) => {
  //         5          10
  //  a            b            c  | a b
  //  a   b                     c  | b
  //  a                            | a
  //  a                         c  | a
  const matches = p.destinations.filter((d) => d.foundAt.toMillis() <= end);
  const [before, within] = partition(
    matches,
    (d) => d.foundAt.toMillis() <= start
  );
  const destinations = compact([before[before.length - 1], ...within]);
  return destinations.map((d) => getPartnerForUrl(d.url));
};

export const getPartnerKeyForProductAtPointInTime = (
  product: IProduct,
  ts: number
) => {
  //                  5
  // a        b            c        d
  for (let i = product.destinations.length - 1; i >= 0; i--) {
    const d = product.destinations[i];
    if (d.foundAt.toMillis() < ts) {
      return getParnterKeyForUrl(d.url);
    }
  }
  return null;
};

export const getKnownPartnerForKey = (partnerKey: string) =>
  PARTNERS.find((p) => p.key === partnerKey) || null;

export const getKnownPartnerForKeyUnsafe = (partnerKey: string) => {
  const p = getKnownPartnerForKey(partnerKey);
  if (!p) {
    // this should never happen
    throw new Error(`UNKNOWN _PARTNER - ${partnerKey}`);
  }
  return p;
};

export const getCurrentPartnerForProduct = (product: IProduct): IPartner => {
  if (product.partnerKey) {
    return (
      getKnownPartnerForKey(product.partnerKey) ||
      constructPartnerForKey(product.partnerKey)
    );
  }
  return getPartnerForUrl(getDestinationUrlForProduct(product));
};

export const getPartnerForKey = async (
  spaceId: string,
  partnerKey: string
): Promise<IPartner | null> => {
  const partner = getKnownPartnerForKey(partnerKey);
  if (partner) {
    return partner;
  }
  return getProductsBySpaceId(spaceId).then((ps) => {
    const partners = uniq(ps.map((p) => getCurrentPartnerForProduct(p.data)));
    return partners.find((p) => p.key === partnerKey) || null;
  });
};

export const getAutoLabellingPartners = () =>
  PARTNERS.filter((p) => !!PARTNERS_WITH_TRACKING[p.key]);
