import { DialogActions } from '@material-ui/core';
import React from 'react';
import { css, styled } from '../../emotion';
import { useTheme } from '../../themes';
import { DialogCloseButton } from '../DialogCloseButton';

const Left = styled('div')`
  display: flex;
  align-items: center;
  min-height: ${(p) => p.theme.spacing(5)}px;
  gap: ${(p) => p.theme.spacing()}px;
  flex: 0 1 auto;
`;

const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing()}px;
  flex: 1 1 auto;
`;

const Right = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${(p) => p.theme.spacing(2)}px;
  flex: 0 1 auto;
`;

export const DialogActionsHeader: React.FC<{
  left?: React.ReactNode;
  center?: React.ReactNode;
  onClose: any;
  hasVerticalPadding?: boolean;
}> = ({ left, center, children, onClose, hasVerticalPadding = true }) => {
  const th = useTheme();
  return (
    <DialogActions
      classes={{
        spacing: css(() => ({ justifyContent: 'space-between !important' })),
        root: css(() => ({
          '&&': {
            borderBottom: th.custom.border.standard,
            background: th.custom.colors.background.light,
            paddingTop: hasVerticalPadding ? th.spacing(1.5) : th.spacing(0),
            paddingBottom: hasVerticalPadding ? th.spacing(1.5) : th.spacing(0),
            paddingLeft: th.spacing(3),
            paddingRight: th.spacing(3)
          }
        }))
      }}
    >
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>
        {children}
        <DialogCloseButton onClick={onClose} />
      </Right>
    </DialogActions>
  );
};
