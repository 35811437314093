import { IconButton } from '@material-ui/core';
import React from 'react';
import { X } from 'react-feather';

export const DialogCloseButton = ({
  onClick,
  size = 20
}: {
  onClick: () => void;
  size?: number;
}) => {
  return (
    <IconButton onClick={onClick}>
      <X size={size} />
    </IconButton>
  );
};
