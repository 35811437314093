import { every } from 'lodash';

type QueueItem<T> = {
  fn: () => Promise<T>;
  started: boolean;
  done: boolean;
};

export const processParallelCapped = async <T>(
  fns: (() => Promise<T>)[],
  capSize: number
): Promise<T[]> => {
  if (!fns.length) {
    return [];
  }
  return new Promise((resolve, reject) => {
    const results: T[] = [];
    const errors: any[] = [];
    const queue: QueueItem<T>[] = fns.map((fn) => ({
      fn,
      started: false,
      done: false
    }));
    const processNext = () => {
      const nextItem = queue.find((item) => !item.started);
      if (nextItem) {
        processItem(nextItem);
      } else {
        if (every(queue, (item) => item.done)) {
          if (errors.length) {
            reject(errors);
          } else {
            resolve(results);
          }
        }
      }
    };
    const processItem = (item: QueueItem<T>) => {
      item.started = true;
      item.fn().then(
        (res) => {
          results[queue.indexOf(item)] = res;
          item.done = true;
          processNext();
        },
        (err) => {
          errors.push(err);
          item.done = true;
          processNext();
        }
      );
    };

    queue.slice(0, capSize).forEach(processItem);
  });
};
