import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { useProductCatalogDescriptorsByIds } from '../../../../../../advertiser-app/features/ProductCatalog/pages/Connections/service';
import { Loader } from '../../../../../../components/Loader';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Centered } from '../../../../../../layout/Centered';
import { Section } from '../../../../../../layout/Section';
import { ProductCatalogDescriptorsTable } from '../../../../ProductCatalog/pages/Descriptors';
import { useAdvertiserAppSpaceAccessList } from '../../../services/accesList';

const CatalogList = ({ catalogIds }: { catalogIds: string[] }) => {
  const [rows] = useProductCatalogDescriptorsByIds(catalogIds);
  if (!rows) {
    return <Loader height={50} />;
  }
  return <ProductCatalogDescriptorsTable rows={rows} />;
};

export const AccessibleCatalogs = ({ spaceId }: { spaceId: string }) => {
  const [accessList, loading] = useAdvertiserAppSpaceAccessList(spaceId);
  return (
    <Section>
      <CanvasBar>Accessible Catalogs</CanvasBar>
      <Card>
        {loading && <Loader height={100} />}
        {accessList && !!accessList.data.catalogIds.length && (
          <CatalogList catalogIds={accessList.data.catalogIds} />
        )}
        {accessList && !accessList.data.catalogIds.length && (
          <Centered height={100}>
            <Typography variant="caption" color="textSecondary">
              No accessible catalogs
            </Typography>
          </Centered>
        )}
      </Card>
    </Section>
  );
};
